import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {UserFormModel, UserModel} from "../../../models/user.model";
import {Select, Store} from "@ngxs/store";
import {UsersState} from "../../../states/users.state";
import {Observable, of, Subscription} from "rxjs";
import {SessionState} from "../../../../../core/state/session/session.state";
import {AccountModel} from "../../../../../core/models/account.model";
import {UserTypeEnum} from "../../../../../shared/enums/user-type.enum";
import {PermissionEnum} from "../../../../../shared/models/permission.enum";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../../core/services/session.service";
import {ClearLinkedAccounts, GetAccountsData} from "../../../../../core/state/accounts/accounts.actions";
import {
  ClearUserDetails,
  CreateUser,
  EditUser,
  GetUserDetails,
  ResetSavingUsersState,
  UpdateUserPassword
} from "../../../states/users.actions";
import {QuickInfoMessage} from "../../../../../shared/components/quick-info/models/quick-info-message.model";
import {RoutesEnum} from "../../../../../shared/enums/routes.enum";
import {MatSelectChange} from "@angular/material/select";
import {map} from "rxjs/operators";
import {
  emailValidator,
  emptySpaces,
  lowercaseValidator,
  maxLengthTrimValidator,
  minLengthTrimValidator,
  numberValidator,
  specialCharacterValidator,
  uppercaseValidator,
  usernamePattern,
  whiteSpacesValidator
} from "../../../../../shared/utils/password-validators";
import {ProfilePermissionsState} from "../../../states/profile-permissions/profile-permissions.state";
import {RoleModel} from "../../../models/roles.model";
import {GetUserRoles} from "../../../states/profile-permissions/profile-permissions.actions";
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Permission, PermissionEntity } from '../../../../../core/models/permission.model';
import { isPartner } from 'src/app/shared/utils/get-context';
import { PermissionData } from 'src/app/shared/models/has-permission-parameter.model';

@Component({
  selector: 'mjx-partner-users-data-form',
  templateUrl: './partner-users-data-form.component.html',
  styleUrls: ['./partner-users-data-form.component.scss']
})
export class PartnerUsersDataFormComponent implements OnInit {
  protected readonly EMPTY = '';

  isEdit = false;
  isProfile = false;
  isEditUserData = false;
  isEditAccountData = false;
  hasPartner = false;
  loadingFilter = false;
  hasUserType = false;
  showInfo = false;
  hideReset = false;
  userForm: UntypedFormGroup;
  user: UserModel;
  userDomain: string;
  selectedRoles: string[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  userSelectRoles: any = [];
  isPartner = isPartner

  @Select(UsersState.getSavingState)
  isSaving$: Observable<boolean>;

  @Select(UsersState.getLoadingState)
  isLoading$: Observable<boolean>;

  @Select(UsersState.getUser)
  userData: Observable<UserModel>;

  @Select(SessionState.userRoles)
  currentPermissions$: Observable<any>;

  selectedAccounts$: Observable<AccountModel[]> = of([]);

  permissionSub: Subscription;
  userTypeSubs: Subscription;
  refCustomerSubs: Subscription;
  detailedUserSubs: Subscription;
  userTypes = UserTypeEnum;
  selectedPartnerId: string;
  removedRoles = ["PartnerIntegration", "PartnerBroker", "Partner", "PartnerDefault"]

  @Select(ProfilePermissionsState.roles)
  roles$: Observable<RoleModel[]>;

  permissionsEnum = PermissionEnum;

  DOMAINS = ['Partner', 'Corporate']

  permissionMembership: PermissionData = {
    entity: PermissionEntity.ACCOUNTS,
    type: Permission.ListMembership
  }

  constructor(
    protected fb: UntypedFormBuilder,
    protected dialog: MatDialog,
    protected router: Router,
    protected activatedRoute: ActivatedRoute,
    protected store: Store,
    protected sessionService: SessionService
  ) { this.buildUserForm(); }

  ngOnInit(): void {
    this.store.dispatch(new ClearLinkedAccounts());
    this.store.dispatch(new ClearUserDetails());
    this.listenRouteState();

    this.hasUserType = true;
    this.listenDetailedUser();

    this.store.dispatch(new ResetSavingUsersState());

    if(isPartner){
      this.roles$.subscribe(roles => {
        const allowedRoles = roles.filter(role => !this.removedRoles.includes(role.name));
        const partnerRoles = allowedRoles?.filter(r => r.domain === 'Partner');
        this.userSelectRoles = partnerRoles;
      })
      this.userForm.get('domain').disable();
    }
  }

  ngOnDestroy() {
    if (this.detailedUserSubs && !this.detailedUserSubs.closed) {
      this.detailedUserSubs.unsubscribe();
    }
  }

  get disableAccountsForm(): boolean {
    if (this.selectedPartnerId && this.user.id) {
      return false;
    }

    return true;
  }

  get isPartnerUser(): boolean {
    const partners = [UserTypeEnum.Operator, UserTypeEnum.Admin, UserTypeEnum.PartnerMemberFin]
    return partners.includes(this.userForm.get('userType')?.value);
  }

  get pageTitle(): string {
    return `USERS.${this.isEdit ? 'EDIT' : 'NEW'}.TITLE`;
  }

  get inputDisabled(): boolean {
    return this.userForm.invalid;
  }

  get saveEditLabel(): string {
    let label = 'USERS.SAVE_BTN';
    if (!this.isEditUserData)  {
      label = 'USERS.SAVE_EDIT_USER';
    } else if(!(this.isEditAccountData && this.isProfile)) {
      label = 'USERS.SAVE_EDIT_ACCOUNT';
    }

    return label;
  }

  get infoMessages(): QuickInfoMessage[] {
    return [
      { icon: 'description', messages: ['USERS.INFO_TEXT'] },
    ]
  }

  changeInfoDialog() {
    this.showInfo = !this.showInfo;
  }

  selectedRole(event) {
    const mutableRoles = [...this.selectedRoles];
    mutableRoles.push(event.option.value);
    this.selectedRoles = mutableRoles;
    this.userForm?.get('roles')?.setValue(this.selectedRoles);
    this.hasUserType = true
  }

  addRole(event: MatChipInputEvent) {
    const value = (event.value || '').trim();

    const roles = this.store.selectSnapshot(ProfilePermissionsState.roles);
    const rolesNames = roles.map(role => role.name);

    if (rolesNames.includes(value)) {
      this.selectedRoles.push(value);
      this.userForm?.get('roles')?.setValue(this.selectedRoles);
    }
    event.chipInput!.clear();
  }

  emitDomain(event) {
    this.userDomain = event.value

    if(this.userDomain === 'Corporate') {
      this.userForm.get('refCustomer').disable()
      this.userForm.get('password').enable()
      this.userForm.get('confirmPassword').enable()
      this.selectedRoles = []
    } else {
      this.userForm.get('refCustomer').enable()
      this.userForm.get('password').disable()
      this.userForm.get('confirmPassword').disable()
      this.selectedRoles = []
    }
    this.userForm.get('userType').disable()
    this.roles$.subscribe(roles => {
      this.userSelectRoles = roles.filter(role => role.domain === this.userDomain)
    })
  }

  removeRole(b: any, index: number): void {
    const mutableRoles = this.selectedRoles.slice()
    mutableRoles.splice(index, 1);
    this.selectedRoles = mutableRoles
    this.userForm?.get('roles')?.setValue(this.selectedRoles);
    if(this.selectedRoles.length === 0) {
      this.hasUserType = false
    }
  }

  getAccounts(event: MatSelectChange) {
    const id = event.value;
    this.store.dispatch(new GetAccountsData(id));
  }

  doSave() {
    const formValue: UserFormModel = this.userForm.value;
    const userObj: UserFormModel = this.sanitizeForm(formValue);

    if (this.isEdit) {
      if (this.isProfile && this.isEditAccountData) {
        const {currentPassword, password, confirmPassword} = this.userForm.value;

        this.store.dispatch(new UpdateUserPassword(currentPassword, password, confirmPassword));

        this.disableEdition();
      } else if (this.isEditUserData || !this.isProfile) {
        this.store.dispatch(new EditUser(userObj, this.user.id));
      }
    } else {
      delete userObj.confirmPassword
      delete userObj.document;
      delete userObj.docType;

      if (userObj.refCustomer === null || userObj.refCustomer === undefined) {
        delete userObj.refCustomer;
      }

      if (userObj.userType === UserTypeEnum.PartnerMemberFin) {
        userObj.roles = [UserTypeEnum.PartnerMemberFin];
        delete userObj.userType;
      }

      this.store.dispatch(new CreateUser(userObj));
    }
  }

  editUserData() {
    this.disableEdition();

    this.isEditUserData = true;
  }

  editAccountData() {
    this.disableEdition();

    if (this.isProfile) {
      this.userForm.patchValue({
        password: '',
        currentPassword: '',
        confirmPassword: ''
      });

      this.userForm.get('username').disable();
    } else {
      this.userForm.patchValue({
        password: 'PLACEHOLDER#1a',
      });
    }

    this.isEditAccountData = true;
  }

  disableEdition() {
    this.isEditUserData = false;
    this.isEditAccountData = false;

    this.populateEditForm();

    if (this.isProfile) {
      this.userForm.patchValue({
        password: 'PLACEHOLDER#1a',
        currentPassword: 'PLACEHOLDER',
        confirmPassword: 'PLACEHOLDER#1a'
      });
    }
  }

  navigateBack() {
    if (this.sessionService.userHasPermission(PermissionEntity.USERS, Permission.List)) {
      this.router.navigateByUrl(`/${RoutesEnum.Users}/list`);
    } else {
      this.router.navigateByUrl(`/${RoutesEnum.Sales}`);
    }
  }

  protected populateEditForm() {
    this.userDomain = this.user?.userType
    let userType = this.user?.userType
    this.selectedRoles  = this.user?.roles

    if (isPartner) {
      this.roles$.subscribe((roles) => {
        this.userSelectRoles = roles
          ?.filter((role) => role.domain === this.userDomain)
          ?.filter((role) => !this.removedRoles.includes(role.name));
      });
    } else {
      this.roles$.subscribe((roles) => {
        this.userSelectRoles = roles?.filter((role) => role.domain === this.userDomain);
      });
    }

    this.userForm.patchValue({
      email: this.user.email,
      fullName: this.user.fullName,
      userType,
      roles: this.user?.roles,
      username: this.user.username,
    });

    this.hasUserType = true;
  }

  protected sanitizeForm(form: UserFormModel): UserFormModel {
    for (let key in form) {
      if (form[key] && key !== 'roles' && key !== 'refCustomer') {
        form[key] = form[key].trim();
      }
    }

    return form;
  }

  protected getUserDetails(userId: string) {
    this.listenDetailedUser();

    this.store.dispatch(new GetUserDetails(userId));
  }

  protected listenDetailedUser() {
    this.detailedUserSubs = this.userData.subscribe(user => {
      if (user) {
        this.isEdit = true;
        this.user = user;

        this.populateEditForm();
      }
    });
  }

  protected listenRouteState() {
    this.activatedRoute
      .paramMap
      .pipe(
        map(() => {
          const state: any = window?.history?.state;
          const isEditPage = (window.document.documentURI).includes('edit');
          this.store.dispatch(new GetUserRoles(null));

          this.isProfile = state?.profile ?? false;
          this.user = (state as UserModel);
          this.isEdit = isEditPage;

          if (state?.id) {
            if (state?.refCustomer) {
              this.selectedPartnerId = state?.refCustomer;
            }

            if (!this.isProfile) {
              this.getUserDetails(this.user.id);
            }

            this.buildUserForm();
            this.populateEditForm();
          } else if (isEditPage) {
            this.navigateBack();
          } else {
            this.buildUserForm();
          }

        })
      )
      .subscribe();
  }

  protected checkPasswords(group: UntypedFormGroup) {
    const NO_PASSWORD_PROFILES = [UserTypeEnum.Admin, UserTypeEnum.Operator];
    // if (NO_PASSWORD_PROFILES.includes(group.controls['userType']?.value)) {
    //   return null
    // }

    let pass = group.controls['password']?.value;
    let confirmPass = group.controls['confirmPassword']?.value;

    return pass === confirmPass ? null : { notSame: true }
  }

  protected buildUserForm() {
    if (this.isProfile) {
      this.userForm = this.fb.group({
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          whiteSpacesValidator(),
          usernamePattern()
        ]],
        currentPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
        ]],
        password: ['PLACEHOLDER', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, {validator: this.checkPasswords});
    } else if (!this.isEdit) {
      this.userForm = this.fb.group({
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          usernamePattern()
        ]],
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        roles: [this.EMPTY, Validators.required],
        domain: [this.EMPTY],
        password: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, {validator: this.checkPasswords});
    } else if (this.isEdit) {
      this.userForm = this.fb.group({
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        roles: [this.EMPTY],
        domain: [this.EMPTY],
        password: [this.EMPTY, [
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: [this.EMPTY, [
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      });
    }
  }
}

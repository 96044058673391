<mjx-navigation-tabs
  [tabs]="tabs"
  [currentIndex]="selectedTabIndex">
</mjx-navigation-tabs>

<ng-template #dataForm>
  <users-data-form></users-data-form>
</ng-template>

<ng-template #accountSecurity>
  <account-security [isProfile]="isProfile" [isPartnerReset]="isPartnerReset" [partnerId]="selectedUserId"></account-security>
</ng-template>

<div *ngIf="processedMenus">
  <button mat-icon-button [matMenuTriggerFor]="menu" [ngStyle]="color" *hasPermission="processedAction.permission">
    <mat-icon>{{ processedAction.icon }}</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item  *ngFor="let subMenu of processedMenus"
      [disabled]="subMenu.disabled"
      (click)="emitEvent(subMenu); $event.stopPropagation()">
      <mat-icon
      [ngStyle]="subMenu.color ? {'color': subMenu.color} : null"
      >
        {{ subMenu.icon }}
      </mat-icon>
      <span>{{ subMenu.tooltip | translate }}</span>
    </button>
  </mat-menu>
</div>

<div *ngIf="processedAction?.action && processedAction?.icon" class="actions-column">
  <button
    *hasPermission="processedAction.permission"
    [matTooltipHideDelay]="10"
    [matTooltipPosition]="'above'"
    [disabled]="processedAction.disabled"
    mat-icon-button [matTooltip]="processedAction.tooltip | translate"
    (click)="emitEvent(); $event.stopPropagation()">
    <mat-icon
      [ngStyle]="color"
    >
      {{ processedAction.icon }}
    </mat-icon>
  </button>
</div>

<div *ngIf="processedAction?.action && !processedAction?.icon" class="actions-column">
  <button
    *hasPermission="processedAction.permission"
    [matTooltipHideDelay]="10"
    [matTooltipPosition]="'above'"
    [disabled]="processedAction.disabled"
    [matTooltip]="processedAction.tooltip | translate"
    mat-button
    class="custom-button"
    (click)="emitEvent(); $event.stopPropagation()">
    <mat-icon
      [ngStyle]="color"
      *ngIf="processedAction.buttonIcon"
    >
      {{ processedAction.buttonIcon }}
    </mat-icon>
    {{ processedAction.buttonText }}
  </button>
</div>

import {MjxTableColumnDefinition} from "../../../shared/modules/mjx-table/models/mjx-table.model";
import {MjxTableColumnTypesEnum} from "../../../shared/modules/mjx-table/enums/mjx-table-column-types.enum";
import {Injectable} from "@angular/core";
import {Store} from "@ngxs/store";
import {UserModel} from "../models/user.model";
import {SessionState} from "../../../core/state/session/session.state";
import { Permission, PermissionEntity } from "src/app/core/models/permission.model";
import { TranslateService } from "@ngx-translate/core";
import { SessionService } from "src/app/core/services/session.service";

@Injectable({
  providedIn: 'root'
})
export class UsersTableUtilsService {
  constructor(
    private store: Store,
    private translate: TranslateService,
    private sessionService: SessionService
  ) { }

  getColumns(): string[] {
    return [
      'username',
      'fullName',
      'email',
      'roles',
      'refCustomer',
      'metaCreatedAt',
      'metaUpdatedAt',
      'actions'
    ];
  }

  getPartnerColumns(): string[] {
    return [
      'username',
      'fullName',
      'email',
      'roles',
      'metaCreatedAt',
      'metaUpdatedAt',
      'actions'
    ];
  }

  getColumnsDef(): MjxTableColumnDefinition[] {
    return [
      {
        column: 'email',
        property: 'email',
        name: 'USERS.TABLE.EMAIL',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'username',
        property: 'username',
        name: 'USERS.TABLE.USERNAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'roles',
        property: 'roles',
        name: 'USERS.TABLE.ROLES',
        type: MjxTableColumnTypesEnum.Array,
        sortable: true,
        enableHideColumn: true,
        transform: (value: string[]) => this.translateRoles(value)
      },
      {
        column: 'refCustomer',
        property: 'refCustomer',
        name: 'USERS.TABLE.PARTNER',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'fullName',
        property: 'fullName',
        name: 'USERS.TABLE.FULL_NAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'metaCreatedAt',
        property: 'metaCreatedAt',
        name: 'USERS.TABLE.CREATED_AT',
        type: MjxTableColumnTypesEnum.Date,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'metaUpdatedAt',
        property: 'metaUpdatedAt',
        name: 'USERS.TABLE.EDITED_AT',
        type: MjxTableColumnTypesEnum.Date,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'actions',
        property: '',
        name: 'USERS.TABLE.ACTIONS',
        type: MjxTableColumnTypesEnum.Actions,
        sortable: false,
        enableHideColumn: false,
        actions: [
          {
            action: 'users-edit', icon: 'edit',
            permission: {
              permission: {
                entity: PermissionEntity.USERS,
                type: Permission.Update
              }
            },
            tooltip: 'USERS.TOOLTIP.EDIT'
          },
          {
            action: 'users-remove', icon: 'delete', color: '#F44336',
            tooltip: 'USERS.TOOLTIP.DELETE',
            dynamicAction: (item: UserModel) => {
              const currentUser = this.store.selectSnapshot(SessionState.getCurrentUser);
              const disabled = (item?.id === currentUser?.id);

              return {
                action: 'users-remove', icon: 'delete', color: disabled ? null: '#F44336',
                permission: {
                  permission: {
                    entity: PermissionEntity.USERS,
                    type: Permission.Delete
                  }
                },
                tooltip: 'USERS.TOOLTIP.DELETE',
                disabled
              }
            }
          },
        ]
      }
    ];
  }

  getPartnerColumnsDef(): MjxTableColumnDefinition[] {
    return [
      {
        column: 'email',
        property: 'email',
        name: 'USERS.TABLE.EMAIL',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'username',
        property: 'username',
        name: 'USERS.TABLE.USERNAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'roles',
        property: 'roles',
        name: 'USERS.TABLE.ROLES',
        type: MjxTableColumnTypesEnum.Array,
        sortable: true,
        enableHideColumn: true,
        transform: (value: string[]) => this.translateRoles(value)
      },
      {
        column: 'fullName',
        property: 'fullName',
        name: 'USERS.TABLE.FULL_NAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'metaCreatedAt',
        property: 'metaCreatedAt',
        name: 'USERS.TABLE.CREATED_AT',
        type: MjxTableColumnTypesEnum.Date,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'metaUpdatedAt',
        property: 'metaUpdatedAt',
        name: 'USERS.TABLE.EDITED_AT',
        type: MjxTableColumnTypesEnum.Date,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'actions',
        property: '',
        name: 'USERS.TABLE.ACTIONS',
        type: MjxTableColumnTypesEnum.Actions,
        sortable: false,
        enableHideColumn: false,
        actions: [
          {
            action: 'users-edit', icon: 'edit',
            permission: {
              permission: {
                entity: PermissionEntity.USERS,
                type: Permission.Update
              }
            },
            tooltip: 'USERS.TOOLTIP.EDIT'
          },
          {
            action: 'users-remove', icon: 'delete', color: 'var(--main-red)',
            tooltip: 'USERS.TOOLTIP.DELETE',
            dynamicAction: (item: UserModel) => {
              const currentUser = this.store.selectSnapshot(SessionState.getCurrentUser);
              const disabled = (item?.id === currentUser?.id);

              return {

                action: 'users-remove', icon: 'delete', color: disabled ? null: 'var(--main-red)',
                permission: {
                  permission: {
                    entity: PermissionEntity.USERS,
                    type: Permission.Delete
                  }
                },
                tooltip: 'USERS.TOOLTIP.DELETE',
                disabled
              }
            }
          },
        ]
      }
    ];
  }

  getAccountsColumns(): string[] {
    return ['accountNumber', 'accountName', 'actions'];
  }

  getAccountsColumnsDef(): MjxTableColumnDefinition[] {
    return [
      {
        column: 'accountNumber',
        property: 'accountNumber',
        name: 'USERS.TABLE.ACCOUNT_NUMBER',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'accountName',
        property: 'accountName',
        name: 'USERS.TABLE.ACCOUNT_NAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
        enableHideColumn: true
      },
      {
        column: 'actions',
        property: '',
        name: 'USERS.TABLE.ACTIONS',
        type: MjxTableColumnTypesEnum.Actions,
        sortable: false,
        enableHideColumn: true,
        actions: [
          {
            action: 'users-account-delete',
            icon: 'delete',
            color: 'var(--main-red)',
            disabled: this.sessionService.userHasPermission(PermissionEntity.ACCOUNTS, Permission.DeleteMembership),
            tooltip: 'USERS.TOOLTIP.DELETE_ACCOUNT'
          },
        ]
      }
    ];
  }

  private translateRoles(values: string[]): string {
    const translatedValues = values.map(value =>  this.translate.instant(`${value}`));
    const removedRoles = ["PartnerDefault", "CorporateDefault"]
    return translatedValues.filter(value => !removedRoles.includes(value)).join(', ');
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskCnpj',
})
export class MaskCnpjPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) return '';
    const stringValue = value.toString();

    if (stringValue.length === 14) {
      return stringValue.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
      );
    } else {
      return stringValue;
    }
  }
}

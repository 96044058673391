import {Injectable, NgZone} from "@angular/core";
import {Action, NgxsOnInit, Selector, State, StateContext, Store} from "@ngxs/store";
import {UsersStateModel, defaultUserState} from "./users-state.model";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {Router} from "@angular/router";
import {catchError, finalize, of, tap, throwError} from "rxjs";
import {CommonResponse} from "../../../shared/models/response.model";
import {PaginationModel} from "../../../shared/models/pagination.model";
import {RoutesEnum} from "../../../shared/enums/routes.enum";
import {UsersService} from "../services/users.service";
import {
  ClearResetKey,
  CreateUser,
  EditUser,
  GetUserDetails,
  GetUsers,
  ResetUserPassword,
  ResetSavingUsersState,
  UpdateUserPassword,
  DeleteUser, ClearUserDetails
} from "./users.actions";
import {CreateUserModel, UserModel} from "../models/user.model";
import {UsersTableDatasource} from "../utils/users-table-datasource";
import {SessionState} from "../../../core/state/session/session.state";
import {AccessRolesEnum} from "../../../shared/enums/access-roles.enum";
import {SessionService} from "../../../core/services/session.service";
import {isPartner} from "../../../shared/utils/get-context";
import {PartnersState} from "../../partners/state/partners.state";

@State<UsersStateModel>({
  name: 'users',
  defaults: defaultUserState
})
@Injectable()
export class UsersState implements NgxsOnInit {

  constructor(
    private usersService: UsersService,
    private snackbar: SnackbarService,
    private store: Store,
    private router: Router,
    private ngZone: NgZone,
    private sessionService: SessionService
  ) { }

  @Selector()
  static getErrorState(state: Partial<UsersStateModel>) {
    return state.hasError;
  }

  @Selector()
  static getLoadingState(state: Partial<UsersStateModel>) {
    return state.isLoading;
  }

  @Selector()
  static getEmptyState(state: Partial<UsersStateModel>) {
    return state.isEmpty;
  }

  @Selector()
  static getDatasourceState(state: Partial<UsersStateModel>) {
    return state.usersDatasource;
  }

  @Selector()
  static getSavingErrorState(state: Partial<UsersStateModel>) {
    return state.hasSavingError;
  }

  @Selector()
  static getSavingState(state: Partial<UsersStateModel>) {
    return state.isSaving;
  }

  @Selector()
  static getCurrentUser(state: Partial<UsersStateModel>) {
    return state.currentUser;
  }

  @Selector()
  static getUser(state: Partial<UsersStateModel>) {
    return state.userDetails;
  }

  @Selector()
  static getResetKey(state: Partial<UsersStateModel>) {
    return state.resetKey;
  }

  @Selector()
  static getFetchingKey(state: Partial<UsersStateModel>) {
    return state.fetchingKey;
  }

  @Selector()
  static hasErrorList(state: Partial<UsersStateModel>): boolean {
    return state.hasErrorList;
  }

  ngxsOnInit(ctx?: StateContext<any>): any {
    ctx.setState(defaultUserState);
  }

  @Action(ResetSavingUsersState)
  resetSavingUsersState(stateCtx: StateContext<UsersStateModel>) {
    stateCtx.patchState({
      isSaving: false,
      hasSavingError: false
    });
  }

  @Action(GetUsers)
  getUsers(
    stateCtx: StateContext<UsersStateModel>,
    actions: GetUsers
  ) {
    stateCtx.patchState({
      isLoading: true,
      hasErrorList: false,
      isEmpty: false
    })

    return this.usersService
      .getUsers(actions.filter)
      .pipe(
        catchError((err) => {
          this.snackbar.error('USERS.FAIL_MSG');
          stateCtx.patchState({
            hasErrorList: true
          })
          return throwError(err);
        }),
        tap((res: CommonResponse<PaginationModel<UserModel>>) => {
          const users = res.data.items;
          const rec = this.mapUsersRequests(users);
          const dataSource = new UsersTableDatasource(rec, res.data.total);
          stateCtx.patchState({
            usersDatasource: dataSource,
            isEmpty: (dataSource.total === 0)
          })
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoading: false
          })
        })
      );
  }

  private mapUsersRequests(users: UserModel[]): any[] {
    const partnersNameMap =  {};

    if (!isPartner) {
      this.store.selectSnapshot(PartnersState.getPartnersList)?.forEach(part => {
        partnersNameMap[part.id] = part.companyName;
      });

      return users.map((tranReq: UserModel): any => {
        const ref = partnersNameMap[tranReq.refCustomer];
        const partnerName = ref ? ref : '-';
        const lastRole = this.mapUserRole(tranReq);

        return {...tranReq, partnerName, lastRole};
      });
    }

    return users.map(user => {
      const lastRole = this.mapUserRole(user);
      return {...user, lastRole};
    });
  }

  private mapUserRole(user: UserModel): string {
    let lastRole = user.userType;

    if (user.roles) {
      lastRole = user?.roles[user?.roles.length - 1];
    }

    return lastRole;
  }

  @Action(GetUserDetails)
  getUserData(
    stateCtx: StateContext<UsersStateModel>,
    actions: GetUserDetails
  ) {
    if (actions.profile) {
      const currentUser = this.store.selectSnapshot(SessionState.getCurrentUser);

      stateCtx.patchState({
        isLoading: false,
        hasError: false,
        userDetails: {
          username: currentUser.username,
          email: currentUser.email,
          id: currentUser.id,
          fullName: currentUser.fullName
        } as any
      });

      this.goEdit({...currentUser, profile: true})

      return of(true)
    }

    stateCtx.patchState({
      isLoading: true,
      hasError: false,
      resetKey: undefined,
    })

    return this.usersService
      .getUser(actions.userId)
      .pipe(
        catchError((err) => {
          this.snackbar.error('USERS.FAIL_MSG');

          stateCtx.patchState({
            hasError: true
          });

          return throwError(err);
        }),
        tap((res: CommonResponse<UserModel>) => {
          const userDetails = res.data;

          stateCtx.patchState({userDetails});
        }),
        finalize(() => {
          stateCtx.patchState({
            isLoading: false
          })
        })
      );
  }

  @Action(CreateUser)
  createUser(
    stateCtx: StateContext<UsersStateModel>,
    actions: CreateUser
  ) {
    stateCtx.patchState({
      isSaving: true,
      hasError: false
    });

    const currentUser = (actions.user as any as CreateUserModel);
    return this.usersService
      .createUser(currentUser)
      .pipe(
        catchError((err) => {
          stateCtx.patchState({
            hasSavingError: true
          })
          return throwError(err);
        }),
        tap((res) => {
          this.snackbar.success('USERS.NEW.SUCCESS_MSG');
          const resetKey = res.data.resetKey;

          stateCtx.patchState({
            resetKey,
            userDetails: {...res.data, userType: actions.user.userType} as unknown as UserModel
          });
        }),
        finalize(() => {
          stateCtx.patchState({
            isSaving: false
          });
        })
      )
  }

  @Action(ClearResetKey)
  clearResetKey(
    stateCtx: StateContext<UsersStateModel>,
  ) {
    stateCtx.patchState({
      resetKey: undefined
    });
  }

  @Action(ClearUserDetails)
  clearUserDetails(
    stateCtx: StateContext<UsersStateModel>,
  ) {
    stateCtx.patchState({
      userDetails: undefined
    });
  }

  @Action(DeleteUser)
  deleteUser(
    stateCtx: StateContext<UsersStateModel>,
    actions: DeleteUser
  ) {

    stateCtx.patchState({
      hasError: false
    });

    return this.usersService
      .deleteUser(actions.userId)
      .pipe(
        catchError((err) => {
          this.snackbar.error('USERS.DELETE.FAIL_MSG');
          stateCtx.patchState({
            hasError: true
          })
          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('USERS.DELETE.SUCCESS_MSG');

          this.store.dispatch(new GetUsers(actions.filter));
        })
      );
  }

  @Action(EditUser)
  editUser(
    stateCtx: StateContext<UsersStateModel>,
    actions: EditUser
  ) {
    stateCtx.patchState({
      isSaving: true,
      hasError: false
    });

    return this.usersService
      .editUser(actions.user, actions.id)
      .pipe(
        catchError((err) => {
          stateCtx.patchState({
            hasSavingError: true
          })
          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('USERS.EDIT.SUCCESS_MSG');
          this.navigateBack();
        }),
        finalize(() => {
          stateCtx.patchState({
            isSaving: false
          });
        })
      )
  }

  @Action(ResetUserPassword)
  resetUserPassword(
    stateCtx: StateContext<UsersStateModel>,
    actions: ResetUserPassword
  ) {
    stateCtx.patchState({
      fetchingKey: true,
      resetKey: undefined,
      hasError: false
    });

    return this.usersService
      .resetUserPassword(actions.login)
      .pipe(
        catchError((err) => {
          this.snackbar.error('USERS.FORM.RESET.FAIL');
          stateCtx.patchState({
            hasError: true
          })
          return throwError(err);
        }),
        tap((res) => {
          stateCtx.patchState({
            resetKey: res.data.resetKey
          })
        }),
        finalize(() => {
          stateCtx.patchState({
            fetchingKey: false
          });
        })
      )
  }

  @Action(UpdateUserPassword)
  updateUserPassword(
    stateCtx: StateContext<UsersStateModel>,
    actions: UpdateUserPassword
  ) {
    stateCtx.patchState({
      isSaving: true,
      hasError: false
    });

    return this.usersService
      .updateUserPassword(actions.currentPassword, actions.newPassword, actions.newPasswordConfirmation)
      .pipe(
        catchError((err) => {
          let reason = err.error?.error?.details?.name;

          if (!reason) {
            reason = err.error?.error?.details?.reason;
          }

          switch(reason) {
            case 'UserPasswordNotMatch':
              this.snackbar.error('USERS.PASSWORD_DID_NOT_MATCH');
              break;
            case 'UserCredentials':
              this.snackbar.error('USERS.PASSWORD_CONFIRMATION');
              break;
            default:
              this.snackbar.error('USERS.EDIT.FAIL_MSG');
              break;
          }

          stateCtx.patchState({
            hasSavingError: true
          })

          return throwError(err);
        }),
        tap(() => {
          this.snackbar.success('USERS.EDIT.SUCCESS_MSG');
        }),
        finalize(() => {
          stateCtx.patchState({
            isSaving: false
          });
        })
      )
  }

  private navigateBack() {
    this.ngZone.run(() => {
      if (this.sessionService.userHasRole(AccessRolesEnum.PartnerMember)) {
        this.router.navigateByUrl(`/${RoutesEnum.Sales}`);
      } else {
        this.router.navigateByUrl(`/${RoutesEnum.Users}/list`);
      }
    });
  }

  private goEdit(user) {
    this.ngZone.run(() => {
      this.router.navigate([`/${RoutesEnum.Users}/edit`], {
        state: user
      });
    })
  }
}

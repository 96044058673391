import { RequestDataFilterModel, RequestFilterModel } from '../../../shared/models/request-filter.model';
import { PartnerFormModel, PartnerOnboardingModel } from '../models/partner-form.model';
import { PartnerSite } from '../models/parrner-site.model';
import { PartnerBillingPlan } from '../models/partner-billing-plan.model';
import { AccountConfigurationObject } from '../models/account-configuration-object.model';

export class GetPartners {
  static readonly type = '[Partners] Get Partners';
  constructor(public filter: Partial<RequestDataFilterModel<any>>) {}
}

export class GetPartnerDetails {
  static readonly type = '[Partners] Get Partner Details';
  constructor(public id: number) {}
}

export class DeletePartner {
  static readonly type = '[Partners] Delete Partner';
  constructor(public filter: RequestFilterModel, public id: number) {}
}

export class CreatePartner {
  static readonly type = '[Partners] Create Partner';
  constructor(public partner: PartnerOnboardingModel) {}
}

export class CreatePartnerSuccess {
  static readonly type = '[Partner] Create Partner Success';
  constructor(public payload: any) {}
}

export class EditPartner {
  static readonly type = '[Partners] Edit Partner';
  constructor(public partner: PartnerFormModel, public id: number) {}
}

export class GetPartnersList {
  static readonly type = '[Partners] Get Partner List For Input';
  constructor(public logo?: boolean) {}
}

export class GetPartnerSites {
  static readonly type = '[Partners] Get Partner Sites';
  constructor(public partnerId: number) {}
}

export class CreatePartnerSite {
  static readonly type = '[Partners] Create Partner Site';
  constructor(public site: PartnerSite, public partnerId: number) {}
}

export class EditPartnerSite {
  static readonly type = '[Partners] Edit Partner Site';
  constructor(public site: PartnerSite, public siteId: string, public partnerId: number) {}
}

export class DeletePartnerSite {
  static readonly type = '[Partners] Delete Partner Site';
  constructor(public siteId: string, public partnerId: number) {}
}

export class GetPartnerAccounts {
  static readonly type = '[Partners] Get Partner Accounts';
  constructor(public partnerId: number) {}
}

export class ClearPartnersAccounts {
  static readonly type = '[Partners] Clear Partners Accounts';
}

export class CreatePartnerAccount {
  static readonly type = '[Partners] Create Partner Account';
  constructor(public partnerId: number) {}
}

export class EditPartnerAccountName {
  static readonly type = '[Partners] Edit Partner Account Name';
  constructor(public partnerId: number, public accountId: number, public accountName: string) {}
}

export class EditPartnerAccountState {
  static readonly type = '[Partners] Edit Partner Account State';
  constructor(public partnerId: number, public accountId: number, public accountState: string) {}
}

export class ClearPartnersState {
  static readonly type = '[Partners] Clear Partner State';
}
export class GetPartnerBanksAccounts {
  static readonly type = '[Partners] Get Partner Banks Accounts';
  constructor(public partnerId: number) {}
}

export class GetPartnerBillingPlans {
  static readonly type = '[Partners] Get Partner Billing Plans';
  constructor(public partnerId: number) {}
}

export class ClearPartnerBillingPlansState {
  static readonly type = '[Partners] Clear Partner Billing Plans State';
  constructor() {}
}

export class CreatePartnerBilligPlan {
  static readonly type = '[Partners] Create Partner Billing Plans';
  constructor(public billingPlan: PartnerBillingPlan) {}
}

export class EditPartnerBillingPlan {
  static readonly type = '[Partners] Edit Partner Billing Plans';
  constructor(public billingPlan: PartnerBillingPlan) {}
}

export class EditActivePartnerBillingPlan {
  static readonly type = '[Partners] Edit Active Partner Billing Plans';
  constructor(public billingPlan: PartnerBillingPlan) {}
}

export class ArchivePartnerBillingPlan {
  static readonly type = '[Partners] Archive Partner Billing Plans';
  constructor(public billingPlan: PartnerBillingPlan) {}
}

export class GetAccountSelectedBanks {
  static readonly type = '[Partners] Get Account Selected Banks';
  constructor(public accountId: number) {}
}

export class SetAccountOperationBanks {
  static readonly type = '[Partners] Set Account Operation Banks';
  constructor(public accountId: number, public configuration: AccountConfigurationObject) {}
}

export class SetAccountSecurityKey {
  static readonly type = '[Partners] Set Account Security Key';
  constructor(public accountId: number, public key: string) {}
}

export class GetAccountSecurityKey {
  static readonly type = '[Partners] Get Account Security Key';
  constructor(public accountId: string) {}
}

export class PartnerGetAccountSecurityKey {
  static readonly type = '[Partners] Partner Get Account Security Key';
  constructor(public accountId: string) {}
}

export class PartnerSetAccountSecurityKey {
  static readonly type = '[Partners] Partner Set Account Security Key';
  constructor(public accountId: string, public key: string) {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import {MjxBreadcrumbModule} from "../../shared/modules/mjx-breadcrumb/mjx-breadcrumb.module";
import {MjxButtonModule} from "../../shared/modules/mjx-button/mjx-button.module";
import {MjxInputModule} from "../../shared/modules/mjx-input/mjx-input.module";
import {TranslateModule} from "@ngx-translate/core";
import {MaterialElementsModule} from "../../shared/modules/material-elements/material-elements.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import { DetailedUserComponent } from './components/detailed-user/detailed-user.component';
import { ListUsersComponent } from './components/list-users/list-users.component';
import {MjxTableModule} from "../../shared/modules/mjx-table/mjx-table.module";
import {NgxsModule} from "@ngxs/store";
import {UsersState} from "./states/users.state";
import {SharedPipesModule} from "../../shared/pipes/shared-pipes.module";
import {PartnersState} from "../partners/state/partners.state";
import {QuickInfoModule} from "../../shared/components/quick-info/quick-info.module";
import {MjxSectionsModule} from "../../shared/modules/mjx-sections/mjx-sections.module";
import {AccountsState} from "../../core/state/accounts/accounts.state";
import {EmptyMessageModule} from "../../shared/components/empty-message/empty-message.module";
import { UsersDataFormComponent } from './components/users-data-form/users-data-form.component';
import { UsersLinkedAccountsComponent } from './components/users-linked-accounts/users-linked-accounts.component';
import {MjxNavigationTabsModule} from "../../shared/modules/mjx-navigation-tabs/mjx-navigation-tabs.module";
import { ResetPasswordDialogComponent } from './components/users-data-form/reset-password-dialog/reset-password-dialog.component';
import { ListProfilesPermissionsComponent } from './components/profile-permissions/list-profiles-permissions/list-profiles-permissions.component';
import { DetailedProfilesPermissionsComponent } from './components/profile-permissions/detailed-profiles-permissions/detailed-profiles-permissions.component';
import {SessionState} from "../../core/state/session/session.state";
import { FilterUsersComponent } from './components/filter-users/filter-users.component';
import { IsLinkedAccountPipe } from './pipes/is-linked-account.pipe';
import { SharedDirectivesModule } from 'src/app/shared/directives/shared-directives.module';
import {AccountSecurityModule} from "../../shared/modules/account-security/account-security.module";
import {AccountSecurityState} from "../../shared/modules/account-security/states/account-security.state";
import {MjxSelectModule} from "../../shared/modules/mjx-select/mjx-select.module";
import {MjxFilterModule} from "../../shared/modules/mjx-filter/mjx-filter.module";
import {TRANSLATE_MODULE_NAME} from "../../shared/tokens/translate-module-name.token";
import { CorporateUsersDataFormComponent } from './components/users-data-form/corporate-users-data-form/corporate-users-data-form.component';
import { PartnerUsersDataFormComponent } from './components/users-data-form/partner-users-data-form/partner-users-data-form.component';
import {maskConfigFactory} from "../../shared/utils/maskConfigFactory";
import {TranslationModulesNames} from "../../shared/utils/translation-module-names";
import {ErrorStateMatcher, ShowOnDirtyErrorStateMatcher} from "@angular/material/core";
import { ProfilePermissionsState } from './states/profile-permissions/profile-permissions.state';
import { TranslatePermissionKeyPipe } from './pipes/translate-permission-key.pipe';
import { TranslatePermissionEntityKeyPipe } from './pipes/translate-permission-entity-key.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';

@NgModule({
  declarations: [
    UsersComponent,
    DetailedUserComponent,
    ListUsersComponent,
    UsersDataFormComponent,
    UsersLinkedAccountsComponent,
    ResetPasswordDialogComponent,
    ListProfilesPermissionsComponent,
    DetailedProfilesPermissionsComponent,
    FilterUsersComponent,
    IsLinkedAccountPipe,
    CorporateUsersDataFormComponent,
    PartnerUsersDataFormComponent,
    TranslatePermissionKeyPipe,
    TranslatePermissionEntityKeyPipe,
    TruncateStringPipe
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    MjxBreadcrumbModule,
    MjxButtonModule,
    MjxInputModule,
    MjxTableModule,
    MjxSectionsModule,
    NgxsModule.forFeature([UsersState, PartnersState, AccountsState, SessionState, AccountSecurityState, ProfilePermissionsState]),
    SharedPipesModule,
    SharedDirectivesModule,
    TranslateModule.forChild({ extend: true }),
    MaterialElementsModule,
    ReactiveFormsModule,
    FormsModule,
    NgxMaskModule.forRoot(maskConfigFactory),
    EmptyMessageModule,
    MjxNavigationTabsModule,
    QuickInfoModule,
    AccountSecurityModule,
    MjxSelectModule,
    MjxFilterModule
  ],
  providers: [
    { provide: TRANSLATE_MODULE_NAME, useValue: [TranslationModulesNames.Users, TranslationModulesNames.Integration, TranslationModulesNames.ProfilePermissions] },
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher }
  ]
})
export class UsersModule { }

import { MjxTableColumnDefinition } from '../../../shared/modules/mjx-table/models/mjx-table.model';
import { MjxTableColumnTypesEnum } from '../../../shared/modules/mjx-table/enums/mjx-table-column-types.enum';
import { Injectable } from '@angular/core';
import { RoleModel } from '../models/roles.model';
import { Permission, PermissionEntity } from 'src/app/core/models/permission.model';
import { SessionService } from 'src/app/core/services/session.service';

@Injectable({
  providedIn: 'root',
})
export class ProfilesPermissionsTableUtilsService {
  constructor(private sessionService: SessionService) {}
  getColumns(): string[] {
    return ['name', 'title', 'domain', 'type', 'actions'];
  }

  getColumnsDef(): MjxTableColumnDefinition[] {
    return [
      {
        column: 'name',
        property: 'name',
        name: 'USERS.PROFILE_PERMISSIONS.TABLE.NAME',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
      },
      {
        column: 'title',
        property: 'title',
        name: 'USERS.PROFILE_PERMISSIONS.TABLE.TITLE',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
      },
      {
        column: 'domain',
        property: 'domain',
        name: 'USERS.PROFILE_PERMISSIONS.TABLE.DOMAIN',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
      },
      {
        column: 'type',
        property: 'type',
        name: 'USERS.PROFILE_PERMISSIONS.TABLE.TYPE',
        type: MjxTableColumnTypesEnum.Text,
        sortable: true,
      },
      {
        column: 'actions',
        property: '',
        name: 'SHARED.TABLE.ACTIONS',
        type: MjxTableColumnTypesEnum.Actions,
        sortable: false,
        enableHideColumn: false,
        actions: [
          {
            action: 'profiles-permissions-edit',
            icon: 'edit',
            tooltip: 'USERS.PROFILE_PERMISSIONS.TOOLTIP.EDIT',
            dynamicAction: (item: RoleModel) => {
              if (item.type === 'SystemDefault') {
                return {
                  action: 'profiles-permissions-edit',
                  icon: 'edit',
                  disabled: true,
                };
              }

              return {
                action: 'profiles-permissions-edit',
                icon: 'edit',
                disabled: !this.sessionService.userHasPermission(
                  PermissionEntity.USER_ROLES,
                  Permission.Update
                ),
                tooltip: 'USERS.PROFILE_PERMISSIONS.TOOLTIP.EDIT',
              };
            },
          },
          {
            action: 'profiles-permissions-details',
            icon: 'visibility ',
            dynamicAction: (item: RoleModel) => {
              return {
                action: 'profiles-permissions-details',
                icon: ' visibility',
                disabled: !this.sessionService.userHasPermission(
                  PermissionEntity.USER_ROLES,
                  Permission.ListAssociation
                ),
                tooltip: 'USERS.PROFILE_PERMISSIONS.TOOLTIP.VISIBILITY',
              };
            },
          },
          {
            action: 'profiles-permissions-delete',
            icon: 'delete',
            dynamicAction: (item: RoleModel) => {
              if (item.type === 'SystemDefault') {
                return {
                  action: 'profiles-permissions-delete',
                  icon: 'delete',
                  disabled: true,
                };
              }

              return {
                action: 'profiles-permissions-delete',
                icon: 'delete',
                color: 'var(--main-red)',
                disabled: !this.sessionService.userHasPermission(
                  PermissionEntity.USER_ROLES,
                  Permission.Delete
                ),
                tooltip: 'USERS.PROFILE_PERMISSIONS.TOOLTIP.DELETE',
              };
            },
          },
        ],
      },
    ];
  }
}

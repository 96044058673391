import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {HttpUtilsService} from "../../../shared/services/http-utils.service";
import {routes} from "../../../../environments/vendor/routes/routes";
import {CommonResponse} from "../../../shared/models/response.model";
import {PaginationModel} from "../../../shared/models/pagination.model";
import {environment} from "../../../../environments/environment";
import {PartnerModel} from "../models/partner.model";
import {PartnerFormModel, PartnerOnboardingModel} from "../models/partner-form.model";
import {TransactionsTypesEnum} from "../../../shared/enums/transactions-types.enum";
import {TransactionResponseModel} from "../../transactions/models/transaction-response.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService
  ) { }

  getPartners(filter: any) {
    let url = `${API}/${routes?.customers}`;
    const queryParam = this.httpUtils.parseFilterParams(filter)

    return this.http
      .get<CommonResponse<PaginationModel<PartnerModel>>>(url, {
        params: queryParam
      });
  }

  getPartnerDetails(partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}`;
    return this.http
      .get<CommonResponse<PartnerFormModel>>(url);
  }

  createPartner(partner: PartnerOnboardingModel) {
    let url = `${API}/corporate/partners/onboarding`;
    return this.http
      .post<CommonResponse<PartnerFormModel>>(url, partner);
  }

  editPartner(partner: PartnerFormModel, partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}`;
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(url, partner);
  }

  deletePartner(partnerId: number) {
    let url = `${API}/${routes?.customers}/${partnerId}`;
    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(url);
  }
}

import {
  AccountModel, FilterAccountOperationalMetrics,
  FilterAccountOperationalSummary,
  FilterAccountSummaryModel,
} from '../../models/account.model';

export class GetAccountsData {
  static readonly type = '[Accounts] Get Wallet Data';
  constructor(public partnerId?: string, public property?: boolean) {}
}

export class GetLinkedAccounts {
  static readonly type = '[Accounts] Get Linked Accounts';
  constructor(public refUser?: string) {}
}

export class TransferAccountAmount {
  static readonly type = '[Accounts] Transfer Account Amount';
  constructor(public transferAccount: any) {}
}


export class UnlinkedAccount {
  static readonly type = '[Accounts] Unlinked Account';
  constructor(public accountId: string, public refUser?: string) {}
}

export class LinkAccount {
  static readonly type = '[Accounts] Link Account';
  constructor(public accountId: string, public refUser?: string) {}
}

export class SetAccountsForSelection {
  static readonly type = '[Accounts] Set Accounts for Selection';
  constructor() {}
}

export class SetCurrentAccount {
  static readonly type = '[Accounts] Set Current Account';
  constructor(public account: AccountModel) {}
}


export class GetAccountSummary {
  static readonly type = '[Accounts] Get Account Summary';
  constructor(public accountId: string, public filter: FilterAccountSummaryModel) {}
}

export class GetAccountOperationalSummary {
  static readonly type = '[Accounts] Get Account Operational Summary';
  constructor(public accountId: string, public filter?: FilterAccountOperationalSummary) {}
}

export class GetCorporateAccountOperationalSummary {
  static readonly type = '[Accounts] Get Account Corporate Operational Summary';
  constructor(public accountId: string, public filter?: FilterAccountOperationalSummary) {}
}

export class GetAccountOperationalBalance {
  static readonly type = '[Accounts] Get Account Operational Balance';
  constructor(public accountId: string, public filter?: FilterAccountOperationalSummary) {}
}

export class GetAccountMetrics {
  static readonly type = '[Accounts] Get Account Metrics';
  constructor(public accountId: string, public filter?: FilterAccountOperationalSummary) {}
}

export class ClearLinkedAccounts {
  static readonly  type = '[Accounts] Clear Linked Accounts';
}

export class GetPartnerBrands {
  static readonly type = '[Accounts] Get Partner Brands';
  constructor(public partnerId: string | number ) {}
}

export class GetAccountBalance {
  static readonly type = '[Accounts] Get Account Balance';
  constructor(public account: any ) {}
}


<div class="dashboard-container">
  <mjx-breadcrumb>
    <button breadActions *ngIf="showNewProfile && permissionProfile" mat-button class="custom-button" routerLink="profile-permissions/new" >
      <span>{{ 'USERS.PROFILE_PERMISSIONS.NEW' | translate }}</span>
    </button>
    <button breadActions *ngIf="showNewUser && permissionUser"
        mat-button class="custom-button breadcrumb-action-button" routerLink="new">
      <span>{{ 'USERS.NEW_BTN' | translate }}</span>
    </button>
  </mjx-breadcrumb>
  <mat-divider class="divider"></mat-divider>
  <router-outlet></router-outlet>
</div>

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {CommonResponse} from "../../../shared/models/response.model";
import { routes } from "../../../../environments/vendor/routes/routes";
import {
  AccountModel,
  AccountOperationalSummary, AccountTransactionsSummary,
  FilterAccountOperationalSummary,
  FilterAccountSummaryModel, FilterAccountTransactionsSummary
} from "../../models/account.model";
import {AccountsStateModel} from "../../state/accounts/models/accounts-state.model";
import {AccountsState} from "../../state/accounts/accounts.state";
import {Store} from "@ngxs/store";
import {AccountsFilterModel} from "../../state/accounts/models/accounts-filter.model";
import {HttpUtilsService} from "../../../shared/services/http-utils.service";
import { isPartner } from 'src/app/shared/utils/get-context';
import { PaginationModel } from 'src/app/shared/models/pagination.model';
import {PartnersApiService} from "../../../shared/services/partners/partners-api.service";
import {CorporateApiService} from "../../../shared/services/corporate/corporate-api.service";
import { TransactionRequestFilterModel } from 'src/app/modules/transactions/models/transaction-request-filter.model';
import { TransactionRequestModel } from 'src/app/modules/transactions/models/transaction-request.model';

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService,
    private store: Store,
    private partApiService: PartnersApiService,
    private corpApiService: CorporateApiService
  ) {}

  getAccounts(filter: Partial<AccountsFilterModel>) {
    let url = `${API}/${routes?.accounts}`;
    return this.http.get<CommonResponse<AccountModel>>(url, {
      params: filter
    });
  }

  unlinkAccount(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.request<CommonResponse<AccountModel>>('delete', url, { body:  { users: usersIds }});
  }

  linkAccounts(accountId: string, usersIds: string[]) {
    const url = `${API}/${routes?.accounts}/${accountId}/memberships`;
    return this.http.post<CommonResponse<AccountModel>>(url, { users: usersIds });
  }

  getLinkedAccounts(refUser: string) {
    let url = `${API}/${routes?.users}/${refUser}/accounts`;

    return this.http.get<CommonResponse<AccountModel[]>>(url);
  }

  getTransactionsBalance(accountId) {
    let url = `${API}/${routes?.accounts}/${accountId}/balance`;

    return this.http.get<CommonResponse<PaginationModel<any>>>(url);
  }

  getAccountMetrics(filter: Partial<any>){
    const corporateUrl = `${API}/corporate/analytics/account-metrics`;
    const partnerUrl = `${API}/partners/analytics/account-metrics`;
    return this.http.get<CommonResponse<any>>(isPartner ? partnerUrl : corporateUrl, {
      params: filter
    });
  }

  partnerBrands(partnerId: number | string) {
    let url = `${API}/partners/brands/accounts?refPartner=${partnerId}`;
    return this.http.get<CommonResponse<PaginationModel<any>>>(url)
  }

  getAccountBalance(accountId: number) {
    let url = `${API}/${routes?.accounts}/${accountId}/balance/remote`;
    return this.http.get<CommonResponse<PaginationModel<any>>>(url)
  }

  get currentAccount(): AccountModel {
    const state = this.store.selectSnapshot<AccountsStateModel>(AccountsState)
    return  state.currentAccount
  }
}

import {Component} from '@angular/core';
import {Subscription} from "rxjs";
import {Event, NavigationEnd, Router} from "@angular/router";
import {isPartner} from "../../shared/utils/get-context";
import {filter} from "rxjs/operators";
import {RoutesEnum} from "../../shared/enums/routes.enum";
import { SessionService } from 'src/app/core/services/session.service';
import { Permission, PermissionEntity } from 'src/app/core/models/permission.model';

@Component({
  selector: 'mjx-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent {
  routerSub: Subscription;
  showNewUser: boolean;
  showNewProfile: boolean;
  permissionProfile: boolean;
  permissionUser: boolean

  constructor(
    private router: Router,
    private sessionService: SessionService
  ) {
    this.listenRoute();
    this.permissionProfile = this.sessionService.userHasPermission(PermissionEntity.USER_ROLES, Permission.Create);
    this.permissionUser = this.sessionService.userHasPermission(PermissionEntity.USERS, Permission.Create);
  }

  get isPartnerContext(): boolean {
    return isPartner;
  }

  ngOnDestroy() {
    if (this.routerSub && !this.routerSub.closed) {
      this.routerSub.unsubscribe();
    }
  }

  private listenRoute() {
    this.routerSub = this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd)
      )
      .subscribe((res: NavigationEnd) => {
          this.showNewUser = res.url === `/${RoutesEnum.Users}/list`;
          this.showNewProfile = res.url === `/${RoutesEnum.Users}/profile-permissions`;
        }
      );
  }
}

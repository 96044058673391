import { Component, OnInit } from '@angular/core';
import {UntypedFormBuilder, Validators} from "@angular/forms";
import {UserFormModel, UserModel} from "../../../models/user.model";
import {Select, Store} from "@ngxs/store";
import {UsersState} from "../../../states/users.state";
import {Observable, Subscription, tap} from "rxjs";
import {PartnersState} from "../../../../partners/state/partners.state";
import {PartnerModel} from "../../../../partners/models/partner.model";
import {UserTypeEnum} from "../../../../../shared/enums/user-type.enum";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../../../../core/services/session.service";
import {ClearLinkedAccounts} from "../../../../../core/state/accounts/accounts.actions";
import {
  ClearResetKey,
  ClearUserDetails, CreateUser, EditUser,
  ResetSavingUsersState, ResetUserPassword, UpdateUserPassword
} from "../../../states/users.actions";
import {GetPartnersList} from "../../../../partners/state/partners.actions";
import {ResetPasswordDialogComponent} from "../reset-password-dialog/reset-password-dialog.component";
import {RoutesEnum} from "../../../../../shared/enums/routes.enum";
import {filter, map} from "rxjs/operators";
import {
  emailValidator,
  emptySpaces,
  lowercaseValidator, maxLengthTrimValidator, minLengthTrimValidator, numberValidator, specialCharacterValidator,
  uppercaseValidator,
  usernamePattern, whiteSpacesValidator
} from "../../../../../shared/utils/password-validators";
import {PartnerUsersDataFormComponent} from "../partner-users-data-form/partner-users-data-form.component";
import {AppContexEnum} from "../../../../../shared/enums/app-contex.enum";
import {GetUserRoles} from "../../../states/profile-permissions/profile-permissions.actions";

@Component({
  selector: 'mjx-corporate-users-data-form',
  templateUrl: './corporate-users-data-form.component.html',
  styleUrls: ['./corporate-users-data-form.component.scss']
})
export class CorporateUsersDataFormComponent extends PartnerUsersDataFormComponent implements OnInit {
  @Select(PartnersState.getPartnersList)
  partners$: Observable<PartnerModel[]>;

  @Select(PartnersState.getPartnersListLoading)
  loadingPartner$: Observable<PartnerModel[]>;

  @Select(UsersState.getFetchingKey)
  isFetchingKey$: Observable<boolean>;

  @Select(UsersState.getResetKey)
  resetKey: Observable<string>;

  resetKeySubs: Subscription;

  constructor(
    override fb: UntypedFormBuilder,
    override dialog: MatDialog,
    override router: Router,
    override activatedRoute: ActivatedRoute,
    override store: Store,
    override sessionService: SessionService
  ) {
    super(fb, dialog, router, activatedRoute, store, sessionService)
    this.buildUserForm(); }

  override ngOnInit(): void {
    this.store.dispatch(new GetUserRoles(null));
    this.store.dispatch(new ClearLinkedAccounts());
    this.store.dispatch(new ClearUserDetails());
    this.listenRouteState();

    if (!this.isEdit) {
      this.listenRefCustomer();
      // this.listenUserType();
      this.store.dispatch(new GetPartnersList());
    }

    this.listenDetailedUser();

    this.store.dispatch(new ResetSavingUsersState());

    this.resetKeySubs = this.resetKey.subscribe(key => {
      if (key) {
        this.openResetPasswordResultDialog(key);
      }
    });
  }

  protected override listenDetailedUser() {
    this.detailedUserSubs = this.userData.subscribe(user => {
      if (user) {
        this.isEdit = true;
        this.user = user;
        this.disableInputs();

        this.populateEditForm();
      }
    });
  }

  override ngOnDestroy() {
    if (this.userTypeSubs && !this.userTypeSubs.closed) {
      this.userTypeSubs.unsubscribe();
    }

    if (this.refCustomerSubs && !this.refCustomerSubs.closed) {
      this.refCustomerSubs.unsubscribe();
    }

    if (this.resetKeySubs && !this.resetKeySubs.closed) {
      this.resetKeySubs.unsubscribe();
    }

    if (this.detailedUserSubs && !this.detailedUserSubs.closed) {
      this.detailedUserSubs.unsubscribe();
    }
  }

  override get disableAccountsForm(): boolean {
    return !(this.selectedPartnerId && this.user.id);
  }

  override doSave() {
    const formValue: UserFormModel = this.userForm.value;
    const userObj: UserFormModel = this.sanitizeForm(formValue)

    if (this.isEdit) {
      if (this.isProfile && this.isEditAccountData) {
        const {currentPassword, password, confirmPassword} = this.userForm.value;

        this.store.dispatch(new UpdateUserPassword(currentPassword, password, confirmPassword));

        this.disableEdition();
      } else if (this.isEditUserData) {
        this.store.dispatch(new EditUser(userObj, this.user.id));
      }
    } else {
      delete userObj.confirmPassword
      delete userObj.document;
      delete userObj.docType;

      if (userObj.refCustomer === null || userObj.refCustomer === undefined) {
        delete userObj.refCustomer;
      }

      this.store.dispatch(new CreateUser(userObj));
    }
  }

  openResetPasswordResultDialog(key) {
    const pageHostname = window.location.hostname;
    const pageProtocol = window.location.protocol;
    const userObj: UserFormModel = this.sanitizeForm(this.userForm.value)
    const userType = userObj.roles.includes(UserTypeEnum.PartnerDefault)
    let pageHost = window.location.host;

    if (pageHostname !== 'localhost') {
      if(document.documentURI.includes('corporate')) {
        pageHost = userType ? this.replaceUrl(pageHost, AppContexEnum.Partners) : this.replaceUrl(pageHost, AppContexEnum.Corporate);
      } else {
        pageHost = this.replaceUrl(pageHost, AppContexEnum.Partners);
      }
    }
    const data = {link: `${pageProtocol}//${pageHost}/reset/${key}`, isNewUser: !this.isEdit, userDetails: null};

    if (!this.isEdit) {
      data.userDetails = this.store.selectSnapshot(UsersState.getUser);
    }

    const dialog$ = this.dialog.open(ResetPasswordDialogComponent, {
      width: '500px',
      panelClass: 'custom-modal',
      data
    });

    dialog$.afterClosed()
      .subscribe((result: UserModel) => {
        this.store.dispatch(new ClearResetKey());

        if (result.id && !this.isEdit) {
          this.user = result;
          this.isEdit = true;
        } else {
          !this.isEdit && this.router.navigateByUrl(`/${RoutesEnum.Users}`)

        }
      });

  }

  requestPasswordResetKey() {
    this.store.dispatch(new ResetUserPassword(this.user.username));
  }

  private replaceUrl(original, newWord) {
    const regex = /^[^.]+/;
    const firstWord = regex.exec(original)[0];

    if (firstWord) {
      original = original.replace(firstWord, newWord);
    }

    return original;
  }

  private disableInputs() {
    this.userForm.get('userType').disable();
    this.userForm.get('username').disable();
    this.userForm.get('password').disable();
    this.userForm.get('confirmPassword').disable();

    if (!this.isProfile) {
      this.userForm.clearValidators();
    }
  }

  private listenRefCustomer() {
    this.refCustomerSubs = this.userForm.get('refCustomer')
      .valueChanges
      .subscribe(value => {
        if (value) {
          this.selectedPartnerId = value;
        }
      });
  }

  // private listenUserType() {
  //   this.userTypeSubs = this.userForm.get('userType')
  //     .valueChanges
  //     .pipe(
  //       filter(() => !this.isEdit),
  //       tap((value) => {
  //         this.hasUserType = !!(value);
  //         if (value !== UserTypeEnum.Corporate && value !== UserTypeEnum.CorporateSupport) {
  //           this.userForm.get('refCustomer').enable();
  //           this.userForm.get('password').disable();
  //           this.userForm.get('confirmPassword').disable();
  //           this.hideReset = true;
  //         } else {
  //           this.userForm.get('refCustomer').disable()
  //           this.userForm.get('password').enable();
  //           this.userForm.get('confirmPassword').enable();
  //           this.hideReset = false;
  //         }
  //       }))
  //     .subscribe();
  // }

  protected override listenRouteState() {
    this.activatedRoute
      .paramMap
      .pipe(
        map(() => {
          const state: any = window?.history?.state;
          const isEditPage = (window.document.documentURI).includes('edit');

          this.isProfile = state?.profile ?? false;
          this.user = (state as UserModel);
          this.isEdit = isEditPage;

          if (state?.id) {
            if (state?.refCustomer) {
              this.selectedPartnerId = state?.refCustomer;
            }

            if (!this.isProfile) {
              this.getUserDetails(this.user.id);
            }

            this.buildUserForm();
            this.populateEditForm();
          } else if (isEditPage) {
            this.navigateBack();
          } else {
            this.buildUserForm();
          }

        })
      )
      .subscribe();
  }

  protected override buildUserForm() {
    if (this.isEdit || this.isProfile) {
      this.userForm = this.fb.group({
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        roles: [this.EMPTY],
        userType: [this.EMPTY],
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          usernamePattern(),
          whiteSpacesValidator()
        ]],
        currentPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250)
        ]],
        password: ['PLACEHOLDER', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: ['PLACEHOLDER#1a', [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, { validator: this.checkPasswords });

      if (this.isProfile) {
        this.userForm.get('userType').disable();
        this.userForm.get('username').disable();
      } else {
        this.disableInputs();
      }
    } else if (!this.isEdit) {
      this.userForm = this.fb.group({
        userType: [this.EMPTY, [
          Validators.required
        ]],
        username: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(20),
          emptySpaces(),
          usernamePattern(),
          whiteSpacesValidator()
        ]],
        email: [this.EMPTY, [
          Validators.required,
          emailValidator(),
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        fullName: [this.EMPTY, [
          Validators.required,
          minLengthTrimValidator(5),
          maxLengthTrimValidator(100),
          whiteSpacesValidator()
        ]],
        roles: [this.EMPTY, [
          Validators.required,
        ]],
        refCustomer: [this.EMPTY, [
          Validators.required
        ]],
        password: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]],
        confirmPassword: [this.EMPTY, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(250),
          Validators.compose([
            lowercaseValidator(),
            uppercaseValidator(),
            numberValidator(),
            specialCharacterValidator()
          ])
        ]]
      }, {validator: this.checkPasswords});
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'maskCpf'
})
export class MaskCpfPipe implements PipeTransform {
  transform(value: string | number): string {
    if (!value) return '';
    const stringValue = value.toString();

    if (stringValue.length === 11) {
      return stringValue.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '•••.$2.$3-••');
    } else {
      return stringValue;
    }
  }
}

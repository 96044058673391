export const partnerAllowedPermissionsSet: Set<string> = new Set([
  'Accounts.CreateMembership',
  'Accounts.DeleteMembership',
  'Accounts.Get',
  'Accounts.GetBalance',
  'Accounts.GetBalanceHistory',
  'Accounts.GetPreferences',
  'Accounts.GetReportOperationalSummary',
  'Accounts.GetReportTransactionsSummary',
  'Accounts.GetTransactions',
  'Accounts.InvokeTransactionWebhook',
  'Accounts.List',
  'Accounts.ListEntries',
  'Accounts.ListForUser',
  'Accounts.ListMembership',
  'Accounts.ListTransactions',
  'Accounts.UpdatePreferences',
  'Analytics.AccountMetrics',
  'Analytics.HeatMap',
  'ApiKeys.Create',
  'ApiKeys.Delete',
  'ApiKeys.List',
  'ApiKeys.Revoke',
  'Auth.WhoAmI',
  'Auth.MFAAppAuthConfirm',
  'Auth.MFAAppAuthSetup',
  'Auth.MFAValidate',
  'Auth.MFAAppAuthRevoke',
  'DataExport.Create',
  'DataExport.Get',
  'DataExport.GetDownloadUrl',
  'DataExport.List',
  'DataExport.UpdatePartial',
  'Documents.Delete',
  'Documents.Download',
  'Documents.List',
  'Documents.Upload',
  'Participants.Check',
  'PolicyRules.ChangeStatus',
  'PolicyRules.Create',
  'PolicyRules.Delete',
  'PolicyRules.List',
  'PolicyRules.Update',
  'PolicyRules.Get',
  'Recipients.Create',
  'Recipients.CreateBankAccounts',
  'Recipients.CreateCnaes',
  'Recipients.Delete',
  'Recipients.DeleteBankAccounts',
  'Recipients.DeleteCnaes',
  'Recipients.Get',
  'Recipients.GetBankAccounts',
  'Recipients.List',
  'Recipients.ListCnaes',
  'Recipients.Update',
  'TransactionRequests.Cancel',
  'TransactionRequests.Create',
  'TransactionRequests.Get',
  'TransactionRequests.List',
  'Transactions.Get',
  'Transactions.List',
  'UserRoles.List',
  'Users.Create',
  'Users.Delete',
  'Users.Get',
  'Users.List',
  'Users.PasswordChange',
  'Users.PasswordReset',
  'Users.Update',
]);

<div class="filter-container">
  <filter-users [filterName]="filterName" [fieldsToDisplay]="filterFields" [disableRule]="(isLoading$ | async)"
    (filterEvent)="filterUsers($event)" ></filter-users>
</div>

<ng-container *ngIf="(isLoading$ | async)" [ngTemplateOutlet]="loadingSpinner"></ng-container>
<mjx-table [hidden]="(isLoading$ | async) || (isEmpty$ | async)"
           #customTable
           [displayedColumns]="usersTableColumns"
           [columnsDefinitions]="usersTableColumnsDef"
           [showColumnsFilter]="true"
           [tableName]="isPartner ? 'partner_list_users_table' : 'corporate_list_users_table'"
           [dataSource]="(userDataSource$ | async) | datasource"
           (tableEvents)="listenTableEvents($event)"
           [hiddenPagination]="(hasError$ | async)">

  <mjx-custom-mat-column columnName="userType">
    <ng-template let-item>
      {{ item?.lastRole }}
    </ng-template>
  </mjx-custom-mat-column>

  <mjx-custom-mat-column columnName="refCustomer">
    <ng-template let-item>
      {{ item?.partnerName }}
    </ng-template>
  </mjx-custom-mat-column>
</mjx-table>
<ng-container *ngIf="!(isLoading$ | async) && ((isEmpty$ | async) || (hasError$ | async))" [ngTemplateOutlet]="emptyMessage"></ng-container>

<ng-template #loadingSpinner>
  <div class="spinner-container">
    <mat-progress-spinner class="custom-spinner"
                          diameter="60"
                          mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #emptyMessage>
  <mjx-empty-message icon="person" (action)="getUsers()" emptyMessage="USERS.TABLE.EMPTY"></mjx-empty-message>
</ng-template>

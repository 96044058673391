export class GetAppSetupKey {
  static readonly type = '[User Security] Get App Setup Key';
}

export class GetAppSetupQrCode {
  static readonly type = '[User Security] Get App Setup Qr Code';
  constructor(public secret: string) { }
}

export class ConfirmAppSetup {
  static readonly type = '[User Security] Confirm Setup';
  constructor(public code: string) { }
}

export class DeactivateAppSetup {
  static readonly type = '[User Security] Deactivate Setup';
  constructor(public code: string) { }
}

export class ResetAppSetup {
  static readonly type = '[User Security] Reset App Setup';
}

export class ResetAppPartner {
  static readonly type = '[User Security] Reset App Partner';
  constructor(public userId: string) { }
}

export class DeactivateAppSetupPartner {
  static readonly type = '[User Security] Deactivate App Partner';
  constructor(public userId: string) { }
}

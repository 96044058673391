import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ProfilePermissionsState } from '../../../states/profile-permissions/profile-permissions.state';
import {
  CreateRole,
  GetAllRoles,
  GetRoleAssociations,
  UpdateRole,
} from '../../../states/profile-permissions/profile-permissions.actions';
import {
  Permission,
  PermissionEntity,
  UserPermissions,
} from '../../../../../core/models/permission.model';
import { RoleModel } from '../../../models/roles.model';
import { PermissionData } from 'src/app/shared/models/has-permission-parameter.model';
import { corporateAllowedPermissionsSet } from 'src/app/shared/utils/maps/corporate-permisisons-allowed';
import { partnerAllowedPermissionsSet } from 'src/app/shared/utils/maps/partners-permissions-allowed';
import { SessionService } from 'src/app/core/services/session.service';

interface RoleFormObj {
  name: string;
  formGroup?: FormGroup;
  formControlNames?: string[];
}
@Component({
  selector: 'mjx-detailed-profiles-permissions',
  templateUrl: './detailed-profiles-permissions.component.html',
  styleUrls: ['./detailed-profiles-permissions.component.scss'],
})
export class DetailedProfilesPermissionsComponent implements OnInit, OnDestroy {
  readonly inputPattern = '[^/]*';
  roleForm: FormGroup;

  profiles = ['Corporate', 'Partner'];
  availableRoles: UserPermissions;
  rolesForms: RoleFormObj[];

  roleAssoSub: Subscription;
  getAllRolesSubs: Subscription;
  getLastCreated: Subscription;
  saveSubs: Subscription;
  savingSubs: Subscription;
  permission: string[] = [];

  @Select(ProfilePermissionsState.isSavingRole)
  savingRole$: Observable<boolean>;

  @Select(ProfilePermissionsState.isLoading)
  isLoading$: Observable<boolean>;

  @Select(ProfilePermissionsState.isSavingRole)
  isSavingRole$: Observable<boolean>;

  @Select(ProfilePermissionsState.lastCreated)
  lastCreated$: Observable<RoleModel>;
  lastCreated: RoleModel;

  isEdit = false;

  permissionData: PermissionData = {
    entity: PermissionEntity.USERS,
    type: Permission.List,
  };

  constructor(
    private fb: FormBuilder,
    private store: Store,
    private actions$: Actions,
    private sessionService: SessionService
  ) {
    this.buildForm();
  }

  ngOnInit(): void {
    this.store.dispatch(new GetAllRoles());
    this.listenGetAllRoles();

    this.listenLoading();
    this.listenSaveRole();

    if (document.documentURI.includes('edit') || document.documentURI.includes('details')) {
      this.isEdit = true;
      this.lastCreated = this.store.selectSnapshot(ProfilePermissionsState.lastCreated);
      this.populateRoleForm(this.lastCreated);
      if(this.lastCreated?.visibility) {
        this.roleForm.get('title').disable();
      }
    }
  }

  ngOnDestroy() {
    const subs = [this.roleAssoSub, this.getAllRolesSubs, this.saveSubs, this.savingSubs];

    subs.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
  }


  formPermissionsToString(form: RoleFormObj[]): string[] {
    // ['Auth.Create']
    const formList = [];

    form.forEach((role) => {
      role.formControlNames.forEach((permission) => {
        if (role.formGroup.get(permission).value) {
          formList.push(`${role.name}.${permission}`);
        }
      });
    });

    return formList;
  }

  save() {
    if (this.lastCreated) {
      this.roleForm.get('associations').setValue(this.formPermissionsToString(this.rolesForms));
      if (this.isEdit) {
        this.store.dispatch(new UpdateRole(this.lastCreated.name, this.roleForm.value));
      }
    } else {
      this.roleForm.get('associations').disable();
      this.store.dispatch(new CreateRole(this.roleForm.value));
    }
  }

  generateRolesForm(roles: UserPermissions): void {
    const rolesForm: RoleFormObj[] = [];
    const domain = this.roleForm.get('domain').value;
    const permissionsSet =
      domain === 'Corporate' ? corporateAllowedPermissionsSet : partnerAllowedPermissionsSet;
    const sortedKeys = Object.keys(roles).sort((a, b) => a.localeCompare(b));

    sortedKeys.forEach((key) => {
      const allowedPermissions = Array.from(permissionsSet).filter((p) => p.startsWith(key));
      if (allowedPermissions.length > 0) {
        const roleObj: RoleFormObj = {
          name: key,
          formGroup: this.fb.group({}),
          formControlNames: [],
        };

        roles[key].forEach((permission) => {
          const permissionKey = `${key}.${permission}`;
          if (permissionsSet.has(permissionKey)) {
            roleObj.formGroup.addControl(permission, new FormControl(false));
            roleObj.formControlNames.push(permission);
          }
        });

        roleObj.formGroup.addControl('all', new FormControl(false));
        rolesForm.push(roleObj);
      }
    });

    this.rolesForms = rolesForm;
  }

  allSelect(form: FormGroup, checked: boolean) {
    const values = Object.keys(form.value);
    const newValue = {};
    values.forEach((key) => (newValue[key] = checked));

    form.patchValue(newValue);
  }

  anySelected(form: FormGroup, checked: boolean) {
    if (!checked) {
      form.get('all').setValue(false);
    } else {
      form.get('all').setValue(this.isAllSelected(form));
    }
  }

  isAuthPermission(controlName: string): boolean {
    const authPermissions = [
      'MFAAppAuthConfirm',
      'MFAAppAuthRevoke',
      'MFAAppAuthSetup',
      'MFAValidate',
      'RefreshToken',
      'WhoAmI',
    ];
    return authPermissions.includes(controlName);
  }

  listenRoleAssociations() {
    this.roleAssoSub = this.actions$.pipe(ofActionSuccessful(GetRoleAssociations)).subscribe(() => {
      const currentRole = this.store.selectSnapshot(ProfilePermissionsState.lastCreated);
      const authPermissions = [
        'Auth.WhoAmI',
        'Auth.MFAAppAuthConfirm',
        'Auth.MFAAppAuthRevoke',
        'Auth.MFAAppAuthSetup',
        'Auth.MFAValidate',
        'Auth.RefreshToken',
      ];

      this.permission = currentRole?.privileges?.length > 0 ? currentRole?.privileges : authPermissions;
      this.setPermissionsOnForm();
    });
  }

  private setPermissionsOnForm() {
    this.permission.forEach((permission) => {
      const [entity, action] = permission.split('.');

      this.rolesForms.forEach((role) => {
        if (role.name === entity) {
          role.formGroup.get(action).setValue(true);
        }

        if (this.isAllSelected(role.formGroup) && !role.formGroup.get('all').value) {
          role.formGroup.get('all').setValue(true);
        }
      });
    });
  }

  listenGetAllRoles() {
    this.getAllRolesSubs = this.actions$.pipe(ofActionSuccessful(GetAllRoles)).subscribe(() => {
      const currentRoles = this.store.selectSnapshot(ProfilePermissionsState.availableRoles);

      this.availableRoles = currentRoles;
      this.generateRolesForm(currentRoles);

      if (this.isEdit) {
        this.listenRoleAssociations();
        this.store.dispatch(new GetRoleAssociations(this.lastCreated.name));
      }
    });
  }

  listenSaveRole() {
    this.saveSubs = this.actions$.pipe(ofActionSuccessful(CreateRole)).subscribe(() => {
      this.lastCreated = this.store.selectSnapshot(ProfilePermissionsState.lastCreated);
      this.isEdit = true;
    });
  }

  private listenLoading() {
    this.savingSubs = this.savingRole$.subscribe((isSaving) => {
      if (isSaving) {
        this.rolesForms?.forEach((role) => {
          role.formGroup.disable();
        });
      } else {
        this.rolesForms?.forEach((role) => {
          role.formGroup.enable();
        });
      }
    });
  }

  private isAllSelected(form: FormGroup): boolean {
    const formValues = form.value;
    delete formValues.all;

    return Object.values(formValues)?.every((value) => value === true);
  }

  private populateRoleForm(role: RoleModel) {
    this.roleForm.patchValue(role);
    this.roleForm.get('domain').disable();
    this.roleForm.get('name').disable();
  }

  private buildForm() {
    this.roleForm = this.fb.group({
      name: [null, Validators.required],
      title: ['', Validators.required],
      domain: [null, Validators.required],
      type: ['Customized', Validators.required],
      associations: [[]],
    });
  }
}

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MjxTableColumnDefinition} from "../../../../shared/modules/mjx-table/models/mjx-table.model";
import {UsersTableUtilsService} from "../../utils/users-table.utils";
import {MjxTableDataSource} from "../../../../shared/modules/mjx-table/mjx-table-datasource";
import {Select, Store} from "@ngxs/store";
import {GetAccountsData, GetLinkedAccounts} from "../../../../core/state/accounts/accounts.actions";
import {AccountsState} from "../../../../core/state/accounts/accounts.state";
import {AccountModel} from "../../../../core/models/account.model";
import {catchError, finalize, Observable, Subscription, tap, throwError} from "rxjs";
import {AccountsService} from "../../../../core/services/accounts/accounts.service";
import {ResponseStatusCodeEnum} from "../../../../shared/enums/response-status-code.enum";
import {MjxTableComponent} from "../../../../shared/modules/mjx-table/mjx-table.component";
import {MjxTableEventsModel} from "../../../../shared/modules/mjx-table/models/mjx-table-events.model";
import {ConfirmDialogComponent} from "../../../../shared/components/confirm-dialog/confirm-dialog.component";
import {ConfirmDialogModel} from "../../../../shared/models/confirm-dialog.model";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {MatSelect} from "@angular/material/select";
import {isPartner} from "../../../../shared/utils/get-context";
import { PermissionData } from 'src/app/shared/models/has-permission-parameter.model';
import { Permission, PermissionEntity } from 'src/app/core/models/permission.model';

@Component({
  selector: 'users-linked-accounts',
  templateUrl: './users-linked-accounts.component.html',
  styleUrls: ['./users-linked-accounts.component.scss']
})
export class UsersLinkedAccountsComponent implements OnInit {
  @Input('partnerId') set getAccountsFromPartnerId(id: string) {
    this.partnerId = id;

    if (id) {
      this.store.dispatch(new GetAccountsData(id));
    }
  }

  @Input('userId') set getUserId(id: any) {
    this.userId = id;
    if (id) {
      this.store.dispatch(new GetLinkedAccounts(id));
    }
  };

  partnerId: any;
  userId: any;

  @ViewChild(MjxTableComponent) usersTable: MjxTableComponent;
  @ViewChild('accountSelect') matSelect: MatSelect;

  @Select(AccountsState.getAccounts)
  accounts$: Observable<AccountModel[]>;

  @Select(AccountsState.getLoadingState)
  loadingAccounts$: Observable<boolean>

  @Select(AccountsState.getLinkedAccountsDataSource)
  linkedAccounts$: Observable<MjxTableDataSource<AccountModel>>;

  @Select(AccountsState.isLoadingLinkedAccounts)
  isLoading$: Observable<boolean>;

  tableColumns: string[];
  tableColumnsDef: MjxTableColumnDefinition[];
  dataSource: MjxTableDataSource<any>;

  selectedAccounts: any[] = [];
  savedAccounts: AccountModel[] = [];
  linkedAccountsSubs: Subscription;

  isNewActive = false;
  allSelected = false;
  newActiveIndex = 0;
  newObj: any;
  isLoading = false;
  isLoadingTable = false;

  permissionCreate: PermissionData = {
    entity: PermissionEntity.ACCOUNTS,
    type: Permission.CreateMembership
  }

  constructor(
    private usersTableUtils: UsersTableUtilsService,
    private store: Store,
    private accountsService: AccountsService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.tableColumns = usersTableUtils.getAccountsColumns();
    this.tableColumnsDef = usersTableUtils.getAccountsColumnsDef();
    this.dataSource = new MjxTableDataSource<any>([], 0);
  }

  ngOnInit(): void {
    this.linkedAccountsSubs = this.linkedAccounts$.subscribe((accounts) => {
      if (accounts?.data?.length > 0) {
        this.selectedAccounts = accounts.data.map(account => this.getAccountId(account));
        this.updateTableFooterVisibility();
      }
    });
  }

  get showTableFooter(): boolean {
    return this.isNewActive && !this.allSelected;
  }

  get noAccountsMsg(): string {
    return 'USERS.EMPTY_ACCOUNTS';
  }

  addNewAccount() {
    this.isNewActive = true;
  }

  saveAccount(account: AccountModel) {
    this.isLoading = true;

    this.accountsService.linkAccounts(this.getAccountId(account), [this.userId])
      .pipe(
        tap((res) => {
          if (res.statusCode === ResponseStatusCodeEnum.Done) {
            this.savedAccounts.push(account);
            this.selectedAccounts.push(this.getAccountId(account));

            this.matSelect.writeValue(null);
            this.updateTableFooterVisibility();
          }
        }),
        catchError((err) => throwError(err)),
        finalize(() => {
          this.isLoading = false
          this.store.dispatch(new GetLinkedAccounts(this.userId));
        })
      )
      .subscribe();
  }

  private deleteAccount(account: AccountModel) {
    this.isLoading = true;

    this.accountsService.unlinkAccount(this.getAccountId(account), [this.userId])
      .pipe(
        tap(() => {
          this.selectedAccounts = this.selectedAccounts.filter(id => id !== this.getAccountId(account));
          this.updateTableFooterVisibility();
        }),
        catchError((err) => throwError(err)),
        finalize(() => {
          this.isLoading = false;
          this.store.dispatch(new GetLinkedAccounts(this.userId));
        })
      )
      .subscribe();
  }

  private updateTableFooterVisibility() {
    const accounts = this.store.selectSnapshot(AccountsState.getAccounts);

    let isAll = true;
    accounts.forEach(item => {
      if (!this.selectedAccounts.includes(`${this.getAccountId(item)}`)) {
        isAll = false;
      }
    });

    if (!isAll) {
      this.isNewActive = false;

    }

    this.allSelected = isAll;
  }

  listenTableEvents(event: MjxTableEventsModel) {
    switch (event.name) {
      case 'users-account-delete':
        this.openDeleteDialog(event.data);
        break;
      case 'table-pagination':
        break;
    }
  }

  getAccountId(account: AccountModel) {
    return (isPartner) ? account.uid : `${account.id}`;
  }

  private openDeleteDialog(account: AccountModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-modal',
      width: '500px',
      data: ({
        title: this.translate.instant('USERS.DELETE_ACCOUNT.MODAL_TITLE'),
        text: this.translate.instant('USERS.DELETE_ACCOUNT.MODAL_TEXT', {
          number: account.accountNumber,
          name: account?.accountName ? `- ${account.accountName}` : ''
        })
      } as ConfirmDialogModel)
    })

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteAccount(account);
      }
    });
  }
}

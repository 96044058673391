import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MjxTableEventsModel } from '../../../../../shared/modules/mjx-table/models/mjx-table-events.model';
import { MjxTableColumnDefinition } from '../../../../../shared/modules/mjx-table/models/mjx-table.model';
import { MjxTableDataSource } from '../../../../../shared/modules/mjx-table/mjx-table-datasource';
import { ProfilesPermissionsTableUtilsService } from '../../../utils/profiles-permissions-table.utils';
import { Actions, ofActionSuccessful, Select, Store } from '@ngxs/store';
import { ProfilePermissionsState } from '../../../states/profile-permissions/profile-permissions.state';
import {
  DeleteRole,
  GetUserRoles,
  SetLastCreated,
} from '../../../states/profile-permissions/profile-permissions.actions';
import { RoleModel } from '../../../models/roles.model';
import { PageEvent } from '@angular/material/paginator';
import { RequestDataFilterModel } from '../../../../../shared/models/request-filter.model';
import { ConfirmDialogComponent } from '../../../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from '../../../../../shared/models/confirm-dialog.model';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { RoutesEnum } from '../../../../../shared/enums/routes.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'mjx-list-profiles-permissions',
  templateUrl: './list-profiles-permissions.component.html',
  styleUrls: ['./list-profiles-permissions.component.scss'],
})
export class ListProfilesPermissionsComponent implements OnInit {
  @Select(ProfilePermissionsState.isLoading)
  isLoading$: Observable<boolean>;

  @Select(ProfilePermissionsState.isEmpty)
  isEmpty$: Observable<boolean>;

  @Select(ProfilePermissionsState.datasource)
  dataSource$: Observable<MjxTableDataSource<RoleModel>>;

  @Select(ProfilePermissionsState.isLoading)
  hasError$: Observable<boolean>;

  tableColumns: string[];
  tableColumnsDef: MjxTableColumnDefinition[];

  currentFilter = this.defaultFilter();

  constructor(
    private profilePermissionsTableUtils: ProfilesPermissionsTableUtilsService,
    private store: Store,
    private dialog: MatDialog,
    private translate: TranslateService,
    private actions$: Actions,
    private router: Router
  ) {
    this.tableColumns = this.profilePermissionsTableUtils.getColumns();
    this.tableColumnsDef = this.profilePermissionsTableUtils.getColumnsDef();
  }

  ngOnInit(): void {
    this.listenDeleteRole();
    this.store.dispatch(new GetUserRoles(this.currentFilter));
  }

  listenTableEvents(event: MjxTableEventsModel) {
    if (event.name === 'table-pagination') {
      // this.changeDataPage(event.data);
    } else if (event.name === 'profiles-permissions-delete') {
      this.openDeleteDialog(event.data);
    } else if (event.name === 'profiles-permissions-edit') {
      this.editRole(event.data);
    } else if (event.name === 'profiles-permissions-details') {
      const data = {
        ...event.data,
        visibility: true
      }
      this.detailRole(data);
    }
  }

  private editRole(role: RoleModel) {
    this.store.dispatch(new SetLastCreated(role));
    this.router.navigateByUrl(`${RoutesEnum.Users}/profile-permissions/edit`);
  }

  private detailRole(role: RoleModel) {
    this.store.dispatch(new SetLastCreated(role));
    this.router.navigateByUrl(`${RoutesEnum.Users}/profile-permissions/details`);
  }

  // private changeDataPage(event: PageEvent) {
  //   const filter = {
  //     dataset: {
  //       offset: (event.pageSize * event.pageIndex),
  //       limit: event.pageSize,
  //     }
  //   }

  //   this.currentFilter.dataset = filter.dataset;

  //   this.store.dispatch(new GetUserRoles(filter));

  // }

  private defaultFilter(): RequestDataFilterModel<any> {
    return {
      dataset: {
        offset: 0,
        limit: 10,
      },
    };
  }

  private listenDeleteRole() {
    this.actions$.pipe(ofActionSuccessful(DeleteRole)).subscribe(() => {
      this.store.dispatch(new GetUserRoles(this.currentFilter));
    });
  }

  private openDeleteDialog(role: RoleModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-modal',
      width: '500px',
      data: {
        title: this.translate.instant('USERS.PROFILE_PERMISSIONS.MODAL.TITLE'),
        text: `${this.translate.instant('USERS.PROFILE_PERMISSIONS.MODAL.TEXT', {
          role: role.name,
        })}`,
      } as ConfirmDialogModel,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.store.dispatch(new DeleteRole(role.name));
      }
    });
  }
}

import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import {
  defaultProfilePermissionsState,
  ProfilePermissionsStateModel,
} from './profile-permissions-state.model';
import {
  ClearLastCreated,
  CreateRole,
  CreateRoleAssociation,
  DeleteRole,
  GetAllRoles,
  GetRoleAssociations,
  GetUserRoles,
  SetLastCreated,
  UpdateRole,
} from './profile-permissions.actions';
import { delay, finalize, of, tap } from 'rxjs';
import { MjxTableDataSource } from '../../../../shared/modules/mjx-table/mjx-table-datasource';
import { CorporateApiService } from '../../../../shared/services/corporate/corporate-api.service';
import { PartnersApiService } from '../../../../shared/services/partners/partners-api.service';
import { PrivilegeModel, RoleModel } from '../../models/roles.model';
import { CommonResponse } from '../../../../shared/models/response.model';
import { PaginationModel } from '../../../../shared/models/pagination.model';
import { mapPermissionsToEntity } from '../../../../core/models/permission.model';
import { SnackbarService } from '../../../../shared/services/snackbar.service';
import { isPartner } from '../../../../shared/utils/get-context';
import { Router } from '@angular/router';
import { RoutesEnum } from 'src/app/shared/enums/routes.enum';

@State<ProfilePermissionsStateModel>({
  name: 'profilePermissions',
  defaults: defaultProfilePermissionsState,
})
@Injectable()
export class ProfilePermissionsState implements NgxsOnInit {
  constructor(
    private corporateService: CorporateApiService,
    private partnersService: PartnersApiService,
    private store: Store,
    private snackbar: SnackbarService,
    private router: Router,
    private ngZone: NgZone
  ) {}

  @Selector()
  static hasError(state: Partial<ProfilePermissionsStateModel>) {
    return state.hasError;
  }

  @Selector()
  static isLoading(state: Partial<ProfilePermissionsStateModel>) {
    return state.isLoading;
  }

  @Selector()
  static isEmpty(state: Partial<ProfilePermissionsStateModel>) {
    return state.isEmpty;
  }

  @Selector()
  static datasource(state: Partial<ProfilePermissionsStateModel>) {
    return state.datasource;
  }

  @Selector()
  static isSavingRole(state: Partial<ProfilePermissionsStateModel>) {
    return state.isSavingRole;
  }

  @Selector()
  static lastCreated(state: Partial<ProfilePermissionsStateModel>) {
    return state.lastCreated;
  }

  @Selector()
  static availableRoles(state: Partial<ProfilePermissionsStateModel>) {
    return state.availableRoles;
  }

  @Selector()
  static roles(state: Partial<ProfilePermissionsStateModel>) {
    return state.allRoles;
  }

  ngxsOnInit(ctx?: StateContext<ProfilePermissionsStateModel>): any {
    ctx.setState(defaultProfilePermissionsState);
  }

  @Action(GetAllRoles)
  getAllRoles(ctx: StateContext<ProfilePermissionsStateModel>) {
    ctx.patchState({
      isLoading: true,
      isEmpty: false,
      hasError: false,
    });

    return this.corporateService.getAllRoles().pipe(
      tap((res: CommonResponse<PaginationModel<PrivilegeModel>>) => {
        ctx.patchState({
          availableRoles: mapPermissionsToEntity(this.mapPrivilegesToPermissions(res.data.items)),
        });
      }),
      finalize(() => {
        ctx.patchState({
          isLoading: false,
        });
      })
    );
  }

  @Action(CreateRole)
  createRole(ctx: StateContext<ProfilePermissionsStateModel>, action: CreateRole) {
    ctx.patchState({
      isSavingRole: true,
      lastCreated: null,
    });

    return this.corporateService.createRole(action.role).pipe(
      tap((res) => {
        this.snackbar.success('Perfil criado com sucesso');
        ctx.patchState({
          lastCreated: action.role,
        });
        this.navigateEdit();
      }),
      finalize(() => {
        ctx.patchState({
          isSavingRole: false,
        });
      })
    );
  }

  @Action(SetLastCreated)
  setLastCreated(ctx: StateContext<ProfilePermissionsStateModel>, action: SetLastCreated) {
    ctx.patchState({
      lastCreated: action.role,
    });
  }

  @Action(ClearLastCreated)
  clearLastCreated(ctx: StateContext<ProfilePermissionsStateModel>) {
    ctx.patchState({
      lastCreated: null,
      isSavingRole: false,
    });
  }

  @Action(DeleteRole)
  deleteRole(ctx: StateContext<ProfilePermissionsStateModel>, actions: DeleteRole) {
    ctx.patchState({
      isSavingRole: true,
      isLoading: true
    });

    return this.corporateService.deleteRole(actions.roleId).pipe(
      tap(() => {
        this.snackbar.success('USERS.PROFILE_PERMISSIONS.MODAL.SUCCESS');
      }),
      finalize(() => {
        ctx.patchState({
          isSavingRole: false,
          isLoading: false
        });
      })
    );
  }

  @Action(CreateRoleAssociation)
  createRoleAssociation(
    ctx: StateContext<ProfilePermissionsStateModel>,
    action: CreateRoleAssociation
  ) {
    ctx.patchState({
      isSavingRole: true,
    });

    return this.corporateService.createRoleAssociation(action.association).pipe(
      tap(() => {
        this.snackbar.success('Perfil associado com sucesso');
      }),
      finalize(() => {
        ctx.patchState({
          isSavingRole: false,
        });
      })
    );
  }

  @Action(GetUserRoles)
  getRoles(ctx: StateContext<ProfilePermissionsStateModel>, action: GetUserRoles) {
    ctx.patchState({
      isLoading: true,
      isEmpty: false,
      hasError: false,
    });

    const currentService: any = isPartner ? this.partnersService : this.corporateService;
    return currentService.getRoles(action.filter).pipe(
      delay(2000),
      tap((res: CommonResponse<PaginationModel<RoleModel>>) => {
        ctx.patchState({
          allRoles: res.data.items,
          datasource: new MjxTableDataSource<RoleModel>(res.data.items, res.data.total),
          isEmpty: res.data.total === 0,
        });
      }),
      finalize(() => {
        ctx.patchState({
          isLoading: false,
        });
      })
    );
  }

  @Action(GetRoleAssociations)
  getRoleAssociations(
    ctx: StateContext<ProfilePermissionsStateModel>,
    action: GetRoleAssociations
  ) {
    ctx.patchState({
      isSavingRole: true,
    });

    return this.corporateService.getRoleAssociations(action.roleId).pipe(
      tap((res: CommonResponse<string[]>) => {
        const currentProfile = { ...ctx.getState().lastCreated };

        currentProfile['privileges'] = res.data;

        ctx.patchState({
          lastCreated: currentProfile,
        });
      }),
      finalize(() => {
        ctx.patchState({
          isSavingRole: false,
        });
      })
    );
  }

  @Action(UpdateRole)
  updateRole(ctx: StateContext<ProfilePermissionsStateModel>, action: UpdateRole) {
    ctx.patchState({
      isSavingRole: true,
    });

    return this.corporateService.updateRole(action.roleId, action.role).pipe(
      tap(() => {
        this.snackbar.success('Perfil atualizado com sucesso');
        this.navigateBack();
      }),
      finalize(() => {
        ctx.patchState({
          isSavingRole: false,
        });
      })
    );
  }

  private mapPrivilegesToPermissions(privileges: PrivilegeModel[]): string[] {
    return privileges.map((privilege) => `${privilege.resource}.${privilege.action}`);
  }

  private navigateBack() {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/${RoutesEnum.Users}/profile-permissions`);
    });
  }

  private navigateEdit() {
    this.ngZone.run(() => {
      this.router.navigateByUrl(`/${RoutesEnum.Users}/profile-permissions/edit`);
    });
  }
}

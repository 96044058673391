<section class="form-section">
  <div class="title-div">
  <h3 class="section-title" *ngIf="name">
    <span class="with-margin-right">{{ name | translate }}</span>
    <ng-content select=".section-buttons"></ng-content>
    <ng-content select=".section-actions"></ng-content>
  </h3>
  <span *ngIf="subtitle" class="with-margin-right subtitle">{{subtitle | translate}}</span>
  </div>
  <div [ngClass]="sectionContentClass" *ngIf="content">
    <ng-container [ngTemplateOutlet]="content"></ng-container>
  </div>
</section>

<mat-stepper class="custom-stepper-auth" #matStepper orientation="vertical" [linear]="false">
  <mat-step *ngIf="!confirmReadQrCode" [stepControl]="step1" aria-selected="true" >
    <ng-template matStepLabel>
      {{ 'INTEGRATION.SECURITY.G_AUTH.STEP1.TITLE' | translate }}
    </ng-template>
  </mat-step>
  <mat-step *ngIf="!confirmReadQrCode" [stepControl]="step2">
    <ng-template matStepLabel>
      {{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.TITLE' | translate }}
    </ng-template>
    <div class="stepper-content">
      <img src="../../../../../../assets/images/plus-mfa.png" />
    </div>
  </mat-step>
  <mat-step *ngIf="!confirmReadQrCode" [stepControl]="step3">
    <ng-template matStepLabel>
      {{ 'INTEGRATION.SECURITY.G_AUTH.STEP3.TITLE' | translate }}
    </ng-template>
    <div class="stepper-content">
      <div class="content qr-code-content">
        <img class="qr-code" [src]="setupQrCode$ | async">
        <div class="advise">
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.ADVISE1' | translate }}</span>
          <span>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP2.ADVISE2' | translate }}</span>
          <span class="auth-code">{{ setupKey$ | async }}</span>
        </div>
      </div>
    </div>
    <div class="mt-5 text-center">
      {{ 'LOGIN.SUPPORT.TITLE' | translate }} <span class="support-email">
        <a [href]="'mailto:' + tenantConfig?.supportEmail">
          <span>{{ tenantConfig?.supportEmail }}</span></a>
      </span>
    </div>
    <ng-container [ngTemplateOutlet]="firstStep"></ng-container>
  </mat-step>

  <mat-step [stepControl]="step4" *ngIf="confirmReadQrCode">
    <ng-template matStepLabel>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.TITLE' | translate }}</ng-template>
    <div class="stepper-content">
      <span class="current-step">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.STEP' | translate }}</span>
      <span class="step-message">{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.ACTIVATION_INFO' | translate }}</span>
      <div class="content">
        <mjx-input class="input-mfa">
          <mat-label>{{ 'INTEGRATION.SECURITY.G_AUTH.STEP4.INPUT_TITLE' | translate }}</mat-label>
          <mat-form-field class="custom-input">
            <input matInput mask="000000" [formControl]="setupCode">
          </mat-form-field>
        </mjx-input>
      </div>
    </div>
    <ng-container [ngTemplateOutlet]="firstStep"></ng-container>
  </mat-step>
</mat-stepper>

<ng-template #firstStep>
  <div class="stepper-options mt-4">
    <button mat-button class="custom-button" (click)="cancel.emit(true)">
      {{ 'INTEGRATION.CANCEL_BTN' | translate }}
    </button>
    <button mat-button class="green-button" (click)="confirmSetup()">
      <span>{{ 'INTEGRATION.FINISH_BTN' | translate }}</span>
      <mat-icon>check</mat-icon>
    </button>
  </div>
</ng-template>

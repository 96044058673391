export const corporateAllowedPermissionsSet: Set<string> = new Set([
  'Accounts.Create',
  'Accounts.CreateMembership',
  'Accounts.CreateVirtualTransfer',
  'Accounts.CreateVirtualTransferBetweenPartners',
  'Accounts.DeleteMembership',
  'Accounts.Get',
  'Accounts.GetBalance',
  'Accounts.GetBalanceHistory',
  'Accounts.GetConfig',
  'Accounts.GetOverview',
  'Accounts.GetPreferences',
  'Accounts.GetReportBookCheck',
  'Accounts.GetReportOperationalSummary',
  'Accounts.GetReportTransactionsSummary',
  'Accounts.GetTransaction',
  'Accounts.GetTransactions',
  'Accounts.InvokeBankSync',
  'Accounts.InvokeTransactionWebhook',
  'Accounts.List',
  'Accounts.ListEntries',
  'Accounts.ListForUser',
  'Accounts.ListMembership',
  'Accounts.ListTransactions',
  'Accounts.UpdateConfig',
  'Accounts.UpdateName',
  'Accounts.UpdatePreferences',
  'Accounts.UpdateState',
  'Accounts.UpdateVirtualTransfer',
  'Analytics.AccountMetrics',
  'Analytics.HeatMap',
  'ApiKeys.Create',
  'ApiKeys.Delete',
  'ApiKeys.List',
  'ApiKeys.Review',
  'ApiKeys.Revoke',
  'AuditEvents.List',
  'Auth.MFAAppAuthConfirm',
  'Auth.MFAAppAuthSetup',
  'Auth.MFAValidate',
  'Auth.MFAAppAuthReset',
  'Auth.RefreshToken',
  'Auth.WhoAmI',
  'Auth.MFAAppAuthRevoke',
  'BillingPlan.CreateCustomerPlan',
  'BillingPlan.DeleteCustomerPlan',
  'BillingPlan.List',
  'BillingPlan.UpdateCustomerPlan',
  'CloudLogs.GetLogs',
  'ControlPanel.GetBalance',
  'ControlPanel.GetCashInDocument',
  'ControlPanel.GetCashOutDocument',
  'ControlPanel.PixKeyCheck',
  'ControlPanel.RequestPIXKeyReset',
  'ControlPanel.RequestRefund',
  'Customers.Create',
  'Customers.CreateSite',
  'Customers.Delete',
  'Customers.DeleteSite',
  'Customers.Get',
  'Customers.List',
  'Customers.ListSites',
  'Customers.Update',
  'Customers.UpdateSite',
  'DataExport.Create',
  'DataExport.Get',
  'DataExport.GetDownloadUrl',
  'DataExport.List',
  'DataExport.UpdatePartial',
  'Documents.Delete',
  'Documents.Download',
  'Documents.List',
  'Documents.Upload',
  'Licenses.Create',
  'Licenses.Delete',
  'Licenses.Get',
  'Licenses.List',
  'Licenses.Update',
  'Participants.Check',
  'PartnersOnboarding.Onboard',
  'PolicyRules.ChangeStatus',
  'PolicyRules.Create',
  'PolicyRules.Delete',
  'PolicyRules.Get',
  'PolicyRules.List',
  'PolicyRules.Update',
  'Recipients.Create',
  'Recipients.CreateBankAccounts',
  'Recipients.CreateCnaes',
  'Recipients.Delete',
  'Recipients.DeleteBankAccounts',
  'Recipients.DeleteCnaes',
  'Recipients.Get',
  'Recipients.GetBankAccounts',
  'Recipients.List',
  'Recipients.ListCnaes',
  'Recipients.Update',
  'SystemConfig.AddCNAEs',
  'SystemConfig.ApplyBankingRoutingProfile',
  'SystemConfig.CreateBank',
  'SystemConfig.CreateBankingRoutingProfile',
  'SystemConfig.DeleteBankingRoutingProfile',
  'SystemConfig.DeleteCNAEs',
  'SystemConfig.GetBankingConfig',
  'SystemConfig.GetBankingRouting',
  'SystemConfig.GetBanks',
  'SystemConfig.GetCNAEs',
  'SystemConfig.ListBankingRoutingProfiles',
  'SystemConfig.RemoveBank',
  'SystemConfig.ReplaceCNAEsList',
  'SystemConfig.UpdateBank',
  'SystemConfig.UpdateBankingRouting',
  'SystemConfig.UpdateBankingRoutingProfile',
  'SystemConfig.UpdateCNAEs',
  'TransactionRequests.Cancel',
  'TransactionRequests.Create',
  'TransactionRequests.Execute',
  'TransactionRequests.Get',
  'TransactionRequests.List',
  'TransactionRequests.Review',
  'Transactions.Get',
  'Transactions.List',
  'Transactions.Sync',
  'UserRoles.Associate',
  'UserRoles.Create',
  'UserRoles.Delete',
  'UserRoles.List',
  'UserRoles.ListAssociation',
  'UserRoles.ListPrivileges',
  'UserRoles.Update',
  'Users.Create',
  'Users.CreatePartnerUser',
  'Users.Delete',
  'Users.Get',
  'Users.List',
  'Users.PasswordChange',
  'Users.PasswordPasswordResetConfirm',
  'Users.PasswordReset',
  'Users.Update',
]);

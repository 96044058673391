import { NavigationOption } from '../models/navigation-option.model';
import { Roles } from './roles-list';
import { CompanyUtils } from './company-utils';
import { Permission, PermissionEntity } from '../../core/models/permission.model';
import { isIp, isPartner } from './get-context';
import { Projects } from '../../shared/enums/projects';

const analyticsMenus: NavigationOption[] = isIp ? [
  {
    title: 'Home',
    icon: 'home',
    routerLink: 'home',
    // roles: Roles.MENU_ROUTES.HOME,

    permissionEntity: PermissionEntity.ANALYTICS,
    permission: Permission.AccountMetrics,
  },
] : [
  {
    title: 'NAVBAR.OPTIONS.HOME',
    icon: 'poll',
    routerLink: 'home',
    // roles: Roles.MENU_ROUTES.HOME,

    permissionEntity: PermissionEntity.ANALYTICS,
    permission: Permission.AccountMetrics,
  },
  {
    title: 'NAVBAR.OPTIONS.HEATMAP',
    icon: 'dashboard',
    routerLink: 'heatmap',
    // roles: Roles.MENU_ROUTES.HEATMAP,

    permissionEntity: PermissionEntity.ANALYTICS,
    permission: Permission.HeatMap,
  },
];

const financesOptions: NavigationOption[] = isIp ? [
  // {
  //   title: 'NAVBAR.OPTIONS.STATEMENTS',
  //   icon: 'attach_money',
  //   routerLink: 'statements',
  //   // roles: Roles.MENU_ROUTES.TRANSACTIONS,

  //   permissionEntity: PermissionEntity.ACCOUNTS,
  //   permission: Permission.ListTransactions,
  // },
  {
    title: 'NAVBAR.OPTIONS.TRANSACTIONS',
    icon: 'attach_money',
    routerLink: 'transactions/list',
    // roles: Roles.MENU_ROUTES.TRANSACTIONS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
    icon: 'apartment',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.RECEIVERS',
        icon: 'store',
        routerLink: 'receivers',
        // roles: Roles.MENU_ROUTES.RECEIVERS.LIST,

        permissionEntity: PermissionEntity.RECIPIENTS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.PAYMENTS',
        icon: 'payments',
        routerLink: 'transactions/payments',
        // roles: Roles.MENU_ROUTES.PAYMENTS,

        permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.ENTRIES',
    icon: 'business',
    routerLink: 'entries',
    // roles: Roles.MENU_ROUTES.ENTRIES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntries,
  },
  !isPartner && {
    title: 'NAVBAR.OPTIONS.DATA_EXPORT.TITLE',
    icon: 'store',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_TRANSACTIONS',
        icon: 'summarize',
        routerLink: 'export-data/transactions',
        // roles: Roles.MENU_ROUTES.DATA_EXPORT,

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ACAM',
        icon: 'summarize',
        routerLink: 'export-data/acam',
        // roles: Roles.MENU_ROUTES.DATA_EXPORT,

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_PAYMENTS',
        icon: 'summarize',
        routerLink: 'export-data/payments',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_BOOK',
        icon: 'summarize',
        routerLink: 'export-data/book',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ENTRIES',
        icon: 'summarize',
        routerLink: 'export-data/entries',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
    ],
  },
] : [
  {
    title: 'NAVBAR.OPTIONS.SALES',
    icon: 'download',
    routerLink: 'transactions/sales',
    // roles: Roles.MENU_ROUTES.SALES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.WITHDRAWS',
    icon: 'upload',
    routerLink: 'transactions/withdraws',
    // roles: Roles.MENU_ROUTES.WITHDRAWS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  !isPartner && {
    title: 'NAVBAR.OPTIONS.TRANSACTIONS',
    icon: 'swap_vert',
    routerLink: 'transactions/list',
    // roles: Roles.MENU_ROUTES.TRANSACTIONS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  CompanyUtils.applicationName === Projects.PBS &&
    !isPartner && {
      title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
      icon: 'store',
      menus: [
        {
          title: 'NAVBAR.OPTIONS.RECEIVERS',
          icon: 'store',
          routerLink: 'receivers',
          // roles: Roles.MENU_ROUTES.RECEIVERS.LIST,

          permissionEntity: PermissionEntity.RECIPIENTS,
          permission: Permission.List,
        },
        {
          title: 'NAVBAR.OPTIONS.PAYMENTS',
          icon: 'payments',
          routerLink: 'transactions/payments',
          // roles: Roles.MENU_ROUTES.PAYMENTS,

          permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
          permission: Permission.List,
        },
      ],
    },
  CompanyUtils.applicationName !== Projects.PBS && {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
    icon: 'store',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.RECEIVERS',
        icon: 'store',
        routerLink: 'receivers',
        // roles: Roles.MENU_ROUTES.RECEIVERS.LIST,

        permissionEntity: PermissionEntity.RECIPIENTS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.PAYMENTS',
        icon: 'payments',
        routerLink: 'transactions/payments',
        // roles: Roles.MENU_ROUTES.PAYMENTS,

        permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.ENTRIES',
    icon: 'currency_exchange',
    routerLink: 'entries',
    // roles: Roles.MENU_ROUTES.ENTRIES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntries,
  },
  !isPartner && {
    title: 'NAVBAR.OPTIONS.DATA_EXPORT.TITLE',
    icon: 'swap_vert',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_TRANSACTIONS',
        icon: 'summarize',
        routerLink: 'export-data/transactions',
        // roles: Roles.MENU_ROUTES.DATA_EXPORT,

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ACAM',
        icon: 'summarize',
        routerLink: 'export-data/acam',
        // roles: Roles.MENU_ROUTES.DATA_EXPORT,

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_PAYMENTS',
        icon: 'summarize',
        routerLink: 'export-data/payments',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_BOOK',
        icon: 'summarize',
        routerLink: 'export-data/book',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.DATA_EXPORT.EXPORT_ENTRIES',
        icon: 'summarize',
        routerLink: 'export-data/entries',

        permissionEntity: PermissionEntity.DATA_EXPORT,
        permission: Permission.List,
      },
    ],
  },
];

const entitiesOptions: NavigationOption[] = isIp ? [
  {
    title: 'NAVBAR.OPTIONS.PARTNERS',
    icon: 'apartment',
    routerLink: 'partners',
    // roles: Roles.MENU_ROUTES.PARTNERS,

    permissionEntity: PermissionEntity.CUSTOMERS,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.ACCOUNTS',
    icon: 'wallet',
    routerLink: 'accounts',
    // roles: Roles.MENU_ROUTES.PARTNERS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.GetOverview,
  },
] : [
  {
    title: 'NAVBAR.OPTIONS.USERS.TITLE',
    icon: 'account_circle',
    routerLink: 'users/list',
    // roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,

    permissionEntity: PermissionEntity.USERS,
    permission: Permission.List,
  },
  !isPartner && {
    title: 'NAVBAR.OPTIONS.USERS.PROFILES',
    icon: 'description',
    routerLink: 'users/profile-permissions',
    // roles: Roles.MENU_ROUTES.USERS.PROFILES,

    permissionEntity: PermissionEntity.USER_ROLES,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.MERCHANTS',
    icon: 'store',
    routerLink: 'licenses',
    // roles: Roles.MENU_ROUTES.MERCHANTS,

    permissionEntity: PermissionEntity.LICENSES,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.PARTNERS',
    icon: 'group_work',
    routerLink: 'partners',
    // roles: Roles.MENU_ROUTES.PARTNERS,

    permissionEntity: PermissionEntity.CUSTOMERS,
    permission: Permission.List,
  },
  !isPartner && {
    title: 'NAVBAR.OPTIONS.ACCOUNTS',
    icon: 'wallet',
    routerLink: 'accounts',
    // roles: Roles.MENU_ROUTES.ACCOUNTS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.GetOverview,
  },
  {
    title: 'NAVBAR.OPTIONS.BANKS.TITLE',
    icon: 'account_balance',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.BANKS.INTEGRATION_BANKS',
        icon: 'account_balance',
        routerLink: 'integration-banks',
        // roles: Roles.MENU_ROUTES.BANKS,

        permissionEntity: PermissionEntity.SYSTEM_CONFIG,
        permission: Permission.ListBankingRoutingProfiles,
      },
      {
        title: 'NAVBAR.OPTIONS.BANKS.OPERATION_BANKS',
        icon: 'account_balance',
        routerLink: 'operation-banks',
        // roles: Roles.MENU_ROUTES.BANKS,

        permissionEntity: PermissionEntity.SYSTEM_CONFIG,
        permission: Permission.GetBanks,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.CNAES',
    icon: 'location_city',
    routerLink: 'cnaes',
    // roles: Roles.MENU_ROUTES.CNAES,

    permissionEntity: PermissionEntity.SYSTEM_CONFIG,
    permission: Permission.GetCNAEs,
  },
];

const managerOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.INTEGRATION.TITLE',
    icon: 'settings',
    roles: Roles.MENU_ROUTES.INTEGRATION.ALL,
    menus: [
      isPartner && {
        title: 'NAVBAR.OPTIONS.INTEGRATION.WEBHOOKS',
        icon: 'send',
        routerLink: 'integration/webhooks',
        // roles: Roles.MENU_ROUTES.INTEGRATION.WEBHOOKS,

        permissionEntity: PermissionEntity.API_KEYS,
        permission: Permission.List,
      },
      isPartner && {
        title: 'NAVBAR.OPTIONS.INTEGRATION.DOCUMENTATION',
        icon: 'description',
        external: true,
        routerLink: CompanyUtils.documentationUrl,
        // roles: Roles.MENU_ROUTES.INTEGRATION.DOCUMENTATION,

        permissionEntity: PermissionEntity.API_KEYS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS',
        icon: 'key',
        routerLink: 'integration/access-keys',
        // roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS,

        permissionEntity: PermissionEntity.API_KEYS,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE',
    icon: 'done_all',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.OPERATIONAL.BANKING',
        icon: 'account_balance',
        // roles: Roles.MENU_ROUTES.OPERATIONAL,
        routerLink: 'operational',

        permissionEntity: PermissionEntity.CONTROL_PANEL,
        permission: Permission.GetCashInDocument,
      },
      {
        title: 'NAVBAR.OPTIONS.CONFIG.BANKS',
        customIcon: 'bank-profile',
        routerLink: 'configuration/banks',
        // roles: Roles.MENU_ROUTES.CONFIG.BANKS,

        permissionEntity: PermissionEntity.SYSTEM_CONFIG,
        permission: Permission.ListBankingRoutingProfiles,
      },
      {
        title: 'NAVBAR.OPTIONS.CONFIG.POLICY',
        icon: 'security',
        routerLink: 'configuration/security',
        // roles: Roles.MENU_ROUTES.CONFIG.POLICY,

        permissionEntity: PermissionEntity.POLICY_RULES,
        permission: Permission.Create,
      },
      {
        title: 'NAVBAR.OPTIONS.CONFIG.SYSTEM_LOGS',
        icon: 'person_search',
        routerLink: 'cloud',
        // roles: Roles.MENU_ROUTES.CONFIG.CLOUD,

        permissionEntity: PermissionEntity.CLOUD_LOGS,
        permission: Permission.GetLogs,
      },
      {
        title: 'NAVBAR.OPTIONS.CONFIG.AUDIT',
        icon: 'person_search',
        routerLink: 'configuration/audit',
        // roles: Roles.MENU_ROUTES.CONFIG.AUDIT,

        permissionEntity: PermissionEntity.AUDIT_EVENTS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.QUERIES',
        icon: 'search',
        routerLink: 'queries',
        // roles: Roles.MENU_ROUTES.QUERY,

        permissionEntity: PermissionEntity.PARTICIPANTS,
        permission: Permission.Check,
      },
    ],
  },
];

export const lateralNavbarOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.LATERAL.ANALYTICS',
    icon: 'insert_chart',
    menus: analyticsMenus,
    roles: Roles.MENU_ROUTES.HOME,
  },
  {
    title: 'NAVBAR.LATERAL.FINANCIAL',
    icon: 'payments',
    menus: financesOptions,
    roles: Roles.MENU_ROUTES.FINANCES,
  },
  {
    title: 'NAVBAR.LATERAL.ENTITIES',
    icon: 'linked_services',
    menus: entitiesOptions,
    roles: Roles.MENU_ROUTES.ENTITIES,
  },
  {
    title: 'NAVBAR.LATERAL.ADMIN',
    icon: 'settings',
    menus: managerOptions,
    roles: Roles.MENU_ROUTES.CONFIGURATIONS,
  },
];

const navbarOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.HOME',
    icon: 'poll',
    routerLink: 'home',
    roles: Roles.MENU_ROUTES.HOME,
  },
  {
    title: 'NAVBAR.OPTIONS.HEATMAP',
    icon: 'dashboard',
    routerLink: 'heatmap',
    roles: Roles.MENU_ROUTES.HEATMAP,
  },
  {
    title: 'NAVBAR.OPTIONS.SALES',
    icon: 'download',
    routerLink: 'transactions/sales',
    roles: Roles.MENU_ROUTES.SALES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.WITHDRAWS',
    icon: 'upload',
    routerLink: 'transactions/withdraws',
    roles: Roles.MENU_ROUTES.WITHDRAWS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  CompanyUtils.applicationName === Projects.PBS &&
    !isPartner && {
      title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
      icon: 'payments',
      menus: [
        {
          title: 'NAVBAR.OPTIONS.RECEIVERS',
          icon: 'store',
          routerLink: 'receivers',
          roles: Roles.MENU_ROUTES.RECEIVERS.LIST,

          permissionEntity: PermissionEntity.RECIPIENTS,
          permission: Permission.List,
        },
        {
          title: 'NAVBAR.OPTIONS.PAYMENTS',
          icon: 'payments',
          routerLink: 'transactions/payments',
          roles: Roles.MENU_ROUTES.PAYMENTS,

          permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
          permission: Permission.List,
        },
      ],
    },
  CompanyUtils.applicationName !== Projects.PBS && {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
    icon: 'payments',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.RECEIVERS',
        icon: 'store',
        routerLink: 'receivers',
        roles: Roles.MENU_ROUTES.RECEIVERS.LIST,

        permissionEntity: PermissionEntity.RECIPIENTS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.PAYMENTS',
        icon: 'payments',
        routerLink: 'transactions/payments',
        roles: Roles.MENU_ROUTES.PAYMENTS,

        permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.ENTRIES',
    icon: 'swap_vert',
    routerLink: 'entries',
    roles: Roles.MENU_ROUTES.ENTRIES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntries,
  },
  {
    title: 'NAVBAR.OPTIONS.TRANSACTIONS',
    icon: 'swap_vert',
    routerLink: 'transactions/list',
    roles: Roles.MENU_ROUTES.TRANSACTIONS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.DATA_EXPORT',
    icon: 'swap_vert',
    routerLink: 'export-data',
    roles: Roles.MENU_ROUTES.DATA_EXPORT,

    permissionEntity: PermissionEntity.DATA_EXPORT,
    permission: Permission.List,
  },
  {
    title: 'NAVBAR.OPTIONS.ACCOUNTS',
    icon: 'wallet',
    routerLink: 'accounts',
    roles: Roles.MENU_ROUTES.ACCOUNTS,
  },
  {
    title: 'NAVBAR.OPTIONS.BANKS.TITLE',
    icon: 'account_balance',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.BANKS.INTEGRATION_BANKS',
        icon: 'account_balance',
        routerLink: 'integration-banks',
        roles: Roles.MENU_ROUTES.BANKS,
      },
      {
        title: 'NAVBAR.OPTIONS.BANKS.OPERATION_BANKS',
        icon: 'account_balance',
        routerLink: 'operation-banks',
        roles: Roles.MENU_ROUTES.BANKS,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.ADMINISTRATIVE',
    icon: 'admin_panel_settings',
    roles: Roles.MENU_ROUTES.ADMINISTRATIVE,
    menus: [
      {
        title: 'NAVBAR.OPTIONS.PARTNERS',
        icon: 'group_work',
        routerLink: 'partners',
        roles: Roles.MENU_ROUTES.PARTNERS,

        permissionEntity: PermissionEntity.CUSTOMERS,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.MERCHANTS',
        icon: 'store',
        routerLink: 'licenses',
        roles: Roles.MENU_ROUTES.MERCHANTS,

        permissionEntity: PermissionEntity.LICENSES,
        permission: Permission.List,
      },
      {
        title: 'NAVBAR.OPTIONS.USERS.TITLE',
        icon: 'person',
        menus: [
          {
            title: 'NAVBAR.OPTIONS.USERS.MANAGEMENT',
            icon: 'manage_accounts',
            routerLink: 'users/list',
            roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,
          },
        ],
      },
      {
        title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE',
        icon: 'done_all',
        menus: [
          {
            title: 'NAVBAR.OPTIONS.OPERATIONAL.BANKING',
            icon: 'account_balance',
            roles: Roles.MENU_ROUTES.OPERATIONAL,
            routerLink: 'operational',

            permissionEntity: PermissionEntity.CONTROL_PANEL,
            permission: Permission.GetCashInDocument,
          },
          {
            title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS',
            icon: 'key',
            routerLink: 'integration/access-keys',
            roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS,

            permissionEntity: PermissionEntity.API_KEYS,
            permission: Permission.List,
          },
        ],
      },
      {
        title: 'NAVBAR.OPTIONS.CONFIG.TITLE',
        icon: 'display_settings',
        menus: [
          {
            title: 'NAVBAR.OPTIONS.CONFIG.BANKS',
            icon: 'account_balance',
            routerLink: 'configuration/banks',
            roles: Roles.MENU_ROUTES.CONFIG.BANKS,

            permissionEntity: PermissionEntity.SYSTEM_CONFIG,
            permission: Permission.ListBankingRoutingProfiles,
          },
          {
            title: 'NAVBAR.OPTIONS.CONFIG.POLICY',
            icon: 'security',
            routerLink: 'configuration/security',
            roles: Roles.MENU_ROUTES.CONFIG.POLICY,

            permissionEntity: PermissionEntity.POLICY_RULES,
            permission: Permission.List,
          },
          {
            title: 'NAVBAR.OPTIONS.CONFIG.AUDIT',
            icon: 'person_search',
            routerLink: 'configuration/audit',
            roles: Roles.MENU_ROUTES.CONFIG.AUDIT,

            permissionEntity: PermissionEntity.AUDIT_EVENTS,
            permission: Permission.List,
          },
        ],
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.QUERIES',
    icon: 'search',
    routerLink: 'queries',
    roles: Roles.MENU_ROUTES.QUERY,

    permissionEntity: PermissionEntity.PARTICIPANTS,
    permission: Permission.Check,
  },
];

const partnersNavbarOptions: NavigationOption[] = [
  {
    title: 'NAVBAR.OPTIONS.HOME',
    icon: 'poll',
    routerLink: 'home',
    roles: Roles.MENU_ROUTES.HOME,
  },
  {
    title: 'NAVBAR.OPTIONS.HEATMAP',
    icon: 'dashboard',
    routerLink: 'heatmap',
    roles: Roles.MENU_ROUTES.HEATMAP,
  },
  {
    title: 'NAVBAR.OPTIONS.SALES',
    icon: 'download',
    routerLink: 'transactions/sales',
    roles: Roles.MENU_ROUTES.SALES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.WITHDRAWS',
    icon: 'upload',
    routerLink: 'transactions/withdraws',
    roles: Roles.MENU_ROUTES.WITHDRAWS,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListTransactions,
  },
  {
    title: 'NAVBAR.OPTIONS.EXTERNAL_SERVICES',
    icon: 'payments',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.RECEIVERS',
        icon: 'store',
        routerLink: 'receivers',
        roles: Roles.MENU_ROUTES.RECEIVERS.LIST,
      },
      {
        title: 'NAVBAR.OPTIONS.PAYMENTS',
        icon: 'payments',
        routerLink: 'transactions/payments',
        roles: Roles.MENU_ROUTES.PAYMENTS,

        permissionEntity: PermissionEntity.TRANSACTION_REQUESTS,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.ENTRIES',
    icon: 'swap_vert',
    routerLink: 'entries',
    roles: Roles.MENU_ROUTES.ENTRIES,

    permissionEntity: PermissionEntity.ACCOUNTS,
    permission: Permission.ListEntries,
  },
  {
    title: 'NAVBAR.OPTIONS.USERS.TITLE',
    icon: 'person',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.USERS.MANAGEMENT',
        icon: 'manage_accounts',
        routerLink: 'users/list',
        roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,
      },
      // { title: 'NAVBAR.OPTIONS.USERS.PROFILES', icon: 'description', routerLink: 'users/profile-permissions',
      //   roles: Roles.MENU_ROUTES.USERS.PROFILES }
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.INTEGRATION.TITLE',
    icon: 'settings',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.WEBHOOKS',
        icon: 'send',
        routerLink: 'integration/webhooks',
        roles: Roles.MENU_ROUTES.INTEGRATION.WEBHOOKS,
      },
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.DOCUMENTATION',
        icon: 'description',
        external: true,
        routerLink: CompanyUtils.documentationUrl,
        roles: Roles.MENU_ROUTES.INTEGRATION.DOCUMENTATION,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.OPERATIONAL.TITLE',
    icon: 'done_all',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.INTEGRATION.ACCESS_KEYS',
        icon: 'key',
        routerLink: 'integration/access-keys',
        roles: Roles.MENU_ROUTES.INTEGRATION.ACCESS_KEYS,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.CONFIG.TITLE',
    icon: 'display_settings',
    menus: [
      {
        title: 'NAVBAR.OPTIONS.CONFIG.POLICY',
        icon: 'security',
        routerLink: 'configuration/security',
        roles: Roles.MENU_ROUTES.CONFIG.POLICY,

        permissionEntity: PermissionEntity.POLICY_RULES,
        permission: Permission.List,
      },
    ],
  },
  {
    title: 'NAVBAR.OPTIONS.QUERIES',
    icon: 'search',
    routerLink: 'queries',
    roles: Roles.MENU_ROUTES.QUERY,

    permissionEntity: PermissionEntity.PARTICIPANTS,
    permission: Permission.Check,
  },
];

export const partnersMenuList = partnersNavbarOptions;
export const menuList = navbarOptions;

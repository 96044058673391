export enum AccessRolesEnum {
  Partner = "Partner",
  PartnerAdmin = "PartnerAdmin",
  PartnerMember = "PartnerMember",
  PartnerMemberFin = "PartnerMemberFin",
  PartnerDefault = 'PartnerDefault',

  Corporate = 'Corporate',
  CorporateAdmin = 'CorporateAdmin',
  CorporateMember = 'CorporateMember',
  CorporateSupport = 'CorporateSupport',
  CorporateDefault = 'CorporateDefault',

  System = 'System'
}

import {Component, OnInit} from '@angular/core';
import {StorageKeysEnum} from "./shared/enums/storage-keys.enum";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
import {NavigationCancel, NavigationStart, Router, RouterEvent} from "@angular/router";
import {filter, tap} from "rxjs/operators";
import {Title} from "@angular/platform-browser";
import {RoutesEnum} from "./shared/enums/routes.enum";
import {AppContexEnum} from "./shared/enums/app-contex.enum";
import {Projects} from "./shared/enums/projects";
import {CompanyUtils} from "./shared/utils/company-utils";
import {Store} from "@ngxs/store";
import {SessionState} from "./core/state/session/session.state";
import { MatDialog } from '@angular/material/dialog';
import { VersionsDialogComponent } from './shared/components/versions-dialog/versions-dialog.component';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

declare var require: any;
const { version: appVersion } = require('../../package.json')
@Component({
  selector: 'mjx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mjx-dashboard';
  hideNavbar = true;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private appTitle: Title,
    private store: Store,
    private dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.setLanguage();
    this.matIconRegistry.addSvgIcon(
      "bank-profile",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/icons/bank-profile.svg")
    );
  }

  ngOnInit() {
    this.listenRouter();
    this.setTitle();
    this.setTheme();
    // this.changeFavicon(company.favicon);
  }
  private setTheme() {
    CompanyUtils.applicationName === Projects.PF ?  this.toggleTheme1() : this.toggleTheme2();
  }

  private toggleTheme2() {
    document.body.classList.remove('THEME_1');
    document.body.classList.add('THEME_2');
  }

  private toggleTheme1() {
    document.body.classList.remove('THEME_2');
    document.body.classList.add('THEME_1');
  }

  private setLanguage() {
    let localLang = localStorage.getItem(StorageKeysEnum.Language);

    if (!localLang) {
      localStorage.setItem(StorageKeysEnum.Language, environment.defaultLang);
      localLang = environment.defaultLang
    }

    this.translate.use(localLang)
  }

  private setTitle() {
    const appName = CompanyUtils.companyName;

    if (environment.context === AppContexEnum.Partners) {
      this.appTitle.setTitle(appName)
    } else {
      this.appTitle.setTitle(appName + ' - Corporate')
    }
  }

  changeFavicon(src) {
    let link = document.createElement('link');
    if (document.querySelector('link[rel*=\'icon\']')) {
      link = document.querySelector('link[rel*=\'icon\']');
    }
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'icon';
    link.href = src;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  private async listenRouter() {

    this.router.events
      .pipe(
        filter((event: RouterEvent) => this.isHiddenNavbar(event)),
        tap((currentRoute: RouterEvent) => {

          if (currentRoute instanceof NavigationCancel) {

            if (this.store.selectSnapshot(SessionState.getToken)) {
              this.hideNavbar = false;
            } else {
              this.hideNavbar = true;
            }

            return;
          }

          if (currentRoute && currentRoute.url && (currentRoute.url.includes(`/${RoutesEnum.Login}`) || currentRoute.url.includes(`/${RoutesEnum.Reset}`))) {
            this.hideNavbar = true;
          } else {
            this.hideNavbar = false;
          }
        })
      )
      .subscribe()
  }

  private isHiddenNavbar(event: RouterEvent): boolean {
    return (
      event instanceof NavigationStart
      || event instanceof NavigationCancel
    )
  }

  getFooter() {
    return this.translate.instant('FOOTER', {COMPANY_NAME: CompanyUtils.companyName, VERSION: appVersion})
  }

  openDialog() {
    this.dialog.open(VersionsDialogComponent, {
      width: '780px',
      height: 'fit-content',
      panelClass: 'custom-modal'
    });
  }
}

export const permissionRoutesSet: Set<any> = new Set([
  {
    permission: 'Analytics.AccountMetrics',
    route: '/home'
  },
  {
    permission: 'Analytics.HeatMap',
    route: '/heatmap'
  },
  {
    permission: 'Accounts.ListTransactions',
    route: '/transactions/sales'
  },
  {
    permission: 'Recipients.List',
    route: '/receivers'
  },
  {
    permission: 'TransactionRequests.List',
    route: '/transactions/payments'
  },
  {
    permission: 'Accounts.ListEntries',
    route: '/entries'
  },
  {
    permission: 'DataExport.List',
    route: '/export-data/transactions'
  },
  {
    permission: 'Users.List',
    route: '/users/list'
  },
  {
    permission: 'UserRoles.List',
    route: 'users/profile-permissions'
  },
  {
    permission: 'Customers.List',
    route: '/partners'
  },
  {
    permission: 'Accounts.GetOverview',
    route: '/accounts'
  },
  {
    permission: 'SystemConfig.GetBanks',
    route: '/operation-banks'
  },
  {
    permission: 'SystemConfig.GetCNAEs',
    route: '/cnaes'
  },
  {
    permission: 'ApiKeys.List',
    route: '/integration/access-keys'
  },
  {
    permission: 'ControlPanel.GetCashInDocument',
    route: '/operational'
  },
  {
    permission: 'SystemConfig.ListBankingRoutingProfiles',
    route: '/configuration/banks'
  },
  {
    permission: 'PolicyRules.Create',
    route: '/configuration/security'
  },
  {
    permission: 'CloudLogs.GetLogs',
    route: '/cloud'
  },
  {
    permission: 'AuditEvents.List',
    route: '/configuration/audit'
  },
  {
    permission: 'Participants.Check',
    route: '/queries'
  },
  {
    permission: 'Users.Update',
    route: '/users/edit'
  }
])

<div class="filters">

  <div class="filters-inline-section">
    <form [formGroup]="formGroup" class="filters-inline" [ngClass]="{'has-filter-title': filterTitle}">
      <div *ngIf="filterTitle" [innerHTML]="filterTitle" class="filter-title"></div>
      <ng-container *ngFor="let field of selectedFiltersInline" [ngTemplateOutlet]="field.templateRef"></ng-container>

      <div class="filters-button" *ngIf="selectedFiltersOnDrawer?.length > 0">
        <button class="custom-button filter-button" type="button" mat-button (click)="onFilterEmit()" [disabled]="formGroup.invalid || allFiltersHidden || disableRule">
          <mat-icon matPrefix>search</mat-icon> Buscar
        </button>

        <button class="custom-button drawer-button" type="button" mat-button (click)="showFiltersDrawer = !showFiltersDrawer">
          <mat-icon matPrefix>filter_alt</mat-icon>
        </button>
      </div>

      <div class="inline-actions-section" *ngIf="selectedFiltersOnDrawer?.length === 0">
        <button mat-button class="custom-button filters-button" (click)="onFilterEmit()" [disabled]="formGroup.invalid || allFiltersHidden || disableRule">
          <mat-icon>search</mat-icon>
          <span>Buscar</span>
        </button>

        <button mat-icon-button [disabled]="false" class="custom-secondary-button icon-action-button" (click)="clear.emit()">
          <mat-icon>close</mat-icon>
        </button>

        <button mat-icon-button class="custom-secondary-button icon-action-button" *ngIf="showFilterActions" [ngClass]="{'activated-filter': activated}" [matTooltipHideDelay]="10" [matTooltipPosition]="'above'" [disabled]="false" matTooltip="{{ 'FILTER_FIELDS' | translate }}" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <ng-container *ngIf="selectedFiltersOnDrawer?.length === 0" [ngTemplateOutlet]="customActionItens"></ng-container>
      </div>
    </form>
  </div>

  <div [ngClass]="{'backdrop': true, 'backdrop-open': showFiltersDrawer}" (click)="showFiltersDrawer = false"></div>

  <div [ngClass]="{'filters-drawer': true, 'open': showFiltersDrawer}">

    <div class="filters-drawer-title">
      <div class="close-btn">
        <button class="close-modal-btn" mat-icon-button (click)="showFiltersDrawer = false">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <h4 *ngIf="showFiltersDrawer">Filtros</h4>
      <div class="title-actions">
        <button mat-icon-button class="custom-secondary-button icon-action-button" *ngIf="showFilterActions" [ngClass]="{'activated-filter': activated}" [matTooltipHideDelay]="10" [matTooltipPosition]="'above'" [disabled]="false" matTooltip="{{ 'FILTER_FIELDS' | translate }}" [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <ng-container *ngIf="selectedFiltersOnDrawer?.length > 0" [ngTemplateOutlet]="customActionItens"></ng-container>
      </div>
    </div>

    <div class="filters-drawer-content">
      <h4 *ngIf="allFiltersHidden">{{'FILTER_FIELDS_EMPTY' | translate}}</h4>

      <form [formGroup]="formGroup">
        <ng-container *ngFor="let field of selectedFiltersOnDrawer" [ngTemplateOutlet]="field.templateRef"></ng-container>
      </form>

      <mat-menu #menu="matMenu" classList="filter-menu">
        <ng-container *ngFor="let field of fieldsToDisplay; index as i">
          <div mat-menu-item *ngIf="!field?.hiddenFromActions"
         (click)="$event.stopPropagation()" [ngClass]="{blockMouse: field.disabled}">
            <mat-checkbox style="height: auto; width: auto" [ngClass]="{blockMouse: field.disabled}" [disabled]="field.disabled" [(ngModel)]="field.show" (change)="savefilterState()">
              <span (mousedown)="$event.stopPropagation()" (click)="checkLabel($event)">
                {{ field.translateKey | translate }}
              </span>
            </mat-checkbox>
          </div>
        </ng-container>
      </mat-menu>
    </div>

    <div class="filters-drawer-footer">
      <div class="actions-button">
        <button mat-button [disabled]="false" class="custom-secondary-button clear-button" (click)="clear.emit()">
          <mat-icon>close</mat-icon>
          <span>Limpar</span>
        </button>

        <button data-qa="filter-button" mat-button class="custom-button" (click)="onFilterEmit()" [disabled]="formGroup.invalid || allFiltersHidden || disableRule">
          <mat-icon>search</mat-icon>
          <span>Buscar</span>
        </button>
      </div>
    </div>
  </div>

</div>

<ng-template #customActionItens>
  <ng-content select=".custom-actions"></ng-content>
</ng-template>

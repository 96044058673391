module.exports = {
  "statusCode": "Done",
  "data": {
     "items": [
      {
        "type": "Credit",
        "quantity": "500000",
        "amount": "30000000.00",
        "charges": "200000.00",
        "average": "29800000.00",
        "net": "29800000.00"
      },
      {
        "type": "Debit",
        "quantity": "70000",
        "amount": "27050000.00",
        "charges": "50000.00",
        "average": "27000000.00",
        "net": "-27000000.00"
      },
     ]
  }
}

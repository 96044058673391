<div class="sections-container">
  <mjx-section [content]="profileName"></mjx-section>
  <ng-container *ngIf="isEdit && (lastCreated$ | async) && !(isLoading$ | async) && !(isSavingRole$ | async); else loadingSpinner">
    <ng-container
      *ngFor="let roleForm of rolesForms"
      [ngTemplateOutlet]="experimental"
      [ngTemplateOutletContext]="{ $implicit: roleForm }"
    >
      <br />
      <mjx-section
        [name]="roleForm.name | translatePermissionEntityKey"
        [content]="experimental"
      ></mjx-section>
    </ng-container>
  </ng-container>
</div>

<div class="actions">
  <button
    mat-button
    class="custom-button custom-secondary-button"
    routerLink="/users/profile-permissions"
    *hasPermission="{ permission: permissionData }"
  >
    <span>{{ 'SHARED.CANCEL_BTN' | translate }}</span>
  </button>

  <button
    mat-button
    class="custom-button"
    *ngIf="!lastCreated?.visibility"
    [disabled]="roleForm.invalid || (savingRole$ | async)"
    (click)="save()"
  >
    <span>{{ 'SHARED.SAVE_BTN' | translate }}</span>
  </button>
</div>

<ng-template #profileName>
  <form [formGroup]="roleForm" class="row">
    <mjx-input class="col-12 col-sm-6 col-md-4">
      <mat-form-field floatLabel="always">
        <mat-label>
          {{ 'USERS.FORM.PROFILE_NAME' | translate }}
        </mat-label>
        <input matInput formControlName="name" autocomplete="off" [pattern]="inputPattern" />
        <mat-error *ngIf="roleForm.controls['name'].hasError('pattern')">
          <strong>{{ 'INVALID_TEXT' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="roleForm.controls['name'].hasError('required')">
          <strong>{{ 'MANDATORY' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </mjx-input>
    <mjx-input class="col-12 col-sm-6 col-md-4">
      <mat-form-field floatLabel="always">
        <mat-label>
          {{ 'USERS.FORM.PROFILE_TITLE' | translate }}
        </mat-label>
        <input matInput formControlName="title" autocomplete="off" [pattern]="inputPattern" />
        <mat-error *ngIf="roleForm.controls['title'].hasError('pattern')">
          <strong>{{ 'INVALID_TEXT' | translate }}</strong>
        </mat-error>
        <mat-error *ngIf="roleForm.controls['title'].hasError('required')">
          <strong>{{ 'MANDATORY' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </mjx-input>
    <mjx-input class="col-12 col-sm-6 col-md-4">
      <mat-form-field floatLabel="always">
        <mat-label>
          {{ 'USERS.FORM.PROFILE_DOMAIN' | translate }}
        </mat-label>
        <mat-select formControlName="domain" autocomplete="off">
          <mat-option *ngFor="let profile of profiles" [value]="profile">{{ profile }}</mat-option>
        </mat-select>
        <mat-error *ngIf="roleForm.controls['domain'].hasError('required')">
          <strong>{{ 'MANDATORY' | translate }}</strong>
        </mat-error>
      </mat-form-field>
    </mjx-input>
  </form>
</ng-template>

<ng-template #experimental let-roleForm>
  <div *ngIf="roleForm">
    <form [formGroup]="roleForm?.formGroup" class="row">
      <mat-checkbox
        (change)="allSelect(roleForm?.formGroup, $event.checked)"
        class="custom-checkbox col-sm-6 all-checkbox"
        [disabled]="roleForm.name === 'Auth' || lastCreated.visibility"
        formControlName="all"
      >
        <div class="custom-label">
          {{ 'USERS.PROFILE_PERMISSIONS.CHECKBOXES.ALL' | translate }}
        </div>
      </mat-checkbox>
      <!--      <hr class="col-1 separator">-->
      <mat-checkbox
        (change)="anySelected(roleForm?.formGroup, $event.checked)"
        *ngFor="let roleCtrlName of roleForm?.formControlNames"
        class="custom-checkbox col-sm-6"
        [disabled]="isAuthPermission(roleCtrlName) || lastCreated.visibility"
        [formControlName]="roleCtrlName"
      >
        <div class="custom-label">
          {{ roleCtrlName | translatePermissionKey | translate }}
        </div>
      </mat-checkbox>
    </form>
  </div>
</ng-template>

<ng-template #loadingSpinner>
  <div *ngIf="isEdit" class="spinner-container mb-3 mt-3">
    <mat-progress-spinner
      class="custom-spinner saving-spinner"
      diameter="60"
      mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>

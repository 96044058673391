import {Directive, Input} from "@angular/core";
import {FilterField} from "../../../models/filter-field.model";

@Directive()
export abstract class MjxBaseFilter {
  @Input() filterTitle: string | undefined;
  @Input() filterName: string;
  @Input() fieldsToDisplay: FilterField[];
  @Input() disableRule: boolean;
}

<!--<div class="actions">-->
<!--  -->
<!--</div>-->

<ng-container *ngIf="(isLoading$ | async)" [ngTemplateOutlet]="loadingSpinner"></ng-container>
<ng-container *ngIf="!(isLoading$ | async) && (isEmpty$ | async)" [ngTemplateOutlet]="emptyMessage"></ng-container>
<mjx-table [hidden]="(isLoading$ | async) || (isEmpty$ | async)"
           [displayedColumns]="tableColumns"
           [columnsDefinitions]="tableColumnsDef"
           [hiddenPagination]="true"
           [dataSource]="(dataSource$ | async) | datasource"
           (tableEvents)="listenTableEvents($event)"
>
  <mjx-custom-mat-column columnName="title">
    <ng-template let-item>
      <span>{{item.title | truncateString:500:1000 }}</span>
    </ng-template>
  </mjx-custom-mat-column>

  <mjx-custom-mat-column columnName="name">
    <ng-template let-item>
      <span>{{item.name | truncateString:500:1000 }}</span>
    </ng-template>
  </mjx-custom-mat-column>
</mjx-table>

<ng-template #loadingSpinner>
  <div class="spinner-container">
    <mat-progress-spinner class="custom-spinner"
                          diameter="60"
                          mode="indeterminate"
    ></mat-progress-spinner>
  </div>
</ng-template>

<ng-template #emptyMessage>
  <mjx-empty-message icon="badge" emptyMessage="INTEGRATION.TABLE.EMPTY_PROFILES"></mjx-empty-message>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';
import {permissionsEntitiesTranslateKeysMap} from "../../../shared/utils/maps/permission-entities-translate-keys.map";

@Pipe({
  name: 'translatePermissionEntityKey'
})
export class TranslatePermissionEntityKeyPipe implements PipeTransform {
  transform(entity: string): string {
    return  permissionsEntitiesTranslateKeysMap[entity] ?? entity;
  }
}

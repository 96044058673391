import { Pipe, PipeTransform } from '@angular/core';
import {permissionsTranslateKeysMap} from "../../../shared/utils/maps/permissions-translate-keys.map";

@Pipe({
  name: 'translatePermissionKey'
})
export class TranslatePermissionKeyPipe implements PipeTransform {
  transform(permission: string): string {
    return  permissionsTranslateKeysMap[permission] ?? permission;
  }
}

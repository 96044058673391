import { AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList } from '@angular/core';
import { MjxFilterFieldDirective } from './directives/mjx-filter-field.directive';
import { UntypedFormGroup } from '@angular/forms';
import { FilterStorageService } from '../../services/filters-storage.service';
import { FilterField } from '../../models/filter-field.model';
import { Subscription } from 'rxjs';
import { MjxBaseFilter } from './models/mjx-base-filter.model';

type FieldsMap = { [key: string]: FilterField };

@Component({
  selector: 'mjx-filter',
  templateUrl: './mjx-filter.component.html',
  styleUrls: ['./mjx-filter.component.scss'],
})
export class MjxFilterComponent extends MjxBaseFilter implements OnInit, AfterViewInit, OnDestroy {
  @ContentChildren(MjxFilterFieldDirective) fieldsRef: QueryList<MjxFilterFieldDirective>;
  @Input() formGroup: UntypedFormGroup;
  @Input() customSearchButtonLabel: string;
  @Input() customSearchButtonIcon: string;

  @Output() filter = new EventEmitter<void>();
  @Output() clear = new EventEmitter<void>();

  formSubs: Subscription;
  activated = false;
  showFilterActions = true;
  showFiltersDrawer = false;

  selectedFiltersInline: MjxFilterFieldDirective[] = [];
  selectedFiltersOnDrawer: MjxFilterFieldDirective[] = [];

  constructor(private filterStorage: FilterStorageService) {
    super();
  }

  get searchButtonName(): string {
    return this.customSearchButtonLabel || 'SHARED.FILTER.SEARCH_BTN';
  }

  get searchButtonIcon(): string {
    return this.customSearchButtonIcon || 'search';
  }

  get allFiltersHidden(): boolean {
    return this.selectedFiltersInline.length === 0 && this.selectedFiltersOnDrawer.length === 0;
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.formSubs && !this.formSubs.closed) {
      this.formSubs.unsubscribe();
    }
  }

  ngAfterViewInit() {
    this.loadFilterState();
    this.updateFilter();
    this.verifyFilterActions();
  }

  savefilterState() {
    this.filterStorage.saveFilterFields(this.filterName, this.fieldsToDisplay);
    this.updateFilter();
  }

  checkLabel(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  onFilterEmit(){
    this.filter.emit();
    this.showFiltersDrawer = false;
  }

  private verifyFilterActions() {
    this.showFilterActions = this.fieldsToDisplay.some((f) => !f.hiddenFromActions);
  }

  private loadFilterState() {
    const storageFilter = this.filterStorage.loadFilterFields(this.filterName);
    if (storageFilter) {
      this.fieldsToDisplay = storageFilter;
    }
  }

  private updateFilter() {
    let hiddenCounts = 0;

    const fieldsInline: FieldsMap = {};
    const fieldsOnDrawer: FieldsMap = {};

    this.fieldsToDisplay?.forEach((f) => {
      if (f.showOnDrawer) {
        fieldsOnDrawer[f.name] = f;
      } else {
        fieldsInline[f.name] = f;
      }
    });

    const updateFields = (fields: FieldsMap, filterRef: any) => {
      return filterRef.filter((field) => {
        const currentField = fields[field.fieldName];
        const show = currentField?.show;

        if (!show) {
          hiddenCounts += 1;

          if (field?.parentForm) {
            field.parentForm.setValue(null);
          }

          currentField?.formControlNames?.forEach((form) => {
            this.formGroup.get(form).setValue(null);
          });
        }

        return show;
      });
    };

    this.selectedFiltersInline = updateFields(fieldsInline, this.fieldsRef);
    this.selectedFiltersOnDrawer = updateFields(fieldsOnDrawer, this.fieldsRef);

    this.activated = hiddenCounts > 0;
  }
}

<div class="sections-container">
  <mjx-quick-info
    *ngIf="showInfo"
    [messages]="infoMessages"
    [position]="'right'"
    [topProximity]="'16px'"
    (mouseleave)="changeInfoDialog()"
  >
  </mjx-quick-info>
  <ng-container
    *ngIf="!(isLoading$ | async); else loadingDataSpinner"
    [ngTemplateOutlet]="isEdit ? editSection : createSection"
  ></ng-container>
</div>

<ng-container [ngTemplateOutlet]="saveActions"></ng-container>

<ng-template #editSection>
  <div *ngIf="isProfile; else userEdition">
    <mjx-section
      [ngClass]="{ 'disabled-edit-field': !isEditAccountData }"
      [name]="'USERS.FORM.ACCOUNT_DATA.TITLE'"
      [content]="editAccountDataRef"
    >
      <div class="section-actions custom-hint" (click)="changeInfoDialog()">
        <mat-icon>help</mat-icon>
        <span>{{ 'USERS.HELP_MSG' | translate }}</span>
      </div>

      <div
        *ngIf="!isEditAccountData"
        class="section-buttons custom-hint"
        (click)="editAccountData()"
      >
        <span>{{ 'USERS.EDIT_BUTTON' | translate }}</span>
        <mat-icon>edit</mat-icon>
      </div>
    </mjx-section>
    <br />
    <mjx-section
      [ngClass]="{ 'disabled-edit-field': !isEditUserData }"
      [name]="'USERS.FORM.USER_DATA.TITLE'"
      [content]="personalDataRef"
    >
      <div class="section-actions custom-hint" (click)="changeInfoDialog()">
        <mat-icon>help</mat-icon>
        <span>{{ 'USERS.HELP_MSG' | translate }}</span>
      </div>

      <div *ngIf="!isEditUserData" class="section-buttons custom-hint" (click)="editUserData()">
        <span>{{ 'USERS.EDIT_BUTTON' | translate }}</span>
        <mat-icon>edit</mat-icon>
      </div>
    </mjx-section>
    <br />
    <mjx-section
      *hasPermission="{permission: permissionMembership}"
      [name]="'USERS.TABS.LINKED_ACCOUNTS'"
      [content]="linkedAccounts"
    ></mjx-section>
  </div>

  <ng-template #userEdition>
    <mjx-section [name]="'USERS.FORM.ACCOUNT_DATA.TITLE'" [content]="editUserDataRef">
    </mjx-section>
    <br />
    <mjx-section
      *hasPermission="{permission: permissionMembership}"
      [name]="'USERS.TABS.LINKED_ACCOUNTS'"
      [content]="linkedAccounts"
    ></mjx-section>
  </ng-template>
</ng-template>

<ng-template #createSection>
  <mjx-section [content]="userTypeTemplate"></mjx-section>
  <br />
  <mjx-section [name]="'USERS.FORM.ACCOUNT_DATA.TITLE'" [content]="accountDataRef"></mjx-section>
  <br />
  <mjx-section [name]="'USERS.FORM.USER_DATA.TITLE'" [content]="personalDataRef"></mjx-section>
  <br />
  <mjx-section
    *hasPermission="{permission: permissionMembership}"
    [name]="'USERS.TABS.LINKED_ACCOUNTS'"
    [content]="linkedAccounts"
  >
  </mjx-section>
</ng-template>

<ng-template #userTypeTemplate>
  <mjx-input class="custom-input select-banks-input">
    <mat-form-field floatLabel="always" appearance="standard">
      <mat-label>{{ 'USERS.FORM.USER_DATA.SELECT_USER_PROFILE' | translate }}</mat-label>
      <mat-chip-list #docList>
        <mat-chip
          [disabled]="role === 'PartnerDefault'"
          *ngFor="let role of selectedRoles; let i = index"
        >
          <span> {{ role | translate }}</span>
          <button *ngIf="!isEdit" matChipRemove (click)="removeRole(role, i)">
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>
      <hr class="input-divider"/>
      <input
        [disabled]="isEdit"
        #docInput
        #trigger="matAutocompleteTrigger"
        [matAutocomplete]="auto"
        [matChipInputAddOnBlur]="true"
        [placeholder]="'USERS.FORM.PROFILE_INPUT' | translate"
        [matChipInputFor]="docList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        matAutocompletePosition="below"
        readonly
        (focus)="trigger.openPanel()"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selectedRole($event)"
      >
        <mat-option
          *ngFor="let role of userSelectRoles"
          [value]="role.name"
          [hidden]="selectedRoles?.includes(role.name)"
          (click)="$event.stopPropagation(); trigger.openPanel()"
        >
          {{ role.name | translate }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </mjx-input>
</ng-template>

<ng-template #linkedAccounts>
  <users-linked-accounts
    [partnerId]="selectedPartnerId"
    [userId]="this.user?.id"
  ></users-linked-accounts>
</ng-template>

<ng-template #accountDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start">
      <mjx-input [ngClass]="'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.USER_NAME' | translate }}</mat-label>
          <input
            matInput
            formControlName="username"
            readonly
            onfocus="this.removeAttribute('readonly');"
          />

          <mat-error *ngIf="userForm.controls['username'].hasError('usernamePattern')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.USERNAME_PATTERN' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('emptySpaces')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.EMPTY_SPACES' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 5 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate : { min: 8 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="confirmPassword" />

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.hasError('notSame') ||
              userForm.controls['confirmPassword'].hasError('notSame')
            "
          >
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #editAccountDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start">
      <mjx-input [ngClass]="isProfile ? 'col-md-2' : 'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.USER_NAME' | translate }}</mat-label>
          <input
            matInput
            formControlName="username"
            readonly
            onfocus="this.removeAttribute('readonly');"
          />

          <mat-error *ngIf="userForm.controls['username'].hasError('usernamePattern')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.USERNAME_PATTERN' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('emptySpaces')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.EMPTY_SPACES' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 4 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['username'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input *ngIf="isProfile" class="col-md-3">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.CURRENT_PASSWORD' | translate }}</mat-label>
          <input
            matInput
            type="password"
            formControlName="currentPassword"
            readonly
            onfocus="this.removeAttribute('readonly');"
          />

          <mat-error *ngIf="userForm.controls['currentPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['currentPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate : { min: 8 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="confirmPassword" />

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.hasError('notSame') ||
              userForm.controls['confirmPassword'].hasError('notSame')
            "
          >
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #personalDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start" autocomplete="off">
      <mjx-input class="col-md-4">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.FULL_NAME' | translate }}</mat-label>
          <input matInput formControlName="fullName" />
          <mat-error *ngIf="userForm.controls['fullName'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 5 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="col-md-4">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.EMAIL' | translate }}</mat-label>
          <input matInput formControlName="email" />
          <mat-error *ngIf="userForm.controls['email'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 5 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('email')">
            <strong>{{ 'INVALID_EMAIL' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="custom-input select-banks-input" *ngIf="isEdit && isProfile">
        <mat-form-field floatLabel="always" appearance="standard">
          <mat-label>{{ 'USERS.FORM.USER_DATA.SELECT_USER_PROFILE' | translate }}</mat-label>
          <mat-chip-list #docList>
            <mat-chip
              [disabled]="role === 'PartnerDefault'"
              *ngFor="let role of selectedRoles; let i = index"
            >
              <span> {{ role | translate }}</span>
            </mat-chip>
          </mat-chip-list>
          <hr class="input-divider" />
          <input
            #docInput
            #trigger="matAutocompleteTrigger"
            [matAutocomplete]="auto"
            [matChipInputAddOnBlur]="true"
            [disabled]="isProfile"
            [matChipInputFor]="docList"
            [placeholder]="'USERS.FORM.PROFILE_INPUT' | translate"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            matAutocompletePosition="below"
            readonly
            (focus)="trigger.openPanel()"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRole($event)">
            <mat-option
              *ngFor="let role of userSelectRoles"
              [value]="role.name"
              [hidden]="selectedRoles?.includes(role.name)"
              (click)="$event.stopPropagation(); trigger.openPanel()"
            >
              {{ role.name | translate }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #editUserDataRef>
  <div class="data-section-content">
    <form [formGroup]="userForm" class="row align-items-start" autocomplete="off">
      <mjx-input class="col-md-6">
        <mat-form-field class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.FULL_NAME' | translate }}</mat-label>
          <input matInput formControlName="fullName" />
          <mat-error *ngIf="userForm.controls['fullName'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 5 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['fullName'].hasError('whiteSpaces')">
            <strong>{{ 'HAS_WHITE_SPACES' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>
      <mjx-input class="col-md-5">
        <mat-form-field class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.EMAIL' | translate }}</mat-label>
          <input matInput formControlName="email" />
          <mat-error *ngIf="userForm.controls['email'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('minLengthTrim')">
            <strong>{{ 'MINLENGTH' | translate : { min: 5 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['email'].hasError('maxLengthTrim')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input [ngClass]="'col-md-5'" *ngIf="!(!isEdit && isPartnerUser)">
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="password" />
          <mat-error *ngIf="userForm.controls['password'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('minlength')">
            <strong>{{ 'MINLENGTH' | translate : { min: 8 } }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('lowercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.LOWERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('uppercase')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.UPPERCASE' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('number')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_NUMBER' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['password'].hasError('specialCharacter')">
            <strong>{{ 'SHARED.FORM_VALIDATORS.HAS_SPECIAL_CHARS' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input
        [ngClass]="isProfile ? 'col-md-3' : 'col-md-4'"
        *ngIf="!((!isEdit && isPartnerUser) || (isEdit && !isProfile))"
      >
        <mat-form-field floatLabel="always" class="errors-form-field">
          <mat-label>{{ 'USERS.FORM.USER_DATA.CONFIRM_PASSWORD' | translate }}</mat-label>
          <input matInput type="password" formControlName="confirmPassword" />

          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('required')">
            <strong>{{ 'MANDATORY' | translate }}</strong>
          </mat-error>
          <mat-error *ngIf="userForm.controls['confirmPassword'].hasError('maxlength')">
            <strong>{{ 'MAXLENGTH' | translate }}</strong>
          </mat-error>
          <mat-error
            *ngIf="
              userForm.hasError('notSame') ||
              userForm.controls['confirmPassword'].hasError('notSame')
            "
          >
            <strong>{{ 'USERS.PASSWORD_CONFIRMATION' | translate }}</strong>
          </mat-error>
        </mat-form-field>
      </mjx-input>

      <mjx-input class="custom-input select-banks-input">
        <mat-form-field floatLabel="always" appearance="standard">
          <mat-label>{{ 'USERS.FORM.USER_DATA.SELECT_USER_PROFILE' | translate }}</mat-label>
          <mat-chip-list #docList>
            <mat-chip
              [disabled]="role === 'PartnerDefault'"
              *ngFor="let role of selectedRoles; let i = index"
            >
              <span> {{ role | translate }}</span>
              <button matChipRemove (click)="removeRole(role, i)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
          <hr class="input-divider" />
          <input
            #docInput
            #trigger="matAutocompleteTrigger"
            [matAutocomplete]="auto"
            [matChipInputAddOnBlur]="true"
            [matChipInputFor]="docList"
            [placeholder]="'USERS.FORM.PROFILE_INPUT' | translate"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            matAutocompletePosition="below"
            readonly
            (focus)="trigger.openPanel()"
          />
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectedRole($event)">
            <mat-option
              *ngFor="let role of userSelectRoles"
              [value]="role.name"
              [hidden]="selectedRoles?.includes(role.name)"
              (click)="$event.stopPropagation(); trigger.openPanel()"
            >
              {{ role.name | translate }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </mjx-input>
    </form>
  </div>
</ng-template>

<ng-template #saveActions>
  <div class="actions">
    <ng-container *ngIf="!isEdit; else editActions">
      <button mat-button class="custom-button custom-secondary-button" (click)="navigateBack()">
        <span>{{ 'USERS.CANCEL_BTN' | translate }}</span>
      </button>

      <button
        mat-button
        class="custom-button"
        [disabled]="inputDisabled || (isSaving$ | async)"
        (click)="doSave()"
      >
        <span *ngIf="!(isSaving$ | async); else loadingSpinner">
          {{ 'USERS.SAVE_BTN' | translate }}
        </span>
      </button>
    </ng-container>

    <ng-template #editActions>
      <div *ngIf="!isEditUserData && !isEditAccountData; else saveUserInfo" class="actions">
        <button mat-button class="custom-button custom-secondary-button" (click)="navigateBack()">
          <span *ngIf="!(isSaving$ | async) || !isProfile; else loadingSpinner">
            {{ 'USERS.CANCEL_BTN' | translate }}
          </span>
        </button>

        <button
          *ngIf="!isProfile"
          mat-button
          class="custom-button edit-save-btn"
          [disabled]="inputDisabled || (isSaving$ | async)"
          (click)="doSave()"
        >
          <span *ngIf="!(isSaving$ | async); else loadingSpinner">
            {{ 'USERS.EDIT_BUTTON' | translate }}
          </span>
        </button>
      </div>

      <ng-template #saveUserInfo>
        <button mat-button class="custom-button custom-secondary-button" (click)="disableEdition()">
          <span>
            {{ 'SHARED.CANCEL_BTN' | translate }}
          </span>
        </button>

        <button
          *ngIf="!isEditAccountData && isEditUserData"
          mat-button
          class="custom-button edit-save-btn"
          [disabled]="inputDisabled || (isSaving$ | async)"
          (click)="doSave()"
        >
          <span *ngIf="!(isSaving$ | async); else loadingSpinner">
            {{ 'USERS.SAVE_EDIT_USER' | translate }}
          </span>
        </button>

        <button
          *ngIf="isEditAccountData && !isEditUserData && isProfile"
          mat-button
          class="custom-button edit-save-btn"
          [disabled]="inputDisabled || (isSaving$ | async)"
          (click)="doSave()"
        >
          <span *ngIf="!(isSaving$ | async); else loadingSpinner">
            {{ 'USERS.SAVE_EDIT_ACCOUNT' | translate }}
          </span>
        </button>
      </ng-template>
    </ng-template>
  </div>
</ng-template>

<ng-template #loadingDataSpinner>
  <mat-spinner diameter="60" mode="indeterminate" class="custom-spinner"></mat-spinner>
</ng-template>
<ng-template #loadingSpinner>
  <mat-spinner diameter="16" mode="indeterminate" class="custom-spinner"></mat-spinner>
</ng-template>

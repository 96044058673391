import { RequestDataFilterModel } from '../../../../shared/models/request-filter.model';
import {RoleAssociationModel, RoleModel} from "../../models/roles.model";

export class GetUserRoles {
  static readonly type = '[Profile Permissions] Get User Roles';
  constructor(public filter: RequestDataFilterModel<any>) {}
}

export class CreateRole {
  static readonly type = '[Profile Permissions] Create Role';
  constructor(public role: RoleModel) {}
}

export class UpdateRole {
  static readonly type = '[Profile Permissions] Update Role';
  constructor(public roleId: string, public role: RoleModel) {}
}

export class ClearLastCreated {
  static readonly type = '[Profile Permissions] Clear Last Created';
}

export class SetLastCreated {
  static readonly type = '[Profile Permissions] Set Last Created';
  constructor(public role: RoleModel) {}
}

export class GetAllRoles {
  static readonly type = '[Profile Permissions] Get All Roles';
}

export class GetRoleAssociations {
  static readonly type = '[Profile Permissions] Get Role Associations';
  constructor(public roleId: string) {}
}

export class CreateProfilePermissions {
  static readonly type = '[Profile Permissions] Create Profile Permissions';
}

export class DeleteRole {
  static readonly type = '[Profile Permissions] Delete Role';
  constructor(public roleId: string) {}
}

export class CreateRoleAssociation {
  static readonly type = '[Profile Permissions] Create Role Association';
  constructor(public association: RoleAssociationModel) {}
}

import {Component, Input} from '@angular/core';
import { isIp } from '../../utils/get-context';

@Component({
  selector: 'mjx-button',
  templateUrl: './mjx-button.component.html',
  styleUrls: ['./mjx-button.component.scss']
})
export class MjxButtonComponent {
  @Input() selected: boolean;
  @Input() name: string;
  @Input() icon: string;
  @Input() customIcon: string;
  @Input() type: string;
  @Input() onlyIcon = false;
  @Input() disabled = false;
  @Input() versionButton = false;
  isIp = isIp
}

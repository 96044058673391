import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateString'
})
export class TruncateStringPipe implements PipeTransform {

  transform(value: string, maxLength: number, containerWidth: number): string {
    const span = document.createElement('span');
    span.style.visibility = 'hidden';
    span.style.whiteSpace = 'nowrap';
    span.textContent = value;
    document.body.appendChild(span);
    const textWidth = span.offsetWidth;
    document.body.removeChild(span);

    if (!value || textWidth <= containerWidth) {
      return value;
    }

    const ratio = containerWidth / textWidth;
    const truncatedLength = Math.floor(maxLength * ratio) - 3; // -3 for the ellipsis
    return value.substr(0, truncatedLength) + '...';
  }
}

<div mat-dialog-title class="title-container">
  <ng-container
    *ngIf="data?.customTitleTemplate; else defaultTitle"
    [ngTemplateOutlet]="data.customTitleTemplate">
  </ng-container>

  <div>
    <button class="close-modal-btn" mat-icon-button mat-dialog-close cdkFocusInitial>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-content class="content mt-3">
  <ng-container
    *ngIf="data?.customContentTemplate; else defaultContent"
    [ngTemplateOutlet]="data.customContentTemplate">
  </ng-container>
</div>

<mat-divider class="dialog-divider"></mat-divider>

<div mat-dialog-actions class="actions" *ngIf="!data.noActions">
  <ng-container
    *ngIf="data?.customActionsTemplate; else defaultButtons"
    [ngTemplateOutlet]="data.customActionsTemplate">
  </ng-container>
</div>

<ng-template #defaultTitle>
  <span>{{ data.title | translate }}</span>
</ng-template>

<ng-template #defaultContent>
  <p>{{ data.text }}</p>
</ng-template>

<ng-template #defaultButtons>
  <button class="custom-button custom-secondary-button" mat-button [mat-dialog-close]="false">
    {{ 'NO' | translate }}
  </button>
  <button class="custom-button" mat-button [mat-dialog-close]="true">
    {{'YES' | translate }}
  </button>
</ng-template>

import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {finalize, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";
import {SessionService} from "../../../core/services/session.service";
import {Router, NavigationEnd} from "@angular/router";
import {RoutesEnum} from "../../enums/routes.enum";
import {MatSidenav} from "@angular/material/sidenav";
import {CurrentUser} from "../../../core/models/user.model";
import {JWTokenService} from "../../../core/services/jwtoken.service";
import {SnackbarService} from "../../services/snackbar.service";
import {Actions, ofActionSuccessful, ofActionErrored, Select, Store} from '@ngxs/store';
import {SessionState} from "../../../core/state/session/session.state";
import {GetCurrentUser} from "../../../core/state/session/session.actions";
import {StorageKeysEnum} from "../../enums/storage-keys.enum";
import {HttpCancelService} from "../../../core/services/http-cancel.service";
import {AccountsState} from "../../../core/state/accounts/accounts.state";
import {GetUserDetails} from '../../../modules/users/states/users.actions';
import {NavigationOption} from "../../models/navigation-option.model";
import {CompanyUtils} from "../../utils/company-utils";
import {lateralNavbarOptions} from "../../utils/menu-list";
import {Roles} from "../../utils/roles-list";
import {Logout} from "../../../core/state/auth/auth.actions";
import {TranslationLoaderUtilsService} from "../../services/translation-loader-utils.service";
import { Permission, PermissionEntity } from 'src/app/core/models/permission.model';
import { isIp, isPartner } from '../../utils/get-context';
import { lateralNavbarOptionsIp } from '../../utils/menu-list-ip';

interface SelectLanguageBtn {
  name: string;
  image: string;
  lang: string;
}

@Component({
  selector: 'mjx-navigator',
  templateUrl: './mjx-navigator.component.html',
  styleUrls: ['./mjx-navigator.component.scss']
})
export class MjxNavigatorComponent implements OnInit {
  isMenuOpen = false;
  loadingLanguage = false;
  isOpen = false;
  hasAccountPermission: boolean = false;
  selectedMenu: NavigationOption;

  @ViewChild(MatSidenav) set matSideNavListener(item) {
    if (item) {
      this.matSideNav = item;

      if (!this.isMobile && !this.matSideNav.opened) {
        setTimeout(() => this.matSideNav.toggle(), 0);
      }
    }
  }

  @Input() set hide(status: boolean) {
    this.hideNavigation = status;
  }

  matSideNav: MatSidenav;
  menuItems: NavigationOption[];
  selectedButton: NavigationOption;
  context = environment.context;
  hideNavigation = true;
  showNavigation = false;
  isMobile = false;
  rolesEnum = Roles;
  isPartner = isPartner;
  isIp = isIp;

  showOnlyTransactionsMenu = false

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  isMobile$: Observable<boolean> = this.breakpointObserver.observe('(max-width: 668px)')
    .pipe(
      map(result => {
        this.isMobile = result.matches;
        return result.matches;
      }),
      shareReplay()
    );

  @Select(SessionState.getCurrentUser)
  currentUser$: Observable<CurrentUser>;

  @Select(SessionState.userRoles)
  currentPermissions$: Observable<CurrentUser>;

  @Select(SessionState.getLoadingUserState)
  isLoadingUser$: Observable<boolean>

  @Select(AccountsState.getAccountUserName)
  userName$: Observable<string>;

  @Select(AccountsState.getAccountUserType)
  userType$: Observable<string>;

  permissionSub;
  selectedLanguage: SelectLanguageBtn;
  languages: SelectLanguageBtn[];
  currentPath: string = '';

  constructor(
    private breakpointObserver: BreakpointObserver,
    private sessionService: SessionService,
    private router: Router,
    private jwtService: JWTokenService,
    private snackbar: SnackbarService,
    private store: Store,
    private httpCancel: HttpCancelService,
    private translationLoader: TranslationLoaderUtilsService,
    private actions$: Actions
  ) {
    this.setSelectedLang();
    this.hasAccountPermission = this.sessionService.userHasPermission(PermissionEntity.ACCOUNTS, Permission.Get)
  }

  ngOnInit() {
    this.languages = this.getlanguages();
    this.checkSession();

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentPath = event.urlAfterRedirects;
      }
    });

    this.actions$
      .pipe(
        ofActionSuccessful(GetCurrentUser)
      )
      .subscribe(() => {
        this.hideNavigation = false;
        this.setMenuItems();
      })

    this.actions$.pipe(ofActionErrored(GetCurrentUser)).subscribe(() => {
      this.doLogout();
    });

    // this.store.select(SessionState.getToken)
    //   .pipe(
    //     tap(token => {
    //       if (token) {
    //         this.setMenuItems();
    //       }
    //     })
    //   ).subscribe();
  }

  openMenu(menu: NavigationOption) {
    if(menu.menus){
      this.isOpen = true;
      this.selectedMenu = menu;
    } else {
      this.isOpen = false;
      this.selectedMenu = null;
    }
  }

  routerMenu(menu: NavigationOption) {
    this.router.navigateByUrl(menu.routerLink)
    this.selectedMenu = menu;
  }

  setSelectedButton(mainMenu: NavigationOption, secondaryMenu?: NavigationOption) {
    if (secondaryMenu && secondaryMenu.external) {
      this.navigateOut(secondaryMenu);
    }

    if (mainMenu !== this.selectedButton) {
      this.httpCancel.cancelPendingRequests();
    }
    this.selectedButton = mainMenu;
  }

  doLogout() {
    this.showNavigation = false;
    this.httpCancel.cancelPendingRequests();
    this.store.dispatch(new Logout())
      .subscribe(() => {
        this.matSideNav.close();
        this.hideNavigation = true;
        this.isOpen = false;
        this.router.navigateByUrl(`/${RoutesEnum.Login}`);
        this.store.reset({})
        this.snackbar.success('LOGOUT_MSG')
      });
  }

  async changeLanguage(lang: SelectLanguageBtn) {
    if (lang.lang === this.selectedLanguage.lang) return;

    this.loadingLanguage = true;
    this.selectedLanguage = lang;

    localStorage.setItem(StorageKeysEnum.Language, lang.lang);
    this.translationLoader
      .reloadTranslations(lang.lang)
      .pipe(
        finalize(() => {
          this.loadingLanguage = false;
        })
      )
      .subscribe();
  }

  setSelectedLang() {
    const localLang = localStorage.getItem(StorageKeysEnum.Language);
    this.selectedLanguage = this.getlanguages().filter(lang => lang.lang === localLang)[0];
  }

  goToProfileForm() {
    const currentUser = this.store.selectSnapshot(SessionState.getCurrentUser);
    const redirectToProfile = this.sessionService.userHasPermission(PermissionEntity.USERS, Permission.Update)
    if (redirectToProfile) {
      this.store.dispatch(new GetUserDetails(currentUser.id, true));
    }
  }

  get filterStyleRules() {
    return {
      'open-menu-filter-rules': this.isMenuOpen,
      'closed-menu-filter-rules': !this.isMenuOpen
    }
  }

  private navigateOut(menu: NavigationOption) {
    window.open(menu.routerLink, '_blank');
  }

  private getlanguages(): SelectLanguageBtn[] {
    return [
      { name: 'PT', image: 'assets/images/countries/br.png', lang: 'pt-br' },
      { name: 'EN', image: 'assets/images/countries/us.png', lang: 'en-us' },
      { name: 'ES', image: 'assets/images/countries/es.png', lang: 'es-es' }
    ]
  }

  private setMenuItems() {
    // this.menuItems = this.filterMenusByPermission(lateralNavbarOptions);
    this.menuItems =  this.isIp ? this.filterMenusByPermission(lateralNavbarOptionsIp) : this.filterMenusByPermission(lateralNavbarOptions);
    this.selectedButton = this.menuItems && this.menuItems[0];
  }

  // private filterMenuPermissions(menu: NavigationOption[]) {
  //   this.permissionSub = this.currentPermissions$.subscribe(permissions => {
  //     if (permissions) {
  //       const filteredMenus = this.filterMenusByRole(menu, permissions);
  //
  //       this.menuItems = filteredMenus;
  //
  //       this.permissionSub?.unsubscribe();
  //     } else {
  //       this.menuItems =  menu;
  //     }
  //
  //     this.selectedButton = this.menuItems && this.menuItems[0];
  //   });
  // }

  filterMenusByPermission(navbarOptions: NavigationOption[]): NavigationOption[] {
    const filteredMenus: NavigationOption[] = [];
    const userPermissions = this.store.selectSnapshot(SessionState.userPermissions);

    for (const option of navbarOptions) {
      if (option.menus) {
        const filteredChildren = this.filterMenusByPermission(option.menus);
        if (filteredChildren.length > 0) {
          const filteredOption: NavigationOption = { ...option, menus: filteredChildren };
          filteredMenus.push(filteredOption);
        }
      } else if (option.permissionEntity && option.permission) {

        const entity = userPermissions[option.permissionEntity];

        if (userPermissions && !!entity && entity?.includes(option.permission) ) {
          filteredMenus.push(option);
        }
      }
    }

    return filteredMenus;

  }

  // filterMenusByRole(navbarOptions: NavigationOption[], permissions): NavigationOption[] {
  //   const filteredMenus: NavigationOption[] = [];
  //
  //   for (const option of navbarOptions) {
  //     if (permissions.includes(AccessRolesEnum.PartnerMemberFin)) {
  //
  //       if (option.menus) {
  //         const filteredChildren = this.filterMenusByRole(option.menus, permissions);
  //         if (filteredChildren.length > 0) {
  //           const filteredOption: NavigationOption = { ...option, menus: filteredChildren };
  //           filteredMenus.push(filteredOption);
  //         }
  //       } else if (option.roles && option.roles.includes(AccessRolesEnum.PartnerMemberFin)) {
  //         filteredMenus.push(option);
  //       }
  //     } else {
  //
  //       if (option.menus) {
  //         const filteredChildren = this.filterMenusByRole(option.menus, permissions);
  //         if (filteredChildren.length > 0) {
  //           const filteredOption: NavigationOption = { ...option, menus: filteredChildren };
  //           filteredMenus.push(filteredOption);
  //         }
  //       } else if (option.roles && permissions.some((p) => option.roles.includes(p))) {
  //         filteredMenus.push(option);
  //
  //       }
  //     }
  //   }
  //
  //   return filteredMenus;
  // }

  private checkSession() {
    const hasSession = this.store.selectSnapshot(SessionState.getToken);
    const pathname = window.location.pathname;

    this.showNavigation = !!(hasSession);

    if (!hasSession) {
      if (!pathname.includes(RoutesEnum.Reset) && !pathname.includes(RoutesEnum.First_Access)) {
        this.router.navigateByUrl(`${RoutesEnum.Login}`);
      } else if(pathname.includes(RoutesEnum.First_Access) && isIp) {
        this.router.navigateByUrl(`${RoutesEnum.First_Access}`);
      }
    } else {
      this.store.dispatch(new GetCurrentUser());
    }
  }

  getCompanyLogo() {
    return CompanyUtils.companyLogo('medium','horizontal');
  }

  getIpBackground() {
    return CompanyUtils.ipBackground();
  }
}

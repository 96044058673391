import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Store} from '@ngxs/store';
import {HasPermissionParameter} from '../models/has-permission-parameter.model';
import {SessionService} from "../../core/services/session.service";
import {UserPermissions} from "../../core/models/permission.model";
import {SessionState} from "../../core/state/session/session.state";

@Directive({
  selector: '[hasPermission]'
})
export class HasPermissionDirective {
  @Input() set hasPermission(parameter: HasPermissionParameter) {
    if (parameter.ignore) {
      this.showComponent();
      return;
    }

    if (this.userRoles && parameter.roles) {
      if (!parameter.hide && this.externalCondition(parameter)
        && this.userRoles && this.userRoles.some((p) => parameter.roles.includes(p))) {
        this.showComponent();
        return;
      }
    } else {
      if (!parameter.hide
        && this.externalCondition(parameter)
        && this.userPermissions
        && this.userPermissions[parameter.permission.entity]?.includes(parameter.permission.type)) {
        this.showComponent();
        return;
      }
    }

    this.removeComponent();
  }

  userPermissions: UserPermissions;
  userRoles: string[];

  constructor(
    private store: Store,
    private templateRef: TemplateRef<any>,
    private sessionService: SessionService,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.extractUserPermissions();
    this.extractUserRoles();
  }

  removeComponent(): void {
    this.viewContainerRef.clear();
  }

  showComponent(): void {
    this.viewContainerRef.clear();
    this.viewContainerRef.createEmbeddedView(
      this.templateRef
    ).rootNodes[0];
  }

  externalCondition(parameter: HasPermissionParameter) {
    return (parameter.externalConditionToShow && parameter.externalConditionToShow === true) || !parameter.externalConditionToShow;
  }

  private extractUserPermissions() {
    this.userPermissions = this.store.selectSnapshot(SessionState.userPermissions);
  }

  private extractUserRoles() {
    this.userRoles = this.store.selectSnapshot(SessionState.userRoles);
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {UsersComponent} from "./users.component";
import {DetailedUserComponent} from "./components/detailed-user/detailed-user.component";
import {ListUsersComponent} from "./components/list-users/list-users.component";
import {TranslationLoaderResolver} from "../../shared/services/translation-loader.resolver";
import {
  ListProfilesPermissionsComponent
} from "./components/profile-permissions/list-profiles-permissions/list-profiles-permissions.component";
import {
  DetailedProfilesPermissionsComponent
} from "./components/profile-permissions/detailed-profiles-permissions/detailed-profiles-permissions.component";
import {SessionGuard} from "../../core/guards/session.guard";
import {Roles} from "../../shared/utils/roles-list";
import {Permission, PermissionEntity} from "../../core/models/permission.model";

const breadcrumbs = {
  title: 'BREADCRUMBS.USERS.TITLE',
  list: 'BREADCRUMBS.USERS.LIST',
  new: 'BREADCRUMBS.USERS.NEW',
  edit: 'BREADCRUMBS.USERS.EDIT',
  profilePermissions: 'USERS.PROFILE_PERMISSIONS.TITLE',
  newProfilePermissions: 'USERS.PROFILE_PERMISSIONS.NEW',
  editProfilePermissions: 'USERS.PROFILE_PERMISSIONS.EDIT',
  detailProfilePermissions: 'USERS.PROFILE_PERMISSIONS.DETAIL',
  entities: 'BREADCRUMBS.ENTITIES'
}

const routes: Routes = [
  {
    path: '', component: UsersComponent,
    data: { breadcrumbs: [breadcrumbs.title] },
    resolve: { i18n: TranslationLoaderResolver },
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      {
        path: 'list', component: ListUsersComponent,
        data: {
          breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.list],
          // roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,

          permissionEntity: PermissionEntity.USERS,
          permission: Permission.List
        },
        canActivate: [SessionGuard]
      },
      {
        path: 'new', component: DetailedUserComponent,
        data: {
          breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.new],
          // roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,

          permissionEntity: PermissionEntity.USERS,
          permission: Permission.Create
        },
        canActivate: [SessionGuard]
      },
      {
        path: 'edit', component: DetailedUserComponent,
        data: {
          breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.edit],
          // roles: Roles.MENU_ROUTES.USERS.MANAGEMENT,

          permissionEntity: PermissionEntity.USERS,
          permission: Permission.Update
        },
        canActivate: [SessionGuard]
      },
      {
        path: 'profile-permissions',
        resolve: { i18n: TranslationLoaderResolver },
        data: {
          breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.profilePermissions],
        },
        children: [
          {
            path: '',
            component: ListProfilesPermissionsComponent,
            canActivate: [SessionGuard],
            data: {
              // roles: Roles.MENU_ROUTES.USERS.PROFILES,

              permissionEntity: PermissionEntity.USER_ROLES,
              permission: Permission.List
            }
          },
          {
            path: 'new',
            component: DetailedProfilesPermissionsComponent,
            data: {
              breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.profilePermissions, breadcrumbs.newProfilePermissions],
              // roles: Roles.MENU_ROUTES.USERS.PROFILES,

              permissionEntity: PermissionEntity.USER_ROLES,
              permission: Permission.Create
            },
            canActivate: [SessionGuard]
          },
          {
            path: 'edit',
            component: DetailedProfilesPermissionsComponent,
            data: {
              breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.profilePermissions, breadcrumbs.editProfilePermissions],
              // roles: Roles.MENU_ROUTES.USERS.PROFILES,

              permissionEntity: PermissionEntity.USER_ROLES,
              permission: Permission.Update
            },
            canActivate: [SessionGuard]
          },
          {
            path: 'details',
            component: DetailedProfilesPermissionsComponent,
            data: {
              breadcrumbs: [breadcrumbs.entities, breadcrumbs.title, breadcrumbs.profilePermissions, breadcrumbs.detailProfilePermissions],
              // roles: Roles.MENU_ROUTES.USERS.PROFILES,

              permissionEntity: PermissionEntity.USER_ROLES,
              permission: Permission.ListAssociation && Permission.ListPrivileges
            },
            canActivate: [SessionGuard]
          }
        ]
      }
    ]
  }
];



@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }

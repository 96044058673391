import { isIp } from './../../utils/get-context';
import {
  AfterViewInit, ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter, Inject,
  Input, OnDestroy,
  OnInit,
  Output,
  QueryList, TemplateRef,
  ViewChild
} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTable} from '@angular/material/table';
import {MjxTableDataSource} from './mjx-table-datasource';
import {MjxTableColumnDefinition} from "./models/mjx-table.model";
import {MjxTableColumnTypesEnum} from "./enums/mjx-table-column-types.enum";
import {MjxTableEventsModel} from "./models/mjx-table-events.model";
import {defaultCurrency} from "../../utils/language-utils";
import {MjxMatColumnDirective} from "./directives/mjx-mat-column.directive";
import {TableStorageService} from "./services/table-storage.service";
import {MJX_TABLE_PAGE_SIZES} from "../../tokens/mjx-table-page-sizes.token";
import { get } from 'lodash';
import {MjxTableEventsEnum} from "./enums/mjx-table-events.enum";
import {MjxMatPaginatorIntl} from "./services/mjx-mat-paginator-intl.service";
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { WebhookConfirmDialogComponent as WebhookIpDialog } from 'src/app/modules/transactions-ip/components/webhook-confirm-dialog/webhook-confirm-dialog.component';
import { WebhookConfirmDialogComponent } from 'src/app/modules/transactions/components/webhook-confirm-dialog/webhook-confirm-dialog.component';
import { SyncConfirmDialogComponent } from 'src/app/modules/transactions/components/sync-confirm-dialog/sync-confirm-dialog.component';
import { SyncConfirmDialogComponent as SyncIpDialog } from 'src/app/modules/transactions-ip/components/sync-confirm-dialog/sync-confirm-dialog.component';
import { Observable, map, startWith } from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { PermissionEntity, Permission } from 'src/app/core/models/permission.model';
import { PermissionData } from '../../models/has-permission-parameter.model';
import { SessionService } from 'src/app/core/services/session.service';

export interface CustomMatColumn {
  def: string;
  name: string;
  show: boolean;
  disabled: boolean;
}

@Component({
  selector: 'mjx-table',
  templateUrl: './mjx-table.component.html',
  styleUrls: ['./mjx-table.component.scss']
})
export class MjxTableComponent implements AfterViewInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  @ContentChildren(MjxMatColumnDirective) columnsRef: QueryList<MjxMatColumnDirective>;

  @Input() set dataSource(data: MjxTableDataSource<any>) {
    if (data) {
      this.selection.clear();
      this.tableDataSource = data;
      this.updateTableDataSource(data?.data ?? []);
    }
  }

  @Input() showColumnsFilter = false;
  @Input() tableName: string;
  @Input() displayedColumns: string[];
  @Input() columnsDefinitions: MjxTableColumnDefinition[];
  @Input() defaultPageSize = 10;
  @Input() defaultPageSizeOptions = [5, 10, 20];
  @Input() hiddenPagination = false;
  @Input() showFooter = false;
  @Input() hafTable = false;
  @Input() columnsWithExpand: boolean = false;
  @Input() infinityTable: boolean = false;
  @Input() totalTable: boolean = false;
  @Input() disableTable: boolean = false;
  @Input() selectableTable: boolean = false;
  @Input() screenFilters: any;

  @Output() tableEvents = new EventEmitter<MjxTableEventsModel>();

  tableDataSource: MjxTableDataSource<any>;
  selection = new SelectionModel<any>(true, [])
  columnTypes = MjxTableColumnTypesEnum;
  defaultCurrency = defaultCurrency();
  columnsToDisplay: CustomMatColumn[] = [];
  expandedElement: any | null;
  isSmallScreen$: Observable<boolean>

  constructor(
    private tableStorage: TableStorageService,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private sessionService: SessionService,
    public breakpointObserver: BreakpointObserver,
    @Inject(MJX_TABLE_PAGE_SIZES) public pageSizes: number[],
  ) {
    if (pageSizes) {
      this.defaultPageSizeOptions = pageSizes;
    }
    this.isSmallScreen$ = this.breakpointObserver.observe([Breakpoints.Small, Breakpoints.XSmall]).pipe(
      map(result => result.matches),
      startWith(false)
    )
  }

  get columnsRefs(): { [key: string]: TemplateRef<any> } {
    if (this.columnsRef) {
      const columnTemplates: { [key: string]: TemplateRef<any> } = {};
      for (const columnDefinition of this.columnsRef.toArray()) {
        columnTemplates[columnDefinition.columnName] = columnDefinition.columnTemplate;
      }

      return columnTemplates;
    } else {
      return {};
    }
  }

  ngOnDestroy(): void {
    if (this.paginator) {
      const intl = (this.paginator._intl as MjxMatPaginatorIntl)

      intl.reloadSubs.unsubscribe();
      intl.translateSubs.unsubscribe();
      this.paginator.ngOnDestroy();
    }
  }

  ngAfterViewInit(): void {
    this.updateTableDataSource(this.tableDataSource);
    if (this.showColumnsFilter) {
      this.updateCustomColumns();
    }
    this.ref.detectChanges();
  }

  hasSyncPermission() {
    return this.sessionService.userHasPermission(PermissionEntity.ACCOUNTS, Permission.InvokeBankSync)
  }

  hasWebhookPermission() {
    return this.sessionService.userHasPermission(PermissionEntity.ACCOUNTS, Permission.InvokeTransactionWebhook)
  }

  updateCustomColumns() {
    const hasColumnsSave: CustomMatColumn[] = this.tableStorage.loadTableColumns(this.tableName);

    if (hasColumnsSave) {
      // Check if columns in storage are in definitions, if not delete storage
      const mapDefsFromStorage = hasColumnsSave.map(c => c.def);
      const mapDefsFromDefinitions = this.columnsDefinitions.map(c => c.column);
      const defsEquals = mapDefsFromStorage.every(item =>mapDefsFromDefinitions.includes(item));
      if (defsEquals) {
        this.columnsToDisplay = hasColumnsSave;
      } else {
        this.tableStorage.deleteTableColumn(this.tableName);
        this.columnsToDisplay = this.getCusMatColumns();
      }
    } else {
      this.columnsToDisplay = this.getCusMatColumns();
    }
  }

  hasColumnsFilter() {
    return this.columnsToDisplay.find(c => !c.show);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableDataSource?.data.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tableDataSource?.data);
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  isAllColumnsFiltered() {
    return this.columnsToDisplay.every(c => !c.show);
  }

  getCusMatColumns(): CustomMatColumn[] {
    const customMatColumns: CustomMatColumn[] = [];
    this.displayedColumns.forEach(column => {
      const columnDef = this.columnsDefinitions.find(d => d.column === column);
      if (columnDef) {
        customMatColumns.push({
          def: column,
          name: columnDef.name,
          disabled: !columnDef.enableHideColumn,
          show: columnDef.show ?? true
        })
      }
    });
    return customMatColumns;
  }

  getDisplayedColumns(): string[] {
    return this.showColumnsFilter ? this.selectableTable ?
      ['select', ...this.columnsToDisplay.filter(cd => cd.show).map(cd => cd.def)] :
      this.columnsToDisplay.filter((cd) => cd.show).map((cd) => cd.def) :
      this.displayedColumns;
  }

  listenChange(event) {
  }

  emitEvent(event: string, tableData: any) {
    this.selection.clear();
    this.tableEvents.emit({name: event, data: tableData});
  }

  emitButtonEvent(event) {
    this.emitEvent(event.name, event.data);
  }

  resetPaginator(reset: boolean) {
    if (reset) {
      this.paginator.firstPage();
    }
  }

  checkLabel(e: Event) {
    e.preventDefault();
    e.stopPropagation();
  }

  toggleRow(row: any) {
    this.expandedElement = this.expandedElement === row ? null : row;
  }

  private async updateTableDataSource(data: any) {
    if (this.table && data) {
      this.tableDataSource.sort = this.sort;
      this.tableDataSource.paginator = this.paginator;

      this.table.dataSource = this.tableDataSource;
    }
  }

  saveStatesToStorage() {
    this.tableStorage.saveTableColumns(this.tableName, this.columnsToDisplay);
  }

  getValue(item: any, columnDef: MjxTableColumnDefinition) {
    return get(item, columnDef.property);
  }


  onScroll(event: any) {
    if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
      this.tableEvents.emit({name: MjxTableEventsEnum.SCROLL_END, data: null})
    }
  }

  expandRow(row: any) {
    if (this.columnsWithExpand) {
      this.expandedElement = (this.expandedElement === row) ? null : row
      this.tableEvents.emit({name: MjxTableEventsEnum.EXPAND_ROW, data: row})
    }
  }

  openWebhookDialog() {
    if(isIp) {
      this.dialog.open(WebhookIpDialog, {
        width: '620px',
        height: 'fit-content',
        data: {data: this.selection.selected, filter: this.screenFilters, function: this.selection},
        panelClass: 'custom-modal'
      });
    } else {
      this.dialog.open(WebhookConfirmDialogComponent, {
        width: '620px',
        height: 'fit-content',
        data: {data: this.selection.selected, filter: this.screenFilters, function: this.selection},
        panelClass: 'custom-modal'
      });
    }
  }

  openSyncDialog() {
    if(isIp) {
      this.dialog.open(SyncIpDialog, {
        width: '620px',
        height: 'fit-content',
        data: {data: this.selection.selected,  function: this.selection},
        panelClass: 'custom-modal'
      });
    } else {
      this.dialog.open(SyncConfirmDialogComponent, {
        width: '620px',
        height: 'fit-content',
        data: {data: this.selection.selected,  function: this.selection},
        panelClass: 'custom-modal'
      });
    }
  }
}

import {MjxTableDataSource} from "../../../shared/modules/mjx-table/mjx-table-datasource";
import {PartnerModel} from "../models/partner.model";
import {PartnerFormModel} from "../models/partner-form.model";
import {PartnerSite} from "../models/parrner-site.model";
import { PartnerBillingPlan } from '../models/partner-billing-plan.model';
import { PartnerAccount } from '../models/partner-account.model';
import {AccountModel} from "../../../core/models/account.model";

export interface PartnersStateModel {
  isLoading: boolean;
  isEmpty: boolean;
  hasError: boolean;
  isLoadingDetails: boolean;
  partnersDatasource: MjxTableDataSource<PartnerModel>;
  detailedPartner: PartnerFormModel,
  errorDetails: any,

  loadingList: boolean;
  partners: PartnerModel[];

  isSaving: boolean;
  hasSavingError: boolean;

  isLoadingSites: boolean;
  isEmptySites: boolean;
  sites: MjxTableDataSource<PartnerSite>;

  isLoadingBillingPlans: boolean;
  isEmptyBillingPlans: boolean;
  canCreateBillingPlans: boolean;
  billigPlans: MjxTableDataSource<PartnerBillingPlan>;

  isLoadingAccounts: boolean;
  isEmptyAccounts: boolean;
  accountsDataSource: MjxTableDataSource<PartnerAccount>;
  accounts: AccountModel[];
  hasErrorList: boolean;

  cashInBanks: string[];
  cashOutBanks: string[];
  isLoadingBanks: boolean;
  isSavingBanks: boolean;

  isLoadingPreferences: boolean,
  isSavingPreferences: boolean,
  preferencesKey: string;
}

export const defaultPartnersStateModel: PartnersStateModel = {
  hasError: false,
  isEmpty: true,
  isLoading: false,
  isLoadingDetails: false,
  detailedPartner: undefined,
  partnersDatasource: undefined,
  errorDetails: undefined,
  partners: undefined,
  loadingList: false,
  isSaving: false,
  hasSavingError: false,
  isLoadingSites: false,
  isEmptySites: true,
  sites: undefined,
  isEmptyBillingPlans: true,
  canCreateBillingPlans: true,
  isLoadingBillingPlans: false,
  billigPlans: undefined,
  isLoadingAccounts: false,
  isEmptyAccounts: false,
  accountsDataSource: undefined,
  accounts: undefined,
  hasErrorList: false,
  cashInBanks: undefined,
  cashOutBanks: undefined,
  isLoadingBanks: false,
  isSavingBanks: false,
  isLoadingPreferences: false,
  isSavingPreferences: false,
  preferencesKey: undefined,
}

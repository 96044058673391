import { MjxTableDataSource } from '../../../../shared/modules/mjx-table/mjx-table-datasource';
import {RoleModel} from "../../models/roles.model";
import {UserPermissions} from "../../../../core/models/permission.model";

export interface ProfilePermissionsStateModel {
  isLoading: boolean;
  isEmpty: boolean;
  hasError: boolean;
  datasource: MjxTableDataSource<RoleModel>;
  allRoles: RoleModel[];
  availableRoles: UserPermissions;
  isSavingRole: boolean;
  lastCreated: RoleModel;
}

export const defaultProfilePermissionsState = {
  hasError: false,
  isEmpty: false,
  isLoading: false,
  datasource: null,
  availableRoles: null,
  isSavingRole: false,
  lastCreated: null,
  allRoles: null
}

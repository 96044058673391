<div class="sections-container mt-3 ms-3 security-container" [ngStyle]="{'height': pageHeight ? pageHeight : 'inherit'}">
  <div class="row align-items-center" *ngIf="!isEnabling; else gAuthPage">
    <img alt="authenticator-logo" class="col-3 g-img" src="assets/images/gauthenticator.png">
    <div class="col-auto fields-container">
      <span class="title-field">{{ 'INTEGRATION.SECURITY.G_AUTH.TITLE' | translate }}</span>
      <br>
      <div class="d-flex align-items-center help-field" (click)="changeInfoStatus()">
        <mat-icon>help</mat-icon>
        <span>{{ 'INTEGRATION.SECURITY.HELP_MSG' | translate }}</span>
      </div>
      <mjx-quick-info
        *ngIf="showInfo"
        [messages]="infoMessages"
        [width]="'380px'"
        [position]="'left'"
      ></mjx-quick-info>
    </div>
    <div class="col-3 mfa-buttons">
      <ng-container *ngIf="isPartner;else corporateMfaAction" [ngTemplateOutlet]="partnerMfaAction"></ng-container>

    </div>
  </div>
</div>

<ng-template #partnerMfaAction>
  <button mat-button (click)="isAppEnabled ? deactivateAuth() : enableAuth()"
          [disabled]="(isLoading$ | async)"
          class="enable-button" [ngClass]="{ 'custom-button': isAppEnabled, 'green-button': !isAppEnabled }">
    <mat-icon>{{ isAppEnabled ? 'remove_moderator' : 'verified_user'}}</mat-icon>
    <span>{{ (isAppEnabled ? 'INTEGRATION.SECURITY.DISABLED_BTN': 'INTEGRATION.SECURITY.ENABLED_BTN') | translate }}</span>
  </button>
</ng-template>

<ng-template #corporateMfaAction>
  <button *ngIf="isProfile" mat-button (click)="enableAuth()"
          [disabled]="(isLoading$ | async) || isAppEnabled"
          class="enable-button" [ngClass]="{ 'custom-button': isAppEnabled, 'green-button': !isAppEnabled }">
    <mat-icon>{{ isAppEnabled ? 'remove_moderator' : 'verified_user'}}</mat-icon>
    <span>{{ (isAppEnabled ? 'INTEGRATION.SECURITY.DISABLED_BTN': 'INTEGRATION.SECURITY.ENABLED_BTN') | translate }}</span>
  </button>

  <button *ngIf="!isProfile" mat-button (click)="resetAuth()"
          [disabled]="(isLoading$ | async) || !permissionReset || (isLoadingMFA$ | async) || userMFAState[0]?.app === false || !userMFAState.length"
          class="enable-button" class="green-button">
    <mat-icon class="icon-right">verified_user</mat-icon>
    <span>{{'INTEGRATION.SECURITY.RESET_BTN' | translate }}</span>
  </button>

  <button *ngIf="!isProfile && isPartnerReset" mat-button (click)="deactivatePartnerAuth()"
      [disabled]="(isLoading$ | async) || !userMFAState.length || (isLoadingMFA$ | async)"
      class="enable-button" class="custom-button">
    <mat-icon class="icon-right">remove_moderator</mat-icon>
    <span>{{ 'INTEGRATION.SECURITY.DISABLED_BTN' | translate }}</span>
  </button>
</ng-template>

<ng-template #gAuthPage>
  <div class="container">
    <div class="auth-container">
      <span class="auth-code">{{'INTEGRATION.SECURITY.G_AUTH.TITLE' | translate}}</span>
      <p class="auth-subtitle">{{'INTEGRATION.SECURITY.G_AUTH.SUBTITLE' | translate}}</p>
    </div>
    <enable-auth (cancel)="cancelMfa()"></enable-auth>
  </div>
</ng-template>

<ng-template #customModalTitle>
  <p *ngIf="isAppEnabled && !isDeactivating">{{'INTEGRATION.SECURITY.ACTIVATE_TITLE' | translate}}</p>
  <p *ngIf="isAppEnabled && isDeactivating">{{'INTEGRATION.SECURITY.REMOVE_TITLE' | translate}}</p>
  <p *ngIf="!isAppEnabled">{{'INTEGRATION.SECURITY.DEACTIVATE_TITLE' | translate}}</p>
</ng-template>

<ng-template #customModalContent>
  <p *ngIf="!isAppEnabled" class="success-text">
    <mat-icon>check</mat-icon>
    <span>{{'INTEGRATION.SECURITY.DEACTIVATE_SUCCESS' | translate}}</span>
  </p>
  <p *ngIf="isAppEnabled && !isDeactivating" class="success-text">
    <mat-icon>check</mat-icon>
    <span>{{'INTEGRATION.SECURITY.ACTIVATE_SUCCESS' | translate}}</span>
  </p>

  <div *ngIf="isAppEnabled && isDeactivating" class="deactivate-container">
    <span class="help-text">{{ 'INTEGRATION.SECURITY.CONFIRMATION_HELP' | translate }}</span>

    <mat-label class="input-title">{{ 'INTEGRATION.SECURITY.GOOGLE_INPUT_TITLE' | translate }}</mat-label>
    <mjx-input class="mfa-input">
      <mat-form-field>
        <input matInput class="input-mfa" mask="000000" [formControl]="setupCode">
      </mat-form-field>
    </mjx-input>
  </div>
</ng-template>

<ng-template #customModalActions>
  <ng-container
    *ngIf="isAppEnabled && isDeactivating; else commonAction"
    [ngTemplateOutlet]="deactivateAction">
  </ng-container>
</ng-template>

<ng-template #deactivateAction>
  <button mat-button class="red-button deactivate-button close-modal-btn" mat-dialog-close="true">
    <mat-icon>block</mat-icon>
    <span>{{ 'SHARED.CANCEL_BTN' | translate }}</span>
  </button>
  <button mat-button (click)="disableAuth()"
          class="green-button deactivate-button close-modal-btn" [disabled]="setupCode.invalid || (isLoading$ | async)">
    <mat-icon>check</mat-icon>
    <span>{{ 'SHARED.CONFIRM_BTN' | translate }}</span>
  </button>
</ng-template>

<ng-template #commonAction>
  <button mat-button class="custom-button close-modal-btn" mat-dialog-close="true">
    <span>{{ 'OK' | translate }}</span>
  </button>
</ng-template>

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isIp } from './shared/utils/get-context';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => {
      return import('./modules/login/login.module').then((m) => m.LoginModule);
    },
  },
  {
    path: 'reset/:key',
    loadChildren: () => {
      return import('./modules/reset-password/reset-password.module').then((m) => m.ResetPasswordModule);
    },
    pathMatch: 'full',
  },
  {
    path: 'unauthorized',
    loadChildren: () => {
      return import('./modules/unauthorized/unauthorized.module').then((m) => m.UnauthorizedModule);
    },
    pathMatch: 'full',
  },
  {
    path: 'transactions',
    loadChildren: () => {
      return isIp
        ? import('./modules/transactions-ip/transactions-ip.module').then((m) => m.TransactionsIpModule)
        : import('./modules/transactions/transactions.module').then((m) => m.TransactionsModule);
    },
  },
  {
    path: 'statements',
    loadChildren: () => {
      return import('./modules/statements-ip/statements-ip.module').then((m) => m.StatementsIpModule);
    },
  },
  {
    path: 'partners',
    loadChildren: () => {
      return isIp
        ? import('./modules/partners-ip/partners-ip.module').then((m) => m.PartnersIpModule)
        : import('./modules/partners/partners.module').then((m) => m.PartnersModule);
    },
  },
  {
    path: 'users',
    loadChildren: () => {
      return isIp
      ? import('./modules/users-ip/users-ip.module').then((m) => m.UsersIpModule)
      : import('./modules/users/users.module').then((m) => m.UsersModule)
    },
  },
  {
    path: 'licenses',
    loadChildren: () => {
      return import('./modules/licenses/licenses.module').then((m) => m.LicensesModule);
    },
  },
  {
    path: 'receivers',
    loadChildren: () => {
      return import('./modules/receivers/receivers.module').then((m) => m.ReceiversModule);
    },
  },
  {
    path: 'export-data',
    loadChildren: () => {
      return import('./modules/export-data/export-data.module').then((m) => m.ExportDataModule);
    },
  },
  {
    path: 'entries',
    loadChildren: () => {
      return import('./modules/entries/entries.module').then((m) => m.EntriesModule);
    },
  },
  {
    path: 'operational',
    loadChildren: () => {
      return import('./modules/operational/operational.module').then((m) => m.OperationalModule);
    },
  },
  {
    path: 'configuration',
    loadChildren: () => {
      return import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule);
    },
  },
  {
    path: 'home',
    loadChildren: () => {
      return isIp
        ? import('./modules/home-ip/home-ip.module').then((m) => m.HomeIpModule)
        : import('./modules/home/home.module').then((m) => m.HomeModule);
    },
  },
  {
    path: 'integration',
    loadChildren: () => {
      return isIp
      ? import('./modules/integration-ip/integration-ip.module').then((m) => m.SettingsIpModule)
      : import('./modules/integration/integration.module').then((m) => m.SettingsModule);
    },
  },
  {
    path: 'queries',
    loadChildren: () => {
      return import('./modules/queries/queries.module').then((m) => m.QueriesModule);
    },
  },
  {
    path: 'accounts',
    loadChildren: () => {
      return isIp
      ? import('./modules/accounts-ip/accounts-ip.module').then((m) => m.AccountsIpModule)
      : import('./modules/accounts/accounts.module').then((m) => m.AccountsModule);
    },
  },
  {
    path: 'integration-banks',
    loadChildren: () => {
      return import('./modules/banks/banks.module').then((m) => m.BanksModule);
    },
  },
  {
    path: 'operation-banks',
    loadChildren: () => {
      return import('./modules/operation-banks/operation-banks.module').then((m) => m.OperationBanksModule);
    },
  },
  {
    path: 'heatmap',
    loadChildren: () => {
      return import('./modules/heatmap/heatmap.module').then((m) => m.HeatmapModule);
    },
  },
  {
    path: 'cloud',
    loadChildren: () => {
      return import('./modules/cloud-logs/cloud-logs.module').then((m) => m.CloudLogsModule);
    },
  },
  {
    path: 'cnaes',
    loadChildren: () => {
      return import('./modules/cnaes/cnaes.module').then((m) => m.CnaesModule);
    },
  },
  {
    path: '*',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'home',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {routes} from "../../../../environments/vendor/routes/routes";
import {CommonResponse} from "../../../shared/models/response.model";
import {PaginationModel} from "../../../shared/models/pagination.model";
import {CreateUserModel, UserFormModel, UserModel} from "../models/user.model";
import {RequestDataFilterModel, RequestFilterModel} from "../../../shared/models/request-filter.model";
import {HttpUtilsService} from "../../../shared/services/http-utils.service";
import {SelectClientModel} from "../../../shared/modules/clients/models/client.model";

const API = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
    private http: HttpClient,
    private httpUtils: HttpUtilsService
  ) { }

  createUser(user: CreateUserModel) {
    let url = `${API}/${routes?.users}`;

    if (!!user.refCustomer) {
      url += '/partners'
    }

    return this.http
      .post<CommonResponse<CreateUserModel>>(url, user);
  }

  editUser(user: UserFormModel, userId: string) {
    let url = `${API}/${routes?.users}/${userId}`;
    const body = { email: user.email, fullName: user.fullName, roles: user.roles };
    return this.http
      .patch<CommonResponse<PaginationModel<void>>>(url, body);
  }

  deleteUser(userId: string) {
    const url = `${API}/${routes?.users}/${userId}`;

    return this.http
      .delete<CommonResponse<PaginationModel<void>>>(url);
  }

  updateUserPassword(currentPassword: string, newPassword: string, newPasswordConfirmation: string) {
    const url = `${API}/${routes?.users}/password-change`;

    return this.http.post<CommonResponse<any>>(url, { currentPassword, newPassword, newPasswordConfirmation })
  }

  resetUserPassword(login: string) {
    const url = `${API}/${routes?.users}/password-reset/send-key`;

    return this.http.post<CommonResponse<any>>(url, { login })
  }

  getUser(userId: string) {
    let url = `${API}/${routes?.users}/${userId}`;

    return this.http.get<CommonResponse<UserModel>>(url);
  }

  getUsers(filter?: RequestDataFilterModel<any>) {
    let url = `${API}/${routes?.users}`;

    const filterParams = this.httpUtils.parseFilterParams(filter);

    return this.http
      .get<CommonResponse<PaginationModel<UserModel>>>(url, {
        params: filterParams
      });
  }

  getClientsForSelectInput() {
    let url = `${API}/${routes?.accounts}`;

    const filter: RequestFilterModel = {
      offset: 0,
      skip: 0,
      limit: 100,
      fields: {
        id: true,
        userFullName: true,
        accountNumber: true
      }
    }

    const queryParam = this.httpUtils.createQueryParamByFilterObject(filter)
    url = url.concat(`?filter=${queryParam}`)

    return this.http
      .get<CommonResponse<PaginationModel<SelectClientModel>>>(url);
  }
}
